body {
    overflow-x: hidden;
}

.TransformComponent-module_container__3NwNd {
    position: relative;
    width: 100% !important;
    height: fit-content;
    overflow: hidden;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    margin: 0;
    padding: 0;
}

.TransformComponent-module_content__TZU5O {
    display: flex;
    flex-wrap: wrap;
    width: 100% !important;
    height: fit-content;
    margin: 0;
    padding: 0;
    transform-origin: 0% 0%;
}

.TransformComponent-module_content__TZU5O img {
    pointer-events: none;
}

/* path#vn {
    transform: scale(10.5);
} */

/* path#us:hover,
path#ca:hover,
path#ar:hover,
path#au:hover,
path#be:hover,
path#br:hover,
path#bn:hover,
path#cn:hover,
path#co:hover,
path#cd:hover,
path#cy:hover,
path#cz:hover,
path#gb:hover,
path#ee:hover,
path#fr:hover,
path#de:hover,
path#gr:hover,
path#hk:hover,
path#in:hover,
path#id:hover,
path#ie:hover,
path#il:hover,
path#it:hover,
path#jp:hover,
path#lb:hover,
path#li:hover,
path#mx:hover,
path#mc:hover,
path#mz:hover,
path#ph:hover,
path#pt:hover,
path#qa:hover,
path#ru:hover,
path#sg:hover,
path#rs:hover,
path#si:hover,
path#za:hover,
path#kr:hover,
path#es:hover,
path#lk:hover,
path#tw:hover,
path#th:hover,
path#ua:hover,
path#us:hover,
path#ae:hover {
    fill: #F26522;
} */

path#us:hover,
path#ca:hover,
path#au:hover,
path#br:hover,
path#gb:hover,
path#id:hover,
path#ru:hover,
path#za:hover,
path#us:hover {
    fill: #F26522;
}

path#vn:hover {
    fill: #F26522;
}

path#node-light {
    filter: blur(100px) !important;
}