.zoomCircle {
   padding: 5px;
   background-color: rgb(45, 57, 69, 0.5);
   transition: transform 0.2s;
   /* width: 10px;
    height: 10px;
    margin: 0 10px 10px 0px; */
   border-radius: 50%;
   /* border: 4px solid rgb(78, 37, 155); */
   border: 1px solid #A7AAAE;
   z-index: 100;

   display: inline-block;
}

.circle {
   border: 1px solid #CCC;
   border-radius: 19px;
}

.inner {
   background-color: #F80;
   border-radius: 15px;
   margin: 3px;
   height: 30px;
   width: 30px;
}

.zoomCircleChild {
   /* padding: 10px; */
   background-color: #A7AAAE;
   transition: transform 0.2s;
   width: 10px;
   height: 10px;
   margin: 4px;
   border-radius: 50%;
   /* border: 1px solid rgba(9, 255, 0, 0); */
   z-index: 100;
   /* position: absolute;
    right: 0;
    top: 50vh; */

}

.zoomCircle:hover {
   transform: scale(1.5);
}

.zoomCircle:hover .zoomCircleChild {
   background-color: #F28953;
}

.home-section-shortcut {
   position: absolute;
   left: 3.5rem;
   top: 50vh;
   z-index: 99;
}

.home-section-shortcut-fixed {
   position: fixed;
   left: 3.5rem;
   top: 20vh;
   z-index: 99;
}

.home-section-shortcut span {
   color: white;
}

@media only screen and (max-width: 1366px) {
   .zoomCircle {
      padding: 2px;
   }
} 