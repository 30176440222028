.mainDev {
  width: 100%;
  height: 100%;
  background: white;
  position: relative;
  z-index: 1;
}

.flexContent {
  width: 1440px;
  height: 440px;
  margin: auto;
  top: 40%;
}

p.title {
  font: bold 36px Helvetica;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 2rem;
}

.contentInBox {
  position: relative;
  top: -35.4vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 100%;
}

.contentInBox p {
  font: 48px Helvetica;
  font-weight: bold;
  letter-spacing: 0px;
  /* animation: animate 30s linear infinite; */

  /* -webkit-text-stroke: 1px rgb(5 54 144 / 70%); */
}

.textInside p {
    text-transform: uppercase;
    color: white;
    text-align: center;
    font-size: bold 48px Helvetica;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-name: header-anim;
}

@keyframes header-anim {
  0%,
  30% {
    transform: translateX(-5%);
    opacity: 0;
  }

  30%,
  70% {
    transform: translateX(0);
    opacity: 1;
  }

  70%,
  99% {
    opacity: 1;
  }

  99%,
  100% {
    opacity: 0;
  }
}

.boxImage {
  width: 100vw;
  height: 65vh;
  position: relative;
}

.coverImage {
  width: 1440px;
  height: 200px;
  position: absolute;
  top: 65%;
  background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), white);
}

.imgContent {
  width: 100%;
  height: 65vh;
  object-fit: cover;
}

.offsideBox {
  width: auto;
  height: auto;
  background-color: #f1f1f1;
  position: absolute;
  top: 48%;
  left: 13%;
  margin: auto;
}

.mainBox {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 5rem 3rem 5rem 5rem;
  box-shadow: 3px 4px 8px 0 rgba(0, 0, 0, 0.2);
}

p.text {
  text-align: justify;
  font: normal 16px/1.8rem Helvetica;
  color: #000000;
  margin-bottom: 2rem;
}

p.mainTitle {
  font: normal normal bold 20px/56px Helvetica;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
}

.padding {
  padding-top: 5rem;
}

.boxC {
  /* max-width: 1540px; */

  margin: 5rem 12.5rem;
}

.boxC ul > li {
  padding-bottom: 1rem;
}

.customLink {
  text-decoration: none;
}

.customLink:hover {
  text-decoration: none;
}

.navBack {
  /* position: fixed;
  bottom: 5%;
  left: 4%; */
  position: relative;
  /* margin-left: -250px;
  margin-top: -165px; */
  padding: 2rem 0 5rem 0;
  width: 30%;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.textBack {
  font: normal 16px/32px Helvetica;
  color: black;
  margin: 0;
}

.customMargin {
  margin: auto 1rem auto auto;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.wrapGallery {
  display: flex;
  justify-content: center;
  /* padding: 2rem 0; */
}

.boxCulture {
  width: 300px;
  height: 360px;
  position: relative;
  justify-content: center;
}

.boxCulture img {
  position: relative;
  width: 100%;
  height: 360px;
  object-fit: cover;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  /* filter: blur(5px); */
}

.boxCulture figure {
  overflow: hidden;
}

.boxCulture .boxMask {
  /* background-color: rgb(0, 0, 0, .4); */
  position: absolute;
  top: 0;
  width: 100%;
  height: 360px;
}

.boxCulture:hover .boxMask {
  opacity: 0;
}

.boxCulture:hover img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transform: scale(1);
  transform: scale(1);
}

p.text ul {
  margin-top: 1rem;
}

.p_0_underline {
    display: block;
    height: 2px;
    width: 400px;
    margin-left: auto;
    margin-right: 42%;
    background-color: white;

  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-iteration-count: alternate;
  animation-name: underline-anim;
}

@keyframes underline-anim {
  0%,
  10% {
    width: 0px;
    transform: translateX(-350px);
  }

  10%,
  20% {
    width: 400px;
    transform: translateX(0);
  }

  /* 20%, 30% { 
    width: 0px;
    transform: translateX(450px);
  } */
  30%,
  100% {
    transform: translateX(0);
    width: 0px;
  }
}

@keyframes animate {
  0% {
    transform: translateX(500);
  }

  100% {
    transform: translateX(-300%);
  }
}

.boxGallery {
  width: 100px;
  height: 374px;
  transition: width 2s;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
}

.boxGallery figure {
  margin: 0;
  overflow: hidden;
}

.boxGallery figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.activeImg {
  width: 720px;
}

.boxGallery:first-child {
  border-left: 7px solid rgba(214, 214, 214, 0.6);
}

.boxGallery:last-child {
  border-right: 7px solid rgba(214, 214, 214, 0.6);
}

.boxGallery:not(:first-child),
.boxGallery:not(:last-child) {
  border-bottom: 7px solid rgba(214, 214, 214, 0.6);
  border-top: 7px solid rgba(214, 214, 214, 0.6);
  border-left: 1px solid rgba(214, 214, 214, 0.6);
}

@media only screen and (min-width: 2560px) {
  .boxC {
    max-width: 2000px !important;
    margin: 5rem 17.5rem;
  }

  p.text {
    line-height: 2rem;
  }
}

@media only screen and (max-width: 1400px) {
  .mainDev {
    max-width: 1400px;
    max-height: 768x;
  }

    .textBack {
        font: normal 14px/32px Helvetica;
        color: black;
        margin: 0;
    }

  .coverImage {
    top: 43%;
    width: 100%;
  }

  .offsideBox {
    width: 1087px;
    height: auto;
  }

  .mainBox {
    padding: 2rem 3rem 2rem 3rem;
  }

  .contentInBox p {
    font: bold 36px Helvetica;
    -webkit-text-stroke: 0.8px rgb(5 54 144 / 70%);
  }

  .contentInBox {
    position: relative;
    top: -35.4vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 100%;
  }

  .boxC {
    margin: 3.5rem 9.5rem;
  }

  p.title {
    font: bold 28px Helvetica;
    margin-bottom: 1.5rem;
  }

  p.mainTitle {
    font: normal normal bold 18px Helvetica;
  }

  p.text {
    font: normal 14px/28px Helvetica;
    text-align: justify;
    max-width: 1062px;
  }

  .boxCulture {
    width: 213px;
    height: 260px;
  }

  .boxCulture img {
    width: 213px;
    height: 260px;
  }

  .navBack {
    padding: 2rem 0 3.5rem 0;
  }
}
