.row_1 {
  /* height: 50vh; */
  /* margin-bottom: 20px; */
  /* background-color: blue; */
  margin: auto;
  width: 100%;
  height: auto;
  background-color: #f7f7f7;
  display: flex;
  position: relative;
  padding-bottom: 5rem;
  padding-top: 5rem;
}

.title_history {
  padding: 20px 0 20px 280px;
}
.content {
  width: 1440px;
  height: 592px;
  background-color: white;
  margin: auto;
  display: flex;
}
.margin {
  margin: auto 2rem;
  opacity: 1 !important;
}

.joinPad {
  max-width: 1540px;
}

.contentBoxTitle {
  margin: auto auto auto 1rem;
  color: #f26522;
  font: bold 50px Helvetica;
}
.boxslide {
  position: relative;
  width: 920px;
  padding: 120px 10px 10px 58px;
}

.title_box {
  font-size: 36px;
  font-family: Helvetica;
  font-weight: bolder;
}

.text_box1 {
  font-size: 16px;
  /* font-weight: bold; */
  font-family: Helvetica;
}
.text2_box1 {
  font-size: 20px;
  color: blue;
}
.button_box1 {
  border-radius: 100px;
  /* background-color: #F16624; */
  font-size: 20px;
  color: white;
  font-weight: bold;
  padding: 20px 70px;
  border: none;
  margin-top: 30px;
  font-family: Helvetica;
}
.boxslide2 {
  width: 520px;
}
.year {
  width: 100%;
}
.Link {
  color: white !important;
}
.Link:hover {
  text-transform: none;
  text-decoration: none;
}

.card_group {
  /* margin-left: auto; */
  /* margin-right: auto; */
  margin: auto;
  padding-left: 10%;
  padding-right: 10%;
  border-color: white;
  padding-top: 50px;
}

.card_body {
  padding: 0;
  width: 720px;
  /* height: 608px; */
  /* height: 100%; */
  /* margin-top: 200px; */
}
.card_row_1_1 {
  border-color: white;
  width: 720px;
  height: 550px;
}
.card_title_row_1 {
  font-size: 32px;
  font-weight: bold;
  font-family: Helvetica;
  color: black !important;
}
.img_row_1 {
  height: 440px;
  width: 720px;
  object-fit: fill;
}
.footer_row_1 {
  background-color: #223771 !important;
  position: absolute;
  bottom: 0;
  width: 37.5vw;
  font-size: 16px;
  color: white;
}
.card_text_row_1_1 {
  font-size: 18px;
  padding-bottom: 10px;
  /* font-weight: bold; */
  font-family: Helvetica;
  margin-top: 5px;
  /* padding-left: 10px; */
}
.card_text_row_1_2 {
  position: absolute;
  bottom: 9%;
  font-family: Helvetica;
  font-size: 22px;
  width: 47vw;
  padding-left: 10px;
  background-color: white;
  left: -25%;
  height: 15vh;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  display: flex;
}
.card_row_1_2 {
  border-color: white;
  width: 720px;
  height: 550px;
  background-color: white;
}

.row_2 {
  height: 50vvh;
  position: relative;
  /* background-color: #F2F2F2; */
  /* padding-top: 50PX; */
  margin: auto;
}
.card_img_2 {
  height: 488px;
  filter: grayscale(100%);
  background-color: white;
  /* For browsers that do not support gradients */
  background-image: linear-gradient(white, rgb(86, 43, 800));
  background-position: center;
  object-fit: cover;
  opacity: 0.7;
}
.text_h2 {
  font-size: 52px;
  font-weight: bold;
  color: #f16624;
}

.text_normal {
  padding: 20px 75px 20px 20px;
  font-weight: bold;
}
.title_row_2 {
  background-color: #223771;
  border-radius: 10px;
  color: white;
  padding: 10px;
  font-size: 24px;
  border-radius: 30px;
  width: 10vw;
  text-align: center;
  font-family: Herlvetica;
}

.arrow {
  font-size: 60px;
  position: relative;
  color: #f26522;
  right: -85%;
  top: 5%;
  width: 30px;
  bottom: 45px;
}

.title_map {
  font-weight: bold;
  position: absolute;
  left: 15%;
  font-size: 32px;
  font-family: Helvetica;
  top: 30%;
  text-transform: uppercase;
  z-index: 2;
}
.box_1 {
  width: 480px;
  height: 176px;
  background-color: #223771;
  border: 1px solid white;
  display: flex;
}
.box_2 {
  width: 480px;
  height: 176px;
  background-color: #223771;
  border: 1px solid white;
  display: flex;
}
.box_3 {
  width: 480px;
  height: 176px;
  background-color: #223771;
  border: 1px solid white;
  display: flex;
}

.boxText {
  width: 191px;
  height: 66px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  align-self: center;
  margin: auto;
}

.box_text_1 {
  font-size: 55px;
  color: white;
  margin: auto 0;
}

.box_text_2 {
  font-size: 26px;
  color: white;
  margin: auto 0 10px 10px;
}

.box_text_3 {
  font-size: 20px;
  color: white;
  margin: 10px 0 0;
  align-self: center;
}

.box_3_text_1 {
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 55px;
}

.row_3 {
  position: relative;
  margin: auto;
  /* padding: 63px; */
  margin-top: 10px;
  justify-content: center;
  height: 327px;
  display: flex;
}
.col_1 {
  width: 50%;
  /* height: 100%; */
}
.col_2 {
  width: 50%;

  /* height: 100%; */
}

._boxSlider {
  width: 900px;
  height: 70px;
  /* background-color: black; */
  display: flex !important;
  flex-direction: row;
}

._boxYear {
  width: 200px;
  height: 70px;
  margin: auto 1rem;
}

.absBtm {
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: row;
  margin-left: -200px;
  /* margin-bottom: 50px; */
}

._box4 {
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #223771;
}

._box4 p {
  text-decoration: none !important;
}
._box1 {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.boxImage {
  /* width: 720px; */
  height: 408px;
  position: relative;
}
.boxBottom {
  width: 200px;
  height: 192px;
  position: relative;
  background-color: rgb(34 55 113 / 75%);
  transition: width 0.5s ease-in-out;
  /* right: 0; */
}

.contentInsideBox {
  position: absolute;
  bottom: 0;
  transition: height 0.5s;
  opacity: 0.7;
}
.insideBox {
  display: flex;
  /* position: absolute; */
  /* bottom: 2rem; */
  margin: 4.5rem 1.5rem 4rem 1.5rem;
}
.inside_image {
  width: 1510px;
  height: 440px;
  position: absolute;
  background-color: rgb(6 6 6 / 23%);
  opacity: 0;
  color: rgb(0, 0, 0);
  top: 14.5%;
}

.boxYear {
  font: bold 22px Helvetica;
  color: black;
}
.img_col_1 {
  width: 755px;
  height: 408px;
  object-fit: cover;
  float: right;
}
.card_2020 {
  width: 954px;
  height: 192px;
  background-color: rgb(34 55 113 / 75%);
  padding: 4rem 3.5rem 4rem 1.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
}

/* .card_2020 p {
  font: normal 22px Helvetica;
  text-align: left;
  color: white;
  margin-top: 1rem;
} */

._boxTitle {
  width: 700px;
  /* height: 5vh; */
  /* margin: auto 1rem; */
  align-self: center;
  justify-content: center;
  display: flex;
}

.boxLinks {
  position: relative;
  margin: auto 0;
  text-align: left;
  display: flex;
  flex-direction: row;
  text-decoration: none !important;
}

/* .boxLinks p {
  text-decoration: none;
} */
.readMore {
  cursor: pointer;
  color: white;
  font: normal 16px Helvetica;
}
.outerCircle2 {
  padding: 5px;
  /* background-color: rgb(45, 57, 69, 0.5); */
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  margin: 0 0 0.3rem 3rem;
  align-self: left;
  opacity: 0;
  position: absolute;
  top: 27%;
  /* top: 1px; */
  /* left: 42%; */
}

.innerCircle2 {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.boxLinks:hover .outerCircle2 {
  opacity: 1;
}

.boxLinks:hover .foot {
  margin-left: 1.5rem;
  font-weight: bold;
}
.boxLinks:hover .foot p {
  text-decoration: none !important;
}

.contentTl {
  font: 16px Helvetica;
  text-align: left;
  color: white;
  margin: 0 auto 0 30px;
  line-height: 1.8rem;
}

p._boxTitle {
  margin: auto auto auto 1rem;
}

._bText {
  width: 88%;
  height: auto;
  position: relative;
}
._bImg {
  width: auto;
  height: auto;
  position: relative;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 5rem;
  align-self: center;
}
.foot {
  position: relative;
  text-align: left;
  color: white;
  padding-left: 3rem;
  padding-top: 15px;
  font: 16px Helvetica !important;
}

/* .foot p {
  text-decoration: none;
  
} */

.absBtm2 {
  position: absolute;
  bottom: 0;
}

.p_col_2 {
  height: 100px;
  width: 640px;
}

.arrow_2 {
  width: 24px;
  cursor: pointer;
}
.judul {
  color: black;
  font-size: 36px;
  font-family: Helvetica;
  font-weight: bold;
  margin-bottom: 2rem;
  line-height: 1.8rem;
}

.text {
  padding-left: 15rem;
}
.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
  position: absolute;
  bottom: -39%;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@media only screen and (min-width: 2560px) {
  .joinPad {
    max-width: 2090px !important;
  }

  .img_col_1 {
    width: 1030px;
    height: 560px;
  }
  .card_2020 {
    width: 1230px;
    height: 220px;
  }

  .box4 {
    height: 60px;
  }

  .foot {
    padding-left: 5.6rem;
  }

  .boxLinks:hover .foot {
    margin-left: 0;
  }
  .judul {
    margin-bottom: 2rem;
  }
  .boxLinks {
    position: relative;
    margin: auto 0;
    text-align: left;
    display: flex;
    flex-direction: row;
    text-decoration: none !important;
  }

  /* .boxLinks p {
    text-decoration: none;
  } */
  .readMore {
    cursor: pointer;
    color: white;
    font: normal 16px Helvetica;
  }
  .outerCircle2 {
    padding: 5px;
    /* background-color: rgb(45, 57, 69, 0.5); */
    transition: transform 0.2s;
    border-radius: 50%;
    border: 1px solid #f16624;
    display: inline-block;
    margin: 0 0 0.3rem 5.3rem;
    align-self: left;
    opacity: 0;
    position: absolute;
    top: 27%;
    /* top: 1px; */
    /* left: 42%; */
  }

  .innerCircle2 {
    content: "";
    background: #f16624;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  .boxLinks:hover .outerCircle2 {
    opacity: 1;
  }

  .boxLinks:hover .foot {
    margin-left: 1.5rem;
    font-weight: bold;
  }
  .boxLinks:hover .foot p {
    text-decoration: none !important;
  }
}
@media only screen and (max-width: 1400px) {
  .outerCircle2 {
    margin-left: 2rem;
  }
  ._box4 {
    width: 500px;
  }
  ._boxYear {
    width: 100px;
  }
  .card_2020 {
    width: 616px;
    height: 100px;
    padding: 0;
  }

  ._boxSlider {
    margin-top: 1rem;
  }
  ._boxTitle {
    margin: 0;
  }
  ._bImg {
    margin-top: 2rem;
    margin-left: 18px;
  }

  .col_1 {
    height: 279px;
  }
  .img_col_1 {
    width: 532px;
    height: 268px;
    position: absolute;
    right: 0;
    top: 11px;
  }
  .joinPad {
    max-width: 1200px;
    padding: unset;
  }

  .boxBottom {
    height: 100px;
    width: 116px;
  }

  .insideBox {
    margin: 0;
    bottom: 0;
    position: relative;
    padding-top: 2rem;
  }

  .contentBoxTitle {
    margin: 1rem auto auto 1rem;
    font: bold 30px Helvetica;
  }

  .contentTl {
    font: normal 14px Helvetica;
  }

  .spinner {
    bottom: -64%;
  }

  .foot {
    position: relative;
    width: 500px;
    padding-left: 2rem;
    font: normal 14px Helvetica !important;
  }
  .p_col_2 {
    height: 300px;
    width: 400px;
    /* padding: 30px;
    bottom: -42%; */
    font-size: 14px;
  }
  .absBtm {
    margin-left: -116px;
  }
  .arrow_2 {
    right: 6%;
    top: 40%;
    width: 18px;
  }
  .judul {
    color: black;
    font: bold 28px Helvetica;
    margin-left: 9.5rem;
  }
  .card_img_2 {
    height: 314px;

    object-fit: contain;
    opacity: 0.7;
    width: 100vw;
  }
  .title_map {
    font-size: 22px;
    left: 22%;
  }
  .text {
    padding-left: 12rem;
  }
  .row_1 {
    padding-bottom: 3.5rem;
    padding-top: 3.5rem;
  }
  .year {
    position: absolute;
    top: 8%;
  }
}
