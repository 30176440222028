/* GENERAL */
:root {
  --title-hd: bold 28px Helvetica;
  --subtitle-hd: bold 18px Helvetica;
  --p-hd: normal 14px Helvetica;
  --container-hd: 1062px !important;
}

.mt3rem {
  margin-top: 3rem;
}

.mt5rem {
  margin-top: 5rem;
}

/* SPECIFIC */
.sectionTitle {
  color: white;
  font: bold 36px Helvetica;
  margin-bottom: 0;
}

.sectionContentTitle {
  color: white;
  font: 26px Helvetica;
  margin: 0 0 1rem 0;
}

.contentDetail p {
  font: 14px/24px Helvetica;
  margin-bottom: 0;
}

.contentImage {
  height: 200px;
}

.btnRead {
  background-color: #223771;
  height: 2.4rem;
  width: 7rem;
  border-radius: 50px;
  align-self: center;
}
.btnRead p {
  color: white;
  font: bold 12px Helvetica;
  padding: 0.8rem 0;
  margin: auto;
  text-align: center;
}

.btnRead:hover {
  background-color: #f16624;
}

.cardtemplateback {
  width: 304px;
  height: 272px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background: rgba(29, 106, 154, 0.72); */
  background: white;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  border: 0;
  transition: opacity 0.2s, visibility 0.2s;
}
.cardfooterback {
  position: relative;
  border: none;
  margin: auto;
  background: none;
}

.ContentWrapp {
  position: relative;
  width: 304px;
  height: 272px;
  background: rgba(34, 55, 113, 0.6);
  margin: 0 16px 0 0;
  cursor: url("./assets/drag.svg") 20 20, auto;
}

.ContentWrapp:hover .cardtemplateback {
  visibility: visible;
  opacity: 1;
}

.cardcontent p {
  color: white;
  font-family: Helvetica;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 12ch;
  line-height: 1.8rem;
  text-align: left;
  font-weight: bold;
}

.cardfront,
.cardfrontside {
  width: 304px;
  height: 272px;
  background: transparent 0 no-repeat padding-box;
  /* opacity: 1; */
  display: flex !important;
}
.coverBlue {
  width: 304px;
  height: 272px;
  background: #223870 0 0 no-repeat padding-box;
  opacity: 0.5;
  position: absolute;
}
.contentImageD {
  width: 480px;
  height: 288px;
  object-fit: contain;
}

.imgOnBox {
  object-fit: cover;
  height: 200px;
  width: 100%;
}

.imgOnBoxD {
  object-fit: contain;
  height: 200px;
  width: 100%;
}

.imageContent {
  width: auto;
  height: 376px;
  object-fit: cover;
}

.imageContentD {
  width: auto;
  height: 376px;
  object-fit: contain;
}

.paddingBoxTitle {
  padding: 0 0 0 5px;
  margin-bottom: 2rem;
}

.paddingBox {
  padding-bottom: 2rem;
}

.imageBack {
  width: 100vw;
  height: auto;
  background-size: cover;
}

.customLink:hover {
  text-decoration: none;
}

.contentWrapping {
  padding: 5rem 0;
}

.customPadding {
  padding: 2rem 0 0 0;
}

.readMore {
  font-size: 14px Helvetica;
  color: white;
}

.date {
  font: 16px Helvetica;
  color: white;
  margin-bottom: 0;
}

.secTempl {
  margin-left: 13rem;
  display: block;
}

.customContainer {
  max-width: 1520px !important;
}
.cardFd1 {
  padding: 2rem;
}

.cardFd {
  padding: 0 2rem 2rem 2rem;
}

@media only screen and (min-width: 2560px) {
  .contentWrapping {
    padding: 3rem 0;
    margin: auto;
  }

  .cardcontent p {
    font: bold 26px Helvetica;
    line-height: 2.5rem;
  }

  .ContentWrapp,
  .cardfront,
  .cardtemplateback {
    width: 472px;
    height: 360px;
  }

  .paddingBoxTitle {
    padding: 2rem 15px 0 17.5rem;
    margin-left: 0;
    margin-right: 0;
  }

  .customContainer {
    max-width: 2000px !important;
  }

  .secTempl {
    margin-left: 17.5rem;
  }

  .cardFd1 {
    padding: 3rem;
  }

  .cardFd {
    padding: 0 3rem 3rem 3rem;
  }

  .imgOnBox {
    height: 245px;
  }

  .cardfooterback {
    margin: 5rem auto auto auto;
  }
}

@media only screen and (max-width: 1400px) {
  .customContainer {
    max-width: 1062px !important;
    padding: 0 1.7rem;
  }

  .contentWrapping {
    padding: 3.5rem 0;
  }

  .paddingBoxTitle {
    margin-bottom: 1.5rem;
  }

  .sectionTitle {
    font: var(--title-hd);
  }

  .secTempl {
    margin-left: 11.2rem;
    display: block;
  }

  .paddingBox {
    padding-bottom: 0;
  }

  .sectionContentTitle {
    font: 18px/32px Helvetica;
    /* margin: 0 0 0.5rem 190px; */
  }

  .cardcontent p {
    font-size: 14px;
    line-height: 1.5rem;
    padding-top: 0;
    margin-bottom: 0;
  }

  .imgOnBox {
    height: 156px;
  }

  .contentImage {
    height: 156px;
  }

  .imageContent {
    height: 276px;
    width: 316px;
  }

  .date {
    font: 14px Helvetica;
    margin-bottom: 0;
  }

  .cardFd {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }

  .cardFd1 {
    padding: 1.5rem;
  }

  .ContentWrapp,
  .cardfront,
  .cardtemplateback {
    width: 272px;
    height: 243px;
  }
}

@media only screen and (max-width: 420px) and (min-width: 361px) {
  .sectionContentTitle {
    margin-left: 1rem;
  }
  .sectionTitle {
    font: Bold 22px Helvetica;
    margin: 0 0 0 1rem;
    text-align: left;
  }
  .sectionContentTitle {
    font: Bold 14px Helvetica;
  }
}

@media only screen and (max-width: 360px) {
  .sectionContentTitle {
    margin-left: 1rem;
  }
  .sectionTitle {
    font: Bold 22px Helvetica;
    margin: 0 0 0 1rem;
    text-align: left;
  }
  .sectionContentTitle {
    font: Bold 14px Helvetica;
  }
}
