.Orientation {
  width: 1920px;
  height: 1080px;
  background: white;
  position: relative;
  z-index: 1;
}

.boxRight {
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
}

.contentImgRight {
  width: 960px;
  height: 1080px;
  object-fit: cover;
}

.contentInBox1 {
  position: relative;
  width: 416px;
  height: 663px;
  background-color: #f1f1f1;
}

.contentInBox2 {
  position: relative;
  width: 792px;
  height: 663px;
  background-color: #f1f1f180;
  margin-left: 30px;

  /* position: absolute;
  opacity: 0; */
  backdrop-filter: blur(20px);

  /* backdrop-filter: blur(5px); */
}

.boxTitle {
  width: 50vw;
  height: auto;
  background-color: black;
  /* margin-top: 20%; */
}

.textInside p {
  text-transform: uppercase;
  /* font-size: 96px; */
  color: rgb(255 255 255 / 0%);
  font-family: Helvetica;
  text-align: right;
  font-weight: bold;
}

p.title {
  font-size: 20px Helvetica;
  font-weight: bold;
  text-align: left;
}

p.title2 {
  font-size: 16px/56px Helvetica;
  text-align: justify;
}

.boxIn {
  width: 315px;
  height: 339px;
  margin: auto;
  padding-top: 2rem;
  line-height: 2rem;
}

._box1 {
  width: 100%;
  height: 100%;
  /* background-color: blueviolet; */
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  justify-content: center;
}

._box2 {
  width: auto;
  /* min-height: 50vh; */
  min-height: 35vh;
  height: auto;
  /* background-color: blue; */
  border-radius: 30px;
  border: 10px solid #f1f1f1;
  /* margin-left: 10%; */
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: row;
}

._boxLeft {
  width: 50%;
  min-height: 25vh;
  height: auto;
  /* background-color: #f1f1f1; */
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 30px;
  border: 10px solid #f1f1f1;
  padding: 1rem;
  /* margin-left: 10%; */
}

.boxLeft2 {
  width: 50%;
  height: auto;
  /* background-color: #f1f1f1; */
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: calc(2rem + 75px);
  /* margin-left: 8%; */
}

.boxLeft2:not(:first-child) {
  width: 50%;
  padding-left: calc(2rem + 175px);
}

.boxLeft2:not(:first-child) ._Left2a {
  width: 100%;
  margin-right: 0;
}

._abs {
  position: absolute;
  right: 0;
}

.boxRight2 {
  width: 70%;
  height: auto;
  background-color: #d6d6d6;
  display: flex;
  flex-direction: column;
  position: relative;
}

._boxRight {
  width: 50%;
  min-height: 25vh;
  height: auto;
  padding: 1rem;
  /* background-color: #f1f1f1; */
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 30px;
  margin-left: 10%;
  border: 10px solid #f1f1f1;
}

._L1 {
  width: 90%;
  height: 50%;
  /* background-color: red; */
  right: 0;
  position: relative;
  margin-right: 10%;
  display: flex;
  flex-direction: row;
  padding-bottom: 1%;
  /* padding-top: 5%; */
  padding-left: calc(2rem + 75px);
}

._L1a {
  width: 50px;
  height: 50px;
  /* background-color: #9e294d; */
  border-radius: 15px;
  padding: 0.3rem;
}

._R1a {
  width: 50px;
  height: 50px;
  /* background-color: #f7bf33; */
  border-radius: 15px;
  padding: 0.3rem;
}

._L1b {
  width: 300px;
  height: 60px;
  margin-left: 1rem;
  /* background-color: #f1f1f1; */
  padding: 0.5rem;
}

._L2a {
  width: 50px;
  height: 50px;
  background-color: #1f6ea3;
  border-radius: 30px;
}

._R2a {
  width: 100px;
  height: 100px;
  background-color: #42824f;
  border-radius: 30px;
}

._L2b {
  width: 300px;
  height: 100px;
  margin-left: 1rem;
  background-color: #f1f1f1;
}

._R2c {
  width: 100px;
  height: 100px;
  background-color: #c42e36;
  border-radius: 30px;
}

._L2 {
  width: 70%;
  height: 50%;
  /* background-color: red; */
  right: 0;
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 0;
  margin-right: 10%;
  padding-bottom: 1%;
  padding-top: 5%;
}

._R1 {
  width: 90%;
  height: 50%;
  /* background-color: hotpink; */
  right: 0;
  position: relative;
  margin-right: 10%;
  display: flex;
  flex-direction: row;
  padding-bottom: 1%;
  /* padding-top: 5%; */
  padding-left: calc(2rem + 75px);
}

._Left2a {
  width: 70%;
  height: 30%;
  /* background-color: hotpink; */
  position: relative;
  margin-right: 10%;
  display: flex;
  flex-direction: row;
  padding-bottom: 1%;
  padding-top: 2%;
}

._R2 {
  width: 70%;
  height: 50%;
  /* background-color: aquamarine; */
  right: 0;
  position: absolute;
  bottom: 0;
  margin-right: 10%;
  display: flex;
  flex-direction: row;
  padding-bottom: 1%;
  padding-top: 5%;
}

.boxDetail {
  height: 40px;
  width: 40px;
  background-color: white;
  border: 1px solid #233871;
  display: flex;
  position: relative;
  margin: 0 auto -40px auto;
  border-radius: 50%;
}

._boxImgL {
  width: 180px;
  height: 180px;
  /* border: 10px solid #f1f1f1; */
  background: linear-gradient(153deg, #f1f1f1 100%, #ffffff 100%);
  position: absolute;
  top: 20%;
  left: -95px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  /* margin: 12% auto auto -14%; */
}

._boxImgR {
  width: 180px;
  height: 180px;
  /* border: 10px solid #f1f1f1; */
  background: linear-gradient(153deg, #f1f1f1 100%, #ffffff 100%);
  position: absolute;
  top: 20%;
  left: -95px;
  border-radius: 50%;
  /* margin: 12% auto auto -14%; */
}

._box2ImgR {
  width: 180px;
  height: 180px;
  /* border: 10px solid #f1f1f1; */
  background: linear-gradient(153deg, #f1f1f1 100%, #ffffff 100%);
  position: absolute;
  top: 0;
  border-radius: 50%;
  margin: 6% auto auto -6.3%;
  object-fit: cover;
  left: 0;
}

.modalTitle {
  font: bold 26px Helvetica;
  color: black;
}

.textC {
  font: normal 16px Helvetica;
  text-transform: uppercase;
  color: #43b5ea;
  text-align: left;
}
.textC p {
  display: contents;
}
.textC span {
  font: normal 16px Helvetica;
  text-transform: none !important;
}

.imgCircle {
  width: 92px;
  height: 92px;
  /* border-radius: 50%; */
  position: relative;
  object-fit: contain;
  position: absolute;
  /* margin: auto; */
  padding: 10px;
  margin: 4% auto auto 16%;
}

.titles {
  font: bold 16px Helvetica;
  color: #43b5ea;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0%;
  padding-top: 1.5rem;
}

.maintitle {
  width: auto;
  height: auto;
  text-align: center;
  margin-bottom: 2rem;
  /* margin: auto auto 2rem auto; */
}

.imgInside {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 12px;
}

._joinBox {
  margin: 7rem auto 5rem auto;
  padding-bottom: 5rem;
}

.modalCustom {
  width: 50%;
  height: 100%;
  margin: auto !important;
}

.boxModal {
  padding: 2rem;
}

.boxModal p,
.boxModal ul > li {
  line-height: 1.8rem;
  font: 16px/32px Helvetica;
  color: black;
  margin-bottom: 1rem;
}

.modalCustom .modal-dialog {
  margin: auto !important;
}

._boxInner {
  width: 150px;
  height: 150px;
  background-color: white;
  position: relative;
  /* top: 0; */
  border-radius: 50%;
  margin: 1rem auto;
}

._boxInner2 {
  width: 150px;
  height: 150px;
  background-color: white;
  position: relative;
  /* top: 0; */
  border-radius: 50%;
  margin: 1rem auto;
}

.boxIn2 {
  width: 792px;
  height: 663px;
  margin: auto;
  padding-top: 2rem;
  padding-left: 10%;
  padding-right: 10%;
  line-height: 2rem;
}

p.title2_2 {
  font-size: 20px Helvetica;
  font-weight: bold;
  text-align: left;
}

.miniBox {
  width: 176px;
  height: 56px;
  font: 26px Helvetica;
  text-transform: uppercase;
}

.boxTop {
  width: 50%;
  height: 100%;
  padding-top: 10%;
}

p.titleTop {
  font: bold 26px/32px Helvetica;
  /* text-transform: lowercase; */
}

/* p.titleTop {
  font: 28px/32px Helvetica;
  text-transform: lowercase;
} */

.textZoom {
  width: 215px;
  height: 56px;
  font: bold 36px Helvetica;
  transition: all;
}

.navBack {
  /* position: fixed;
  bottom: 5%;
  left: 4%; */
  position: relative;
  /* margin-left: -250px;
  margin-top: -165px;
  padding: 0px 0 5rem 0; */
  padding: 2rem 0 5rem 0;
  width: 30%;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.textBack {
  font: normal 16px/32px Helvetica;
  color: black;
  margin: 0;
}

.customMargin {
  margin: auto 1rem auto auto;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.customLink {
  text-decoration: none;
}

.customLink:hover {
  text-decoration: none;
}

@media only screen and (max-width: 1400px) {
  .boxLeft2:not(:first-child) {
    padding-left: calc(1rem + 150px);
  }

  .boxLeft2:not(:first-child) ._Left2a {
    width: 90%;
    margin-right: 0;
  }

  ._box2ImgR {
    margin: 6% auto auto -9.3%;
  }

  .imgCircle {
    margin: 4% auto auto 20%;
  }

  ._L1,
  ._R1,
  .boxLeft2 {
    padding-left: calc(1rem + 75px);
  }

  .Orientation {
    width: 1062px;
    height: 768px;
  }

  ._joinBox {
    margin: 4.5rem 9.5rem 3.5rem 9.5rem;
    width: 1062px;
    padding-bottom: 3.5rem;
  }

  p.titleTop {
    font: bold 18px/32px Helvetica;
    /* text-transform: lowercase; */
  }

  .contentInBox1 {
    width: 279px;
    height: 507px;
  }

  .contentInBox2 {
    width: 648px;
    height: 507px;
  }

  .boxIn {
    width: auto;
    height: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .boxTop {
    padding-top: 7%;
  }

  .boxIn2 {
    width: auto;
    height: auto;
  }

  .contentImgRight {
    width: 620px;
    height: 768px;
  }

  .boxRight {
    right: -63px;
  }

  ._boxRight {
    width: 45%;
  }

  ._boxLeft {
    width: 45%;
  }

  .miniBox {
    font: 20px Helvetica;
    width: 120px;
    margin-right: 2rem;
  }

  .textZoom {
    font: bold 26px Helvetica;
    transition: all;
  }

  .textC {
    font: normal 14px Helvetica;
  }
}
