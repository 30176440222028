.wrapper {
  width: 100vw;
}
.main {
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
}

.box {
  position: relative;
  display: flex;
}
.boxContent {
  width: 50%;
  height: 1080px;
  /* background-color: red */
}

.boxImage {
  width: 50%;
  height: 1080px;
  cursor: url("./assets/drag.svg") 39 39, auto;
  /* background-color: blue; */
}

p.categoryText {
  font: bold 18px/32px Helvetica;
}

.categoryBox {
  width: 500px;
  height: 21px;
}

.titleBox {
  width: 500px;
  height: 32px;
  padding-bottom: 2rem;
}

p.titleText {
  font: bold 24px/32px Helvetica;
}
.news {
  padding: 5rem 7rem 1rem 10rem;
  font: 18px Helvetica;
}
p.titleArticle {
  font: bold 24px/32px Helvetica;
  text-transform: uppercase;
}

.title {
  padding: 1rem 7rem 1rem 10rem;
  font: bold 24px/32px Helvetica;
}

.tgl {
  padding: 1rem 7rem 1rem 10rem;
}

.isi {
  padding: 1rem 7rem 1rem 10rem;
  text-align: justify;
}

.img_box {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}
.box_scroll {
  height: 880px;
  overflow: hidden;
  padding: 110px 0px 20px 20px;
}

.scroll_content {
  height: 770px;
  overflow: auto;
  cursor: url("./assets/scrolldark.svg") 39 39, auto;
  padding-top: 2rem;
}
.box_togle {
  display: flex;
  padding: 2rem 7rem 1rem 10rem;
}
.images_goback {
  /* padding: 2rem 2rem 1rem 10rem; */
  display: flex;
  margin-top: -2%;
}
.share {
  /* z-index: 200; */
  position: absolute;
  display: flex;
  width: 200px;
  /* right: 48%; */
  right: 0;
  left: 37%;
  top: 8%;
  font-family: Helvetica;
}
.figure {
    overflow: hidden;
}
.boxRight {
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
  opacity: 1;
}

.contentImgRight {
  width: 960px;
  height: 1080px;
  object-fit: cover;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  overflow: hidden;

}

.box_absolute {
  width: 50px;
  height: 100px;
  padding: 0 7rem 8rem 10rem;
}

.boxBack {
  width: 500px;
  height: 21px;
  padding-bottom: 2rem;
  cursor: pointer;
}

.content_hover {
  background-color: rgb(50 68 174 / 58%);
  width: 100%;
  height: 220px;
  bottom: 0;
  position: absolute;
  z-index: 1;
  opacity: 0;
}
.boxRight .content_hover {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.boxRight:hover .content_hover {
  opacity: 1;
}

.contentImgRight:hover {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.padd {
  display: flex;
  flex-direction: row;
  padding-top: 10%;
  padding-left: 10%;
}

.imgDiv {
  width: 332px;
  height: 480px;
}

.text_hover {
  width: 200px;
  opacity: 1;
  object-fit: cover;
}
