.circleImage {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    width: 30px;
    height: 30px;
}

.circleImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.active {
    color: #F16B26;
}
.inactive {
    color: grey;
}

.contentLang {
    font: 16px/32px Helvetica;
    cursor: pointer;
}

@media only screen and (max-width: 1376px) {
    .contentLang {
        font: 14px Helvetica;
        margin: auto auto auto 1rem;
    }
}

@media only screen and (max-width: 420px) {
    .iconLang{
        width: 15%;
    }
}

@media only screen and (max-width: 360px) {
    .iconLang{
        width: 15%;
    }
}