.customContainer {
    padding: 5rem 0;
    height: 100%;
    width: 100%;
}

.boxNav {
    display: flex;
    margin-bottom: 1rem;
}

.customLink {
    display: flex;
    text-decoration: none;
}

.customLink:hover {
    text-decoration: none;
}

.backButton {
    font: 12px/16px Helvetica;
    color: #000000;
    margin: 0 0 0 1rem;
}

.category {
    font: 18px/32px Helvetica;
    color: #000000;
}

.title {
    text-align: left;
    font: bold 24px/32px Helvetica;
    letter-spacing: -0.24px;
    color: #000000;
    text-transform: uppercase;
}

.boxImage {
    width: 100%;
    height: 504px;
}

.contentImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.titleContent {
    font: bold 26px/31px Helvetica;
    color: #000000;
}

.content {
    font: 16px/32px Helvetica;
    color: #000000;
}

.boxLeft {
    padding: 0 5rem 0 0;
    width: 60vw;
}

.boxRight {
    width: calc((100% - 624px) + 10vw);
    height: 100%;
    background-color: transparent;
    display: block;
    position: relative;
}

.boxContentOverflow {
    padding: 2rem 2rem;
    width: 624px;
    height: 100%;
    background-color: #D4D4D4;
    margin-top: 1rem;
    /* box-shadow: 0px 6px 16px #00000073; */
    overflow-y: hidden;
    text-align: justify;
}

.contentDesc {
    font: 16px/32px Helvetica;
    color: #000000;
    text-align: justify;
}

.boxContent ul>li {
    font: 16px/32px Helvetica;
    margin-bottom: 1rem;
    color: #000000;
}

.sectionContent {
    font: 16px/32px Helvetica;
    text-align: justify;
}

.sectionContent ul {
    margin-top: 1rem;
}

.sectionContent strong {
    display: block;
    margin-bottom: 1rem;
    font: 20px/24px Helvetica;
    color: #000000;
}

.sectionContent div {
    margin-top: 2rem;
}

.section2 {
    margin: 2rem 0;
}

.subTitle,
.subTitle strong {
    text-align: left;
    font: bold 26px/31px Helvetica !important;
    color: #000000;
}

.boxWrapping {
    background-color: #F1F1F1;
}

.s2_boxLeft,
.s2_boxRight {
    width: 50%;
    height: 100%;
    margin: 0 !important;
}

.s2_boxLeft div,
.s2_boxRight div {
    padding: 2rem;
    margin: 0;
    text-align: justify;
}

.boxList {
    width: 624px;
    height: 100%;
    background-color: #D4D4D4;
}

.boxX {
    width: 320px;
    height: 152px;
    background: #03428E 0% 0% no-repeat padding-box;
    margin: 0 1rem 1rem 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.num {
    text-align: center;
    font: 80px Helvetica;
    color: #FFFFFF;
    margin: 0;
}

.num1 {
    text-align: center;
    font: 60px Helvetica;
    color: #FFFFFF;
}

.wordFlip {
    display: inline;
    text-indent: 10px;
}

.relative {
    position: relative;
    display: block;
}

.animateFlip span{
	position: absolute;
	opacity: 0;
	overflow: hidden;
	color: #000000;
	-webkit-animation: rotateWord 18s linear infinite 0s;
	-ms-animation: rotateWord 18s linear infinite 0s;
	animation: rotateWord 18s linear infinite 0s;
}
.animateFlip span:nth-child(2) { 
    -webkit-animation-delay: 3s; 
	-ms-animation-delay: 3s; 
	animation-delay: 3s; 
	color: #000000;
}
.animateFlip span:nth-child(3) { 
    -webkit-animation-delay: 6s; 
	-ms-animation-delay: 6s; 
	animation-delay: 6s; 
	color: #000000;	
}
.animateFlip span:nth-child(4) { 
    -webkit-animation-delay: 9s; 
	-ms-animation-delay: 9s; 
	animation-delay: 9s; 
	color: #000000;
}
.animateFlip span:nth-child(5) { 
    -webkit-animation-delay: 12s; 
	-ms-animation-delay: 12s; 
	animation-delay: 12s; 
	color: #000000;
}
.animateFlip span:nth-child(6) { 
    -webkit-animation-delay: 15s; 
	-ms-animation-delay: 15s; 
	animation-delay: 15s; 
	color: #000000;
}

@-webkit-keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; -webkit-transform: translateY(-30px); }
	5% { opacity: 1; -webkit-transform: translateY(0px);}
    17% { opacity: 1; -webkit-transform: translateY(0px); }
	20% { opacity: 0; -webkit-transform: translateY(30px); }
	80% { opacity: 0; }
    100% { opacity: 0; }
}
@-ms-keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; -ms-transform: translateY(-30px); }
	5% { opacity: 1; -ms-transform: translateY(0px);}
    17% { opacity: 1; -ms-transform: translateY(0px); }
	20% { opacity: 0; -ms-transform: translateY(30px); }
	80% { opacity: 0; }
    100% { opacity: 0; }
}
@keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; -webkit-transform: translateY(-30px); transform: translateY(-30px); }
	5% { opacity: 1; -webkit-transform: translateY(0px); transform: translateY(0px);}
    17% { opacity: 1; -webkit-transform: translateY(0px); transform: translateY(0px); }
	20% { opacity: 0; -webkit-transform: translateY(30px); transform: translateY(30px); }
	80% { opacity: 0; }
    100% { opacity: 0; }
}

.boxxContent {
    text-align: center;
    font: 14px/32px Helvetica;
    color: #FFFFFF;
}

.titleSection {
    text-align: left;
    font: 20px/24px Helvetica;
    color: #000000;
}

.boxImageLeft {
    width: 100%;
    height: 360px;
    background: #D4D4D4 0% 0% no-repeat padding-box;
    margin: 0 0 0 2rem !important;
}

.boxFullImage {
    width: 100%;
    height: 512px;
    background-color: #F1F1F1;
    display: flex;
    position: relative;
}

.boxFullImage img {
    width: 100%;
    height: 472px;
    margin: auto;
    object-fit: contain;
}

.section3 {
    margin: 2rem 0;
}

.box {
    width: 336px;
    height: 176px;
    margin-right: 1rem;
    position: relative;
}

.box:hover .contentFront {
    opacity: 0;
}

.box:hover .contentBack {
    opacity: 1;
}

.box:hover .boxMask {
    opacity: .8;
}

.box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contentFront,
.contentBack {
    width: 336px;
    height: 176px;
    position: absolute;
    top: 0;
    left: 0;
}

.contentFront {
    opacity: 1;
}

.contentBack {
    opacity: 0;
}

.boxMask {
    width: 336px;
    height: 176px;
    position: absolute;
    top: 0;
    left: 0;
    background: #223771 0% 0% no-repeat padding-box;
    opacity: .5;
}

.boxContentIn {
    width: 336px;
    height: 176px;
    display: flex;
    justify-content: flex-end;
    align-self: center;
    position: relative;
}

.boxContentIn p {
    text-align: right;
    font: bold 16px/15px Helvetica;
    color: #FFFFFF;
    margin: auto 0;
    padding: 0 1rem 0 0;
    display: block;
}

.outerCircle {
    padding: 5px;
    transition: transform 0.2s;
    border-radius: 50%;
    border: 1px solid #F16624;
    display: inline-block;
    position: relative;
    opacity: 1;
    margin: .5rem 0 auto auto;
}

.innerCircle {
    content: "";
    background: #F16624;
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

.boxContentIn .viewMore {
    text-align: right;
    font: 14px Helvetica;
    color: #FFFFFF;
    margin: .6rem 2rem 0 0;
    padding: 0 0 0 .5rem;
    display: block;
}