
.active {
    color: #f16b26;
}

.dropDownLang {
    position: relative;
}

.dropDownBtn {
    color: #707070;
    font-size: 1.4rem;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    border: none;
    background: 0 0;
}

.dropdownMenu {
    position: absolute;
    width: 25rem;
    min-width: 100%;
    background: #f8f8f8;
    top: 25%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-transition: all .5s cubic-bezier(.215, .61, .355, 1);
    transition: all .5s cubic-bezier(.215, .61, .355, 1);
    -webkit-box-shadow: 0 0.5rem 1rem rgba(29, 29, 29, .08);
    box-shadow: 0 0.5rem 1rem rgba(29, 29, 29, .08);

    width: 16rem;
    left: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.slNav {
    display: inline;
}

.slNav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
}

.slNav li {
    cursor: pointer;
    padding-bottom: 10px;
    color: #f16b26;
}

.slNav li ul {
    display: none;
}

.slNav li:hover ul {
    position: absolute;
    top: 29px;
    right: -15px;
    display: block;
    background: #fff;
    width: 150px;
    padding-top: 0px;
    z-index: 1;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.slNav li:hover .triangle {
    position: absolute;
    top: 15px;
    right: -10px;
    z-index: 10;
    height: 14px;
    overflow: hidden;
    width: 30px;
    background: transparent;
}

.slNav li:hover .triangle:after {
    content: '';
    display: block;
    z-index: 20;
    width: 15px;
    transform: rotate(45deg) translateY(0px) translatex(10px);
    height: 15px;
    background: #fff;
    border-radius: 2px 0px 0px 0px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.slNav li ul li {
    position: relative;
    text-align: left;
    background: transparent;
    padding: 15px 15px;
    padding-bottom: 0;
    z-index: 2;
    font-size: 15px;
    color: #000;
}

.slNav li ul li:last-of-type {
    padding-bottom: 15px;
}

.slNav li ul li span {
    padding-left: 5px;
}

.slNav li ul li span:hover,
.slNav li ul li span.active {
    color: #f16b26;
}

.slFlag {
    display: inline-block;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
    width: 15px;
    height: 15px;
    background: transparent;
    border-radius: 50%;
    position: relative;
    /* top: 2px; */
    overflow: hidden;
}

.imgFlag {
    height: 15px;
    width: 15px;
    object-fit: cover;
    margin-top: -10px;
}