/* GENERAL */
:root {
  --title-hd: bold 28px Helvetica;
  --subtitle-hd: bold 18px Helvetica;
  --p-hd: normal 14px Helvetica;
  --container-hd: 1062px !important;
}

/* SPECIFIC */
.sectionTitle {
  color: black;
  font: bold 26px Helvetica;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.sectionContentTitle {
  /* color: white; */
  font: bold 26px Helvetica;
  margin: 0 0 1rem 0;
}

.contentDetail p {
  font: 14px/24px Helvetica;
  margin-bottom: 0;
}
.contentImage {
  height: 200px;
}
.coverBlue {
  width: 416px;
  height: 350px;
  background: #223870 0 0 no-repeat padding-box;
  opacity: 0.5;
  position: absolute;
}
.contentImageD {
  width: 480px;
  height: 288px;
  object-fit: contain;
}

.imgOnBox {
  object-fit: cover;
  height: 276px;
  width: 100%;
}

.imgOnBoxD {
  object-fit: contain;
  height: 276px;
  width: 100%;
}

.imageContent {
  width: auto;
  height: 376px;
  object-fit: cover;
}

.imageContentD {
  width: auto;
  height: 376px;
  object-fit: contain;
}

.paddingBox {
  padding-bottom: 5rem;
}

.imageBack {
  width: 100vw;
  height: auto;
  background-size: cover;
}

.customLink:hover {
  text-decoration: none;
}

.bxf1r {
  position: relative;
  width: 304px;
  height: 272px;
  /* background: #223771; */
  background: transparent;
  margin: 0 16px 0 0;
  cursor: url("./assets/drag.svg") 20 20, auto;
}

.ContentWrapp {
  position: relative;
  width: 304px;
  height: 272px;
  background: transparent;
  margin: 0 16px 0 0;
  cursor: url("./assets/drag.svg") 20 20, auto;
}

.shadowHover {
  width: 304px;
  height: 272px;
  box-shadow: 0px 16px 20px 7px #2b407b;
  opacity: 0;
}

.cardtemplateback {
  width: 304px;
  height: 272px;
  position: relative;
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  /* background: rgba(29, 106, 154, 0.72); */
  background: #f1f1f1;
  color: #fff;
  /* visibility: hidden; */
  margin-right: 20px;
  opacity: 1;
  border: 0;
  transition: opacity 0.2s, visibility 0.2s;
}

.shdw0 {
  width: 304px;
  height: 272px;
  position: absolute;
  top: 0;
  opacity: 1;
  z-index: 1;
  background: hsl(224 54% 29% / 0.5);
}

/* .ContentWrapp:hover .cardtemplateback {
  visibility: visible;
  opacity: 1;
} */

.ContentWrapp:hover .shadowHover {
  opacity: 1;
}

.contentWrapping {
  padding: 0;
}

.customPadding {
  padding: 2rem 0 0 0;
}

.bxf1r:hover .shdw0 {
  opacity: 0;
  z-index: -1;
}

.readMore {
  font-size: 14px Helvetica;
  color: white;
}

.date {
  font: 16px Helvetica;
  color: white;
}

@media only screen and (max-width: 1400px) {
    .date {
        font: 14px Helvetica;
        color: white;
    }
}

    .cardFd1 {
        padding: 2rem;
    }

    .cardFd {
        padding: 0 2rem 2rem 2rem;
    }

    .cardfooterback {
        position: relative;
        border: none;
        margin: auto;
        background: none;
    }

    .btnRead {
        background-color: #223771;
        height: 2.4rem;
        width: 7rem;
        border-radius: 50px;
        align-self: center;
    }

        .btnRead p {
            color: white;
            font: bold 12px Helvetica;
            padding: 0.8rem 0;
            margin: auto;
            text-align: center;
        }

        .btnRead:hover {
            background-color: #f16624;
        }

    .cardfront,
    .cardfrontside {
        position: absolute;
        width: 304px;
        height: 272px;
        background: transparent 0 no-repeat padding-box;
        /* opacity: 1; */
        display: flex !important;
        top: 0;
    }

    .cardcontent p {
        color: white;
        font-family: Helvetica;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 12ch;
        line-height: 1.8rem;
        text-align: left;
        font-weight: bold;
    }

    .imgOnBox {
        object-fit: cover;
        height: 200px;
        width: 100%;
    }

    @media only screen and (min-width: 2560px) {
        .joinC {
            max-width: 2000px !important;
            margin-left: 235px;
        }

        .joinT {
            max-width: 2000px !important;
            margin-left: 235px;
        }

        .cardcontent p {
            font: bold 26px Helvetica;
            line-height: 2.5rem;
        }

        .bxf1r,
        .shdw0,
        .cardtemplateback {
            width: 472px;
            height: 360px;
        }

        .cardFd1 {
            padding: 3rem;
        }

        .cardFd {
            padding: 0 3rem 3rem 3rem;
        }

        .cardfooterback {
            margin: 5rem auto auto auto;
        }

        .imgOnBox {
            height: 245px;
        }

        .shadowHover {
            width: 472px;
            height: 360px;
        }
    }

    @media only screen and (max-width: 1400px) {
        .sectionTitle {
            font: var(--title-hd);
            margin-left: 169px;
            font: bold 18px Helvetica;
        }

        .cardcontent p {
            font-size: 14px;
            line-height: 1.5rem;
            padding-top: 0;
            margin-bottom: 0;
        }

        .joinT {
            max-width: 1062px;
            margin-left: 169px;
        }

        .sectionContentTitle {
            font: 18px/32px Helvetica;
            margin: 0 0 0.5rem 190px;
        }

        .imgOnBox {
            height: 156px;
        }

        .contentImage {
            height: 156px;
        }

        .imageContent {
            height: 276px;
            width: 316px;
        }

        .shdw0,
        .cardtemplateback {
            width: 272px;
            height: 243px;
        }

        .bxf1r {
            width: 272px;
            height: 243px;
            margin: 0 82px 0 0;
        }

        .cardFd1 {
            padding: 1.5rem;
        }

        .cardFd {
            padding: 0 1.5rem 1.5rem 1.5rem;
        }

        .shadowHover {
            width: 272px;
            height: 243px;
        }
    }

    @media only screen and (max-width: 420px) and (min-width: 361px) {
        .sectionContentTitle {
            margin-left: 1rem;
        }

        .sectionTitle {
            font: Bold 26px Helvetica;
            margin: 0 0 0 1rem;
            text-align: left;
        }

        .sectionContentTitle {
            font: Bold 14px Helvetica;
        }
    }

    @media only screen and (max-width: 360px) {
        .sectionContentTitle {
            margin-left: 1rem;
        }

        .sectionTitle {
            font: Bold 26px Helvetica;
            margin: 0 0 0 1rem;
            text-align: left;
        }

        .sectionContentTitle {
            font: Bold 14px Helvetica;
        }
    }
