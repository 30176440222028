.boxLeftx {
    width: 50%;
    height: 100%;
    position: relative;
    padding: 0 2rem 0 0;
}

.boxRightx {
    width: 50%;
    height: 100%;
    position: relative;
    padding: 1rem 0 0 2rem;
}