.imgLogo {
  height: 100%;
  width: 100%;
  color: white;
  z-index: 100;
  margin: 1rem 0 0 2rem;
}

.navLogo {
  z-index: 100;
}

.circleLink {
  width: 96px;
  height: 96px;
  background: #223771 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 50%;
  position: absolute;
  top: 16%;
  right: 10%;
}

.circleLink div {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
}

.circleLink span {
  color: white;
  margin: auto;
  text-align: center;
  font: 16px/55px Helvetica;
  letter-spacing: 0px;
}

@media only screen and (max-width: 420px) {
  .imgLogo {
    width: 40px !important;
    height: 40px !important;
  }
}

@media only screen and (max-width: 360px) {
  .imgLogo {
    width: 40px !important;
    height: 40px !important;
  }
}

@media only screen and (max-width: 1400px) {
  .circleLink {
    width: 80px;
    height: 80px;
    top: 15%;
  }
  .circleLink div {
    width: 100%;
    height: 100%;
  }
  .circleLink span {
    font-size: 14px;
  }
}

@media only screen and (min-width: 2560px) {
  .circleLink {
    top: 13.5%;
  }
}
