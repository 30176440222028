.wrap {
  /* height: auto; */
  height: 100%;
  width: 100vw;
  background-color: white;
  /* padding-top: 5rem !important; */
}

.boxGlobal {
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
  padding: 5rem 0;
  width: 100%;
  height: 100vh;
}

.boxImage {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  /* height: 100%; */
  height: 100vh;
  width: 100%;
}

.imageMask {
  height: 100%;
  width: 100vw;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

p.content {
  font: 18px/32px Helvetica;
  color: black;
}

span.num {
  font: bold 65px/48px Helvetica;
  margin-right: 0.5rem;
  color: #f16624;
}

span.text {
  font: Bold 26px/48px Helvetica;
  color: #000000;
}

p.span.content {
  font: 20px/48px Helvetica;
}

.boxLight {
  height: 156px;
  width: 450px;
  /* background-color: #e9e9e9; */
  /* background-color: transparent; */
  background-color: rgb(128, 128, 128, 0.5);
  display: flex;
  /* border: .2rem solid #f16624; */
  margin: 0 1rem;
}

.boxDark {
  height: 156px;
  width: 450px;
  /* background-color: #e1e1e1; */
  /* background-color: transparent; */
  /* border: .2rem solid #f16624; */
  background-color: rgb(128, 128, 128, 0.5);
  display: flex;
}

/* .boxNation{
    height: 181px;
} */

.boxNation {
  margin: 0 2.8rem;
}

.boxNation p {
  color: #707070;
  font: 20px/48px Helvetica;
  margin: 0;
}

.m_auto {
  margin: auto !important;
  width: 960px;
}

.tools {
  position: absolute;
  bottom: 28%;
  right: 25%;
  z-index: 4;
}

.button_map {
  width: 40px;
  height: 40px;
  border: none;
  margin-right: 10px;
  font-size: 20px;
}

.boxContent {
  display: flex;
  margin: 0 auto 0 auto;
  position: relative;
}

.boxTitle {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #e1e1e1;
}

.boxTitle p {
  font: bold 26px/48px Helvetica;
  padding: 0.5rem 0 0.5rem 2rem;
}

.country {
  font: 16px Helvetica;
  color: #223771;
}

.containerCountry {
  margin-top: 2rem;
}

.boxCountry {
  padding: 1rem 2rem 1rem 0;
  /* border-right: 2px solid #223771; */
  margin: 0 1rem;
  width: 15%;
  position: relative;
}

.boxCountry:after {
  content: "" !important;
  position: absolute;
  right: 0;
  top: 0;
  display: block !important;
  margin-top: 18px !important;
  height: 80% !important;
  width: 100% !important;
  border-right: 2px solid #223771;
}

@media only screen and (min-width: 2560px) {
  .boxLight {
    width: 640px;
    margin: 0 2.4rem;
  }

  .boxDark {
    width: 670px;
  }
}

@media only screen and (max-width: 1400px) {
  .boxLight {
    height: 110px;
    width: 340px;
  }

  .boxDark {
    height: 110px;
    width: 340px;
  }

  span.num {
    font-size: 32px;
  }

  span.text {
    font-size: 22px;
  }
    .boxGlobal {
        padding: 2rem 0;
    }
  /* p,
  span {
    font-size: 13px;
  } */
  .m_auto {
    width: 700px;
  }

  .tools {
    bottom: 23%;
    right: 30%;
  }

  .button_map {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 420px) and (min-width: 361px) {
  .boxGlobal {
    padding-top: 2rem;
  }

  .box {
    width: 360px;
    height: 176px;
    background-color: #223771;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .contentBox {
    margin: auto;
  }

  .textSlider {
    font: 26px Helvetica;
    color: white;
    margin-left: 1rem;
  }

  .contentSlider {
    font: 55px Helvetica;
    color: white;
  }
}

@media only screen and (max-width: 360px) {
  .boxGlobal {
    padding-top: 2rem;
  }

  .box {
    width: 360px;
    height: 176px;
    background-color: #223771;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .contentBox {
    margin: auto;
  }

  .textSlider {
    font: 26px Helvetica;
    color: white;
    margin-left: 1rem;
  }

  .contentSlider {
    font: 55px Helvetica;
    color: white;
  }
}
