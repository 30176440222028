.wrapp {
  height: 100vh;
  width: 100%;
  background-color: transparent;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
}

.box_fake {
  position: absolute;
  z-index: 3;
  width: 384px;
  height: 100vh;
  opacity: 0;
}

._boxOne {
  width: 70vw;
  height: auto;
  margin: auto 6rem;
  /* background-color: rgb(34 57 113 / 60%); */
  display: flex;
  flex-direction: row;
  /* background-color: pink; */
}

.box_year {
  font: 40px Helvetica;
  text-transform: uppercase;
  color: white;
  margin: auto;
}

.box_main {
  height: auto;
  width: auto;
  /* background-color: rgb(34 57 113 / 60%); */
  margin: auto;
  cursor: pointer;
}

.highlight {
  width: 384px;
  height: 100vh;
  background-color: #f1f1f1 !important;
}

._flexD {
  display: flex;
  flex-direction: column;
}

.color1 {
  opacity: 0 !important;
}

/* .box_main:hover .box_year {
  background-color: #f26522;
  font: bold 70px Helvetica;
  color: white;
} */

.text_fake {
  color: rgb(115 112 112 / 45%);
  transform: rotate(90deg);
  font-family: Helvetica;
  font-size: 115px;
  font-weight: 1000;
  position: re;
  opacity: 0;
}

.box_fake:hover {
  opacity: 1;
}

.textZoom {
  /* background-color: #f26522; */
  transition: all;
  color: white;
}

.textZoom p {
  color: white;
  font: bold 60px Helvetica;
}

._flexDir {
  display: flex;
  flex-direction: column;
}

/* .boxTopList {
  width: auto;
} */

.box {
  width: 384px;
  height: 100vh;
  /* background-image: url("./asset//AEC-final.jpg") ; */
  /* background-color: transparent; */
  object-fit: cover;
  overflow: hidden;
  position: relative;

  cursor: url("./asset/scroll.svg") 39 39, auto;
  transition: width 1s;
}

/* .box:hover, .box:hover .figureimg, .box:hover .image_box{
  width: 100vw;
} */

.boxHighlight {
  background-color: tomato;
  width: 384px;
  height: 100vh;
}

.figureimg {
  width: 384px;
  height: 100%;
  margin: 0;
  padding: 0;
  /* background: #fff; */
  overflow: hidden;
}

.btn_hover {
  position: absolute;
  /* width: 50px; */
  /* height: 50px; */
  /* z-index: 7; */
  top: 45%;
  left: 15%;
  opacity: 0;
  max-width: 50px;
  max-height: 50px;
}

/*.box img {*/
.image_box {
  width: 384px;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.8s ease-in-out;
  transition: 0.8s ease-in-out;
  overflow: hidden;
}

.outerCircle {
  padding: 5px;
  /* background-color: rgb(45, 57, 69, 0.5); */
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  bottom: -1rem;
  left: 0rem;
  opacity: 1;
  margin: auto 0.5rem auto 0;
  width: 20px;
  align-self: center;
}

.txt {
  padding: 5px;
  /* background-color: rgb(45, 57, 69, 0.5); */
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #fff;
  display: inline-block;
  bottom: -1rem;
  left: 0rem;
  opacity: 1;
  margin: auto 0.5rem auto 0;
  width: 20px;
}

.dxt {
  content: "";
  background: #fff !important;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.innerCircle {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.image_box_default {
  width: 384px;
  height: 100vh;
  object-fit: contain;
  object-position: center;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transition: 0.8s ease-in-out;
  transition: 0.8s ease-in-out;
  overflow: hidden;
}

._boxPrev {
  width: 80vw;
  height: 5vh;
  background-color: #f1f1f1;
  margin-left: 2rem;
}

/* .box:hover .figureimg {
    width: 100%;
    opacity: 0.6;
}

.box:hover p {
    opacity: 1;
} */

.colorbox {
  position: absolute;
  width: 322px;
  height: 22%;
  background-color: #00000040;
  z-index: 6;
  top: 61%;
  /* padding: 2rem; */
  margin: 2rem;
}

._overlay {
  width: 384px;
  position: absolute;
  height: 100%;
  background-color: rgb(9 9 10 / 27%);
  z-index: 1;
}

.box:hover ._overlay {
  background: rgb(9 9 10 / 27%);
}

.title_mid p {
  /* position: absolute; */
  color: black;
  z-index: 1;
  font-size: 22px;
  font-weight: bold;
  width: auto;
  /* text-align: justify; */
  font-family: Helvetica;
  z-index: 6;
  text-transform: uppercase;
}

.title_mid2 p {
  position: absolute;
  color: white;
  z-index: 1;
  font-size: 16px;
  font-weight: bold;
  width: auto;
  /* text-align: center; */
  font-family: Helvetica;
  z-index: 6;
  /* text-transform: uppercase; */
  /* top: 0%; */
  padding: 2rem;
  opacity: 1;
  text-transform: uppercase;
}

.p_mid {
  position: absolute;
  z-index: 6;
  height: auto;
  /* top: 60%; */
  /* left: 20%; */
  background-color: rgb(255 255 255 / 0.55);
  padding: 4rem 4rem;
  /* overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; */
  /* -webkit-box-orient: vertical; */
  /* margin: auto 1rem; */
}

.boxNone {
  display: none;
}

.p_text {
  font: normal 16px/1.8rem Helvetica;
  color: black;
  text-align: justify;
  padding-bottom: 1rem;
}

/* .p_mid li {
  display: none;
} */

.p_mid2 {
  position: absolute;
  color: white;
  z-index: 1;
  font-size: 16px;
  font-weight: bold;
  height: 200px;
  width: 384px;
  bottom: 37%;
  padding: 15px;
  font-family: Helvetica;
}

.disableNext {
  display: none;
}

.disableTrue {
  display: inline-block;
}

.boxDetail {
  background-color: rgb(0, 0, 0, 0.1);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 0;
}

.boxPreview {
  width: 100vw;
  height: 100vh;
  background-color: white;
}

.boxDetailShow {
  height: 100vh;
  width: 100vw;
  z-index: 6;
  opacity: 1;
}

.boxDetailShow h1 {
  color: white;
}

.image_full {
  /* background: #223870 0 0 no-repeat padding-box; */
  position: absolute;
  z-index: 6;
  width: 100vw;
  height: 100vh;
  opacity: 0.6;
  /* cursor: url('./../Historysample//asset/drag.svg')39 39,auto; */
}

.paragraph {
  color: white;
  position: absolute;
  z-index: 8;
  top: 50%;
  left: 20%;
  font: 20px/35px Helvetica;
  text-transform: uppercase;
}

._boxIns {
  width: 100vw;
  height: 100vh;
  background-color: #f1f1f1;
}

._flex {
  bottom: 25%;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: auto;
  padding: 2rem auto;
  margin: auto;
  width: 65vw;
}

._flexRow {
  display: flex;
  position: relative;
  margin: auto;
  flex-direction: row;
}

/* p._box1 {
  margin: 0;
} */

._box1 {
  width: 25%;
  height: 12vh;
  /* background-color: yellowgreen; */
}

._box2 {
  width: 75%;
  height: 15vh;
  /* background-color: pink; */
}

._years {
  font: bold 75px Helvetica;
  color: #f26522;
  text-align: center;
}

p._years {
  margin-top: 2rem;
}

._box2_content {
  display: flex;
  flex-direction: column;
}

._column1 {
  width: auto;
  height: 6vh;
  /* background-color: purple; */
}

._column2 {
  width: auto;
  height: 6vh;
  padding-top: 1rem;
  /* background-color: rosybrown; */
}

._tmline {
  width: auto;
  height: 7vh;
  background-color: rgb(34 57 113 / 60%);
}

._title1 {
  font: bold 22px Helvetica;
  text-transform: uppercase;
  text-align: left;
  color: white;
  /* padding: 1rem; */
  margin: 0;
  padding-top: 2rem;
}

._title2 {
  font: normal 16px Helvetica;
  text-align: left;
  color: white;
  padding-right: 2rem;
  margin: 0;
}

.thn {
  color: white;
  /* position: absolute; */
  z-index: 8;
  top: 30%;
  left: 20%;
  font-size: 160px;
  font-weight: 1000;
  opacity: 0.3;
  font-family: Helvetica;
}

.thn p {
  color: white;
  /* position: absolute; */
}

.text {
  color: white;
  /* position: absolute; */
  z-index: 8;
  top: 60%;
  left: 20%;

  font: 16px/16px Helvetica;
  /* font-size: 18px;
    font-family: Helvetica; */
}

.xxx {
  color: white;
  /* position: absolute; */

  z-index: 8;
  top: 63%;
  left: 20%;

  font: 16px/16px Helvetica;
  /* font-size: 18px;
    font-family: Helvetica; */
}

.img_details {
  width: 100vw;
  height: 100vh;
}

.img_details span p {
  color: white !important;
}

.img_minus {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 7;
  top: 49%;
  left: 10%;
}

.arrow_left {
  background: #ffffff40;
  position: absolute;
  width: 70px;
  height: 70px;
  z-index: 7;
  bottom: 9%;
  left: 9%;
  padding: 23px;
  border-radius: 42px;
}

.arrow_right {
  position: absolute;
  width: 70px;
  height: 70px;
  z-index: 7;
  bottom: 9%;
  right: 9%;
  background: #ffffff40;
  padding: 23px;
  border-radius: 42px;
}

.arrow_right2 {
  /* position: absolute; */
  width: 70px;
  height: 70px;
  z-index: 7;
  bottom: 9%;
  right: 9%;
  background: transparent;
  padding: 23px;
  border-radius: 42px;
  opacity: 0;
}

.imgFull {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}

.imgFull p {
  color: white;
}

._boxBtn {
  width: 50px;
  height: 50px;
  background-color: coral;
}

.arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.activeImg,
/* .activeImg > div, */
.activeImg>.colorbox,
.activeImg>.figureimg,
.activeImg>.figureimg>.image_box {
  width: 100vw !important;
  transition: width 1s;
}

.activeImg > .title_mid p {
  width: 100%;
  position: relative;
  padding: 0;
}

.activeImg > .title_mid2 p {
  opacity: 0;
  z-index: 0;
  display: none;
}

.activeImg > .p_mid {
  width: 20vw;
  height: 100vh;
  /* top: 60%; */
  right: 0;
}

.activeImg .toggleHide {
  opacity: 1;
}

.activeImg .text_fake {
  transform: rotate(0deg);
  right: 20px;
  position: relative;
  /* left: 22%;
  top: 16%; */
  color: rgb(115 112 112 / 45%);
  opacity: 1 !important;
}

.activeImg .colorbox {
  background: none !important;
}

.activeImg:hover .colorbox {
  background: transparent !important;
}

.activeImg {
  cursor: default !important;
}

.inActiveImg {
  width: 0;
  transition: width 1s;
}

.activeMinus {
  opacity: 1 !important;
}

.toggleHide {
  position: absolute;
  top: 43%;
  left: 15%;
  z-index: 10000;
  height: 50px;
  width: 50px;
  opacity: 0;
  cursor: pointer;
  display: block;
}

.toggleNav {
  position: absolute;
  bottom: 20%;
  left: 0;
  opacity: 0;
  z-index: 1000;
  width: 100%;
}

.activeNav {
  opacity: 1 !important;
}

.arrowLeft {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 15%;
  z-index: 6;
  top: 80%;
  cursor: pointer;
}

.arrowRight {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 4%;
  z-index: 6;
  top: 80%;
  cursor: pointer;
}

.navBack {
  position: absolute;
  bottom: 2rem;
  /* left: 5rem; */
  z-index: 3;
  display: flex;
  flex-direction: row;
  background-color: rgb(34 57 113 / 60%);
  width: 100%;
  opacity: 1;
}

.backPrev {
  font: bold 18px Helvetica;
  color: white;
  text-align: center;
  /* margin-left: 2rem; */
  margin-left: 1rem;
  margin-top: 0.65rem;
  /* text-align: center; */
  /* margin-bottom: 0; */
}

._rowDir {
  display: flex;
  flex-direction: row;
  /* margin-left: 5rem; */
  margin: 1rem 0 0 2rem;
  cursor: pointer;
}

.customMargin {
  margin: auto;
  margin-top: 1rem;
}

.customLink:hover {
  text-decoration: none;
}

._marg {
  margin: auto;
}

._boxIns2 {
  width: auto;
  height: 16vh;
  background-color: rgb(34 57 113 / 60%);
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 1400px) {
  .box {
    width: 264px;
  }

  .inActiveImg {
    width: 0 !important;
  }

  .colorbox {
    width: 222px;
    margin: 1rem;
  }

  .thn {
    font-size: 120px;
  }

  .box_year {
    font: 26px Helvetica;
  }

  .figureimg,
  .image_box {
    width: 264px;
  }

  .arrow_left {
    bottom: 10%;
  }

  .navBack {
    height: 65px;
  }

  .arrow_right {
    bottom: 11%;
    right: 10%;
  }

  .backPrev {
    font: 16px/48px Helvetica;
    margin-top: -0.35rem;
  }

  .title_mid p {
    font-size: 16px;
  }

  .title_mid2 p {
    font-size: 14px !important;
    text-transform: uppercase;
    padding: 1rem;
  }

  .p_mid {
    font-size: 14px;
    padding: 2rem 2rem;
  }

  .text_fake {
    font-size: 40px;
    top: 3%;
    right: 0px !important;
  }

  ._column2 {
    padding-top: 0;
  }

  ._title1 {
    padding-top: 0;
    font: bold 16px Helvetica;
  }

  ._title2 {
    font: normal 14px Helvetica;
  }

  ._years {
    font: bold 50px Helvetica;
  }

  .colorbox {
    top: 57%;
  }

  .textZoom p {
    font: bold 36px Helvetica;
  }

  .p_text {
    font: normal 14px/22px Helvetica;
  }

  .toggleHide {
    height: 40px;
    width: 40px;
  }
  .arrowRight,
  .arrowLeft {
    height: 20px;
    width: 20px;
  }
}
