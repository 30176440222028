.wrappContent {
    width: 100%;
    height: 100vh;
    position: relative;
    // padding-left: 2rem;
    .wrappBox {
        margin-left: 17.5%;
        position: absolute;
        top: 0;
    }

    .textDes {
        min-width: 100px;
    }

    .diagram {
        width: 60%;
        height: 40%;
        margin: auto;
        text-align: center;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .box {
        width: 550px;
        height: 220px;
        // background-color: burlywood;
        position: absolute;

        .boxRound {
            position: absolute;
            width: 300px;
            height: 60px;
            border: solid 3px #0066ff;
            background-color: white;
            border-bottom-left-radius: 30px;
            border-top-right-radius: 30px;
            bottom: 0;
            padding: 0.75rem;
            .boxContent {
                height: 100%;
                width: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                .txtBoxRound {
                    font: bold 24px Helvetica;
                    margin: auto;
                }
            }
            
        }

        .line1 {
            width: 50px;
            height: 3px;
            background: linear-gradient(to left, #0066ff 0%, #66ccff 100%);
            position: absolute;
            bottom: 12%;
            left: 54.5%;

        }
        .line2 {
            width: 50px;
            height: 3px;
            background: linear-gradient(to left, #0000cc 0%, #0066ff 100%);
            position: absolute;
            bottom: 19%;
            left: 62.5%;
            transform: rotate(-40deg);
        }
        .line3 {
            width: 100px;
            height: 3px;
            background: linear-gradient(to left, #0066ff 0%, #66ccff 100%);
            position: absolute;
            bottom: 27.3%;
            right: 20.5%;
            transform: rotate(45deg);
        }
        .line4 {
            width: 50px;
            height: 3px;
            background: linear-gradient(to left, #0000cc 0%, #0066ff 100%);
            position: absolute;
            bottom: 11.5%;
            right: 14.3%;

        }
        .line5 {
            width: 50px;
            height: 3px;
            background: linear-gradient(to left, #66ccff 0%, #66eeff 100%);
            position: absolute;
            right: 31.4%;
            top: 44.3%;
            transform: rotate(90deg);
        }

        .line6 {
            width: 85px;
            height: 3px;
            background: linear-gradient(to left, #0066ff 0%, #66ccff 100%);
            position: absolute;
            bottom: 31.3%;
            right: 20.5%;
            transform: rotate(45deg);
        }
        .line7 {
            width: 40px;
            height: 3px;
            background: linear-gradient(to left, #66ccff 0%, #66eeff 100%);
            position: absolute;
            position: absolute;
            right: 30%;
            top: 45%;
            transform: rotate(90deg);
        }
        .line8 {
            width: 40px;
            height: 3px;
            background: linear-gradient(to left, #0000cc 0%, #0066ff 100%);
            position: absolute;
            bottom: 17.9%;
            right: 15.7%;
        }

        .line9 {
            width: 75px;
            height: 3px;
            background: linear-gradient(to left, #0066ff 0%, #66ccff 100%);
            position: absolute;
            bottom: 33.3%;
            right: 20.5%;
            transform: rotate(45deg);
        }
        .line10 {
            width: 30px;
            height: 3px;
            background: linear-gradient(to left, #66ccff 0%, #66eeff 100%);
            position: absolute;
            position: absolute;
            right: 29.3%;
            top: 47%;
            transform: rotate(90deg);
        }

        .line11 {
            width: 30px;
            height: 3px;
            background: linear-gradient(to left, #0000cc 0%, #0066ff 100%);
            position: absolute;
            bottom: 21.3%;
            right: 17.2%;
        }

        .line12 {
            width: 65px;
            height: 3px;
            background: linear-gradient(to left, #0066ff 0%, #66ccff 100%);
            position: absolute;
            bottom: 44.3%;
            right: 16.5%;
            transform: rotate(45deg);
        }
        .line13 {
            width: 25px;
            height: 3px;
            background: linear-gradient(to left, #66ccff 0%, #66eeff 100%);
            position: absolute;
            position: absolute;
            right: 24.2%;
            top: 38.5%;

            transform: rotate(90deg);
        }
        .line14 {
            width: 25px;
            height: 3px;
            background: linear-gradient(to left, #0000cc 0%, #0066ff 100%);
            position: absolute;
            bottom: 34.2%;
            right: 14%;
        }

        .circle1 {
            width: 15px;
            height: 15px;
            border: solid 2px blue;
            border-radius: 10px;
            position: absolute;
            right: 34.5%;
            top: 27%;
        }
        .circle2 {
            width: 15px;
            height: 15px;
            border: solid 2px blue;
            border-radius: 10px;
            position: absolute;
            right: 11.7%;
            bottom: 8.7%;

        }
        .circle3 {
            width: 15px;
            height: 15px;
            border: solid 2px blue;
            border-radius: 10px;
            position: absolute;
            right: 25.1%;
            top: 27%;
        }
        .circle4 {
            width: 15px;
            height: 15px;
            border: solid 2px blue;
            border-radius: 10px;
            position: absolute;
            right: 11.6%;
            bottom: 31.7%;

        }

        .boxDes {
            width: 380px;
            height: 150px;
            position: absolute;
            bottom: -70%;
            display: flex;
            flex-direction: row;
            left: -20px;
            justify-content: center;
        }

        .boxDes p {
            text-align: center !important;
        }

        .boxDes > div {
            padding: 0 .5rem 0 .5rem;
        }
    }
    .box:nth-child(2) {
        transform: translate(0px, -111px) scaleY(-1);

        .boxDes {
            transform: scaleY(-1);
            bottom: 30%;
        }
        .txtBoxRound {
            transform: scaleY(-1);
        }

        .line3 {
            background: linear-gradient(to left, #0000cc 0%, #0000cc 100%);
        }
        .line4 {
            background: linear-gradient(to left, #0000cc 0%, #0000cc 100%);
        }

        .line5 {
            background: linear-gradient(to left, #0000cc 0%, #0000cc 100%);
        }

    }
    .box:nth-child(3) {
        transform: translate(431px, -111px) scaleY(-1) scaleX(-1);

        .boxRound {
            border: solid 3px #ff9933;
        }
        .boxDes {
            transform: scaleY(-1) scaleX(-1);
            bottom: 30%;
        }
        .txtBoxRound {
            transform: scaleY(-1) scaleX(-1);
        }
        .line1 {

            background: linear-gradient(to right, #ff9933 0%, #ff9933 100%);

        }
        .line2 {

            background: linear-gradient(to right, #ff9933 0%, #ff9933 100%);

        }
        .line3 {

            background: linear-gradient(to left, #ff6600 0%, #ff6600 100%);

        }
        .line4 {

            background: linear-gradient(to right, #ff6600 0%, #ff6600 100%);

        }
        .line5 {

            background: linear-gradient(to right, #ff6600 0%, #ff6600 100%);

        }

        .line6 {

            background: linear-gradient(to left, #ff9933 0%, #ff9933 100%);

        }
        .line7 {

            background: linear-gradient(to right, #ff9933 0%, #ff9933 100%);

        }
        .line8 {

            background: linear-gradient(to right, #ff9933 0%, #ff9933 100%);

        }

        .line9 {

            background: linear-gradient(to left, #ff9933 0%, #ff9933 100%);

        }
        .line10 {

            background: linear-gradient(to right, #ff9933 0%, #ff9933 100%);

        }

        .line11 {

            background: linear-gradient(to right, #ff9933 0%, #ff9933 100%);

        }

        .line12 {

            background: linear-gradient(to left, #ff9933 0%, #ff9933 100%);

        }
        .line13 {

            background: linear-gradient(to right, #ff9933 0%, #ff9933 100%);

        }
        .line14 {

            background: linear-gradient(to right, #ff9933 0%, #ff9933 100%);

        }

        .circle1 {
            border: solid 2px #ff6600;
        }
        .circle2 {
            border: solid 2px #ff6600;
        }
        .circle3 {
            border: solid 2px #ff9933;
        }
        .circle4 {
            border: solid 2px #ff9933;
        }

    }
    .box:nth-child(4) {
        transform: translate(431px, 0px) scaleY(1) scaleX(-1);

        .boxRound {
            border: solid 3px #66ccff;
        }

        .circle1 {
            border: solid 2px #66ccff;
        }
        .circle2 {
            border: solid 2px #66ccff;
        }

        .circle3 {
            border: solid 2px #66ccff;
        }

        .circle4 {
            border: solid 2px #66ccff;
        }

        .boxDes {
            transform: scaleY(1) scaleX(-1);

        }
        .txtBoxRound {
            transform: scaleY(1) scaleX(-1);
        }
        .line1 {

            background: linear-gradient(to right, #66ccff 0%, #66eeff 100%);

        }
        .line2 {

            background: linear-gradient(to right, #66ccff 0%, #66eeff 100%);

        }
        .line3 {

            background: linear-gradient(to left, #66ccff 0%, #66eeff 100%);

        }
        .line4 {

            background: linear-gradient(to right, #66ccff 0%, #66eeff 100%);

        }
        .line5 {

            background: linear-gradient(to right, #66ccff 0%, #66eeff 100%);

        }

        .line6 {

            background: linear-gradient(to left, #66ccff 0%, #66eeff 100%);

        }
        .line7 {

            background: linear-gradient(to right, #66ccff 0%, #66eeff 100%);

        }
        .line8 {

            background: linear-gradient(to right, #66ccff 0%, #66eeff 100%);

        }

        .line9 {

            background: linear-gradient(to left, #66ccff 0%, #66eeff 100%);

        }
        .line10 {

            background: linear-gradient(to right, #66ccff 0%, #66eeff 100%);

        }

        .line11 {

            background: linear-gradient(to right, #66ccff 0%, #66eeff 100%);

        }

        .line12 {

            background: linear-gradient(to left, #66ccff 0%, #66eeff 100%);

        }
        .line13 {

            background: linear-gradient(to right, #66ccff 0%, #66eeff 100%);

        }
        .line14 {

            background: linear-gradient(to right, #66ccff 0%, #66eeff 100%);

        }

    }
    .titleSection {
        width: 150px;
        height: 105px;
        font: bold 26px Helvetica;
        margin: auto;
        
    }

}
