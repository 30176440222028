.bannerTop {
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 65vh;
  position: relative;
}

.imgOverlay {
  opacity: 0.6;
  background: #283164a9 0 0 no-repeat padding-box;
}

.imgTop {
  width: 100vw;
  /* position: fixed; */
  height: 65vh;
  filter: blur(2px);
  object-fit: cover;
}

.contentInsideImage {
  align-self: center;
  position: relative;
  margin: 0 auto;
  color: white;
  top: -60%;
  right: 2%;
  text-align: right;
}

.contentRunningText {
  align-self: center;
  position: relative;
  margin: 0 auto;
  color: white;
  top: -50%;
  left: 0;
  text-align: center;
}

.wraperOveriew {
  border-color: transparent;
  margin: 5rem 12.5rem;
  height: 100%;
  position: relative;
  background-color: white;
}

.p1a {
  margin-bottom: 2rem;
  line-height: 1.8rem;
}

.p_0 {
  font-size: 48px;
  font-weight: bold;
  font-family: Helvetica;
  color: white;
  margin-right: 27%;
  margin-left: auto;
  margin-bottom: 8px;
  line-height: 1.8rem;
}

.p_0_animation {
  display: block;

  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-name: header-anim;
}

@keyframes header-anim {
  0%,
  30% {
    transform: translateX(-5%);
    opacity: 0;
  }
  30%,
  70% {
    transform: translateX(0);
    opacity: 1;
  }
  70%,
  99% {
    opacity: 1;
  }
  99%,
  100% {
    opacity: 0;
  }
}

.p_0_underline {
  display: block;
  height: 2px;
  width: 720px;
  margin-left: auto;
  margin-right: 28%;
  background-color: white;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-iteration-count: alternate;
  animation-name: underline-anim;
}

@keyframes underline-anim {
  0%,
  10% {
    width: 0px;
    transform: translateX(-450px);
  }
  10%,
  20% {
    width: 700px;
    transform: translateX(0);
  }
  /* 20%, 30% { 
    width: 0px;
    transform: translateX(450px);
  } */
  30%,
  100% {
    /* transform: translateX(0); */
    width: 0px;
  }
}

.p_1 {
  margin: auto;
  justify-content: center;
  font-size: 36px;
  font-family: Helvetica;
  font-weight: bold;
}

.p_2 {
  font: normal 16px/1.8rem Helvetica;
  text-align: center;
}

.p_2 p {
  line-height: 1.8;
}

.p_2_a {
  margin: auto;
  color: rgba(68, 67, 67, 0.493);
}

.p_3 {
  position: relative;
  font-size: 45px;
  font-weight: bold;
  text-align: center;
  font-family: 20px/22px Helvetica;
  animation: animate 12s linear infinite;
  margin-top: 3rem;
}

@keyframes animate {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-120%);
  }
}

.rowOverview {
  text-align: center !important;
  padding-left: 20%;
  padding-right: 20%;
}

.cardOverview {
  align-self: center;
  height: 350px;
  padding: 0px;
  border-color: transparent;
}

.cardimageOver {
  height: 420px;
  border-color: transparent;
}

.cardcarouselOver {
  border: none !important;
}

.carouselOverview {
  width: 100%;
  height: auto;
  height: 320px;
}

.carouselitemOverview {
  border: none !important;
}

.cardtitleOver {
  font-size: 40px;
  font-weight: bolder;
  color: black;
  text-align: start;
  padding: 20px 20px 0px 20px;
}

.cardtextOver {
  color: black;
  padding: 20px;
  text-align: start;
  margin-top: none;
}

.cusT {
  max-width: 1540px;
}

.marg {
  margin: auto;
}

@media only screen and (min-width: 2560px) {
  .wraperOveriew {
    height: auto;
  }

  .cusT {
    max-width: 2090px;
  }

  .p_2 {
    margin: 0 12rem;
  }
  .p_0 {
    margin-right: 29%;
    font-size: 64px;
  }
  .p_0_underline {
    margin-right: 27%;
  }
  .p_0_underline {
    display: block;
    height: 4px;
    width: 1020px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: 29%;
    background-color: white;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-iteration-count: alternate;
    animation-name: underline-anim;
  }
  @keyframes underline-anim {
    0%,
    10% {
      width: 0px;
      transform: translateX(-450px);
    }
    10%,
    20% {
      width: 1000px;
      transform: translateX(0);
    }
    /* 20%, 30% { 
      width: 0px;
      transform: translateX(450px);
    } */
    30%,
    100% {
      /* transform: translateX(0); */
      width: 0px;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .cusT {
    max-width: 1200px;
  }
  .wraperOveriew {
    margin: 3.5rem 9.5rem;
  }

  .p_3 {
    font-size: 50px;
  }

  .imgTop {
    max-width: 1400px;

    object-fit: cover;
  }
  .p_2 {
    font: normal 14px/28px Helvetica;
  }

  .p_0 {
    font-size: 36px;
  }

  .p_1 {
    font: bold 28px Helvetica;
  }
  .p1a {
    margin-bottom: 1.5rem;
  }
  .p_0_underline {
    width: 600px;
    margin-right: 28%;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-iteration-count: alternate;
    animation-name: underline-anim;
  }
  @keyframes underline-anim {
    0%,
    10% {
      width: 0px;
      transform: translateX(-450px);
    }
    10%,
    20% {
      width: 550px;
      transform: translateX(0);
    }
    /* 20%, 30% { 
      width: 0px;
      transform: translateX(450px);
    } */
    30%,
    100% {
      /* transform: translateX(0); */
      width: 0px;
    }
  }
}

@media only screen and (max-width: 420px) and (min-width: 361px) {
  .bannerTop {
    width: 100%;
  }
  .contentInsideImage {
    top: 10%;
    padding: 0 0 0 2rem;
    right: 0;
  }

  .contentInsideImage p {
    font: bold 24px Helvetica;
  }

  .wraperOveriew {
    padding: 2rem 0 2rem 2rem;
    height: 100%;
  }

  .p_1 {
    justify-content: flex-start;
  }

  .p_1 > p > span {
    font: bold 18px Helvetica;
  }

  .p_2 {
    margin: 0;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
  }
  .p_3 {
    font: bold 46px Helvetica;
  }
}

@media only screen and (max-width: 360px) {
  .bannerTop {
    width: 100%;
  }
  .contentInsideImage {
    top: 10%;
    padding: 0 0 0 2rem;
    right: 0;
  }

  .contentInsideImage p {
    font: bold 24px Helvetica;
  }

  .wraperOveriew {
    padding: 2rem 0 2rem 2rem;
    height: 100%;
  }

  .p_1 {
    justify-content: flex-start;
  }

  .p_1 > p > span {
    font: bold 18px Helvetica;
  }

  .p_2 {
    margin: 0;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
  }
  .p_3 {
    font: bold 46px Helvetica;
  }
}
