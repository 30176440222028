.customContainer {
  margin: 5rem 12.5rem;
}

.customLink {
  text-decoration: none;
}

.customLink:hover {
  text-decoration: none;
}

.navBack {
  /* position: fixed;
  bottom: 5%;
  left: 4%; */
  position: relative;
  /* margin-left: -250px;
  margin-top: -165px; */
  padding: 2rem 0 5rem 0;
  width: 30%;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.textBack {
  font: normal 16px/32px Helvetica;
  color: black;
  margin: 0;
}

.customMargin {
  margin: auto 1rem auto auto;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.backgroundchart {
  /* background-color: violet */
  width: 100%;
}

.wrappContent {
  margin-top: 8rem;
  width: 100%;
  height: 100vh;
  position: relative;
  /* background-color: palegreen; */
}

.circleFake {
  position: absolute;
  /* margin: 18rem auto auto auto; */
  border: 2px solid #f5f5f5;
  width: 480px;
  height: 483px;
  /* display: flex; */
  align-items: center;
  border-radius: 50%;
  top: 9px;
  left: 515px;
}

.circle {
  margin: 18rem auto auto auto;
  border: 20px dotted #f1f1f1f1;
  width: 500px;
  height: 500px;
  display: flex;
  align-items: center;
  border-radius: 50%;

  /* background-color: turquoise; */
}

.circleInner {
  margin: auto;
  width: 420px;
  height: 420px;
  display: flex;
  text-align: center;
  background-color: #ececec;
  align-items: center;
  border-radius: 50%;
  transition: 0.3s;
  box-shadow: -15px 13px 24px -8px rgba(140, 140, 140, 0.9);
}

.textInnerCircle {
  font: 1000 32px Helvetica;
  margin: auto;
}

.chart {
  position: relative;
}

.boxRound {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  margin: auto auto auto auto;

  /* border: solid; */
  background-color: #eead34;
  width: 400px;
  height: 50px;
  border-radius: 40px;
  justify-content: flex-end;
  padding-right: 5px;
  right: 0;
  bottom: 0;
}

.textInBox {
  font: normal 16px Helvetica;
}

.textInboxRound {
  font: normal 26px Helvetica;
  color: white;
  margin: auto;
}

.Line {
  position: absolute;
  width: 120px;
  height: 5px;
  background-color: #eead34;
  z-index: 2;
  transform: rotate(90deg);
  right: -6%;
  bottom: 40%;
}

.circleLine {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 20px;
  text-align: center;
  align-items: center;
  padding: 5px;
  border: solid 5px #eead34;
}

.circleLineIn {
  margin: auto;
  width: 20px;
  height: 20px;

  border-radius: 15px;
  border: solid 5px #eead34;
  text-align: center;
  align-items: center;
}

.circleLine2 {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 20px;
  text-align: center;
  align-items: center;
  padding: 5px;
  border: solid 5px #eead34;
  position: absolute;
  right: 5px;
  top: 21%;
}

.circleLineIn2 {
  margin: auto;
  width: 20px;
  height: 20px;

  border-radius: 15px;
  border: solid 5px #eead34;
  text-align: center;
  align-items: center;
}

.wrappContentBox {
  position: absolute;
  width: 600px;
  /* background-color: aliceblue; */
  height: 250px;
  left: 10px;
  top: 70%;
  z-index: 1;
}

.wrappContentBox:nth-child(2) {
  transform: translate(-232px, -290px);
}

.wrappContentBox:nth-child(2) .Line {
  transform: translate(83px, 78px) rotate(180deg);
  background-color: #f19924;
}

.wrappContentBox:nth-child(2) .circleLine2 {
  transform: translate(163px, 154px);
  border: solid 5px #ffaa3b;
}

.wrappContentBox:nth-child(2) .circleLineIn2 {
  border: solid 5px #f19924;
}

.wrappContentBox:nth-child(2) .boxRound {
  background-color: #f19924;
}

.wrappContentBox:nth-child(2) .circleLine {
  border: solid 5px #f19924;
}

.wrappContentBox:nth-child(2) .circleLineIn {
  border: solid 5px #f19924;
}

.wrappContentBox:nth-child(3) .boxDescription {
  right: 15%;
}

.wrappContentBox:nth-child(3) {
  transform: translate(-179px, -478px);
}

.wrappContentBox:nth-child(3) .Line {
  transform: translate(83px, 78px) rotate(180deg);
}

.wrappContentBox:nth-child(3) .boxRound {
  background-color: #f18624;
}

.wrappContentBox:nth-child(3) .circleLine2 {
  border: solid 5px #f18624;
  transform: translate(163px, 154px);
}

.wrappContentBox:nth-child(3) .circleLineIn2 {
  border: solid 5px #f18624;
}

.wrappContentBox:nth-child(3) .circleLine {
  border: solid 5px #f18624;
}

.wrappContentBox:nth-child(3) .circleLineIn {
  border: solid 5px #f18624;
}

.wrappContentBox:nth-child(3) .Line {
  background-color: #f18624;
}

.wrappContentBox:nth-child(4) {
  transform: translate(160px, -519px) scalex(-1) rotate(180deg);
}

.wrappContentBox:nth-child(4) .boxRound {
  background-color: #f16624;
}

.wrappContentBox:nth-child(4) .circleLine2 {
  border: solid 5px #f16624;
}

.wrappContentBox:nth-child(4) .circleLineIn2 {
  border: solid 5px #f16624;
}

.wrappContentBox:nth-child(4) .circleLine {
  border: solid 5px #f16624;
}

.wrappContentBox:nth-child(4) .circleLineIn {
  border: solid 5px #f16624;
}

.wrappContentBox:nth-child(4) .Line {
  background-color: #f16624;
}

.wrappContentBox:nth-child(4) p {
  transform: scaley(-1);
}

.wrappContentBox:nth-child(5) {
  transform: translate(893px, -441px) rotate(180deg);
}

.wrappContentBox:nth-child(5) .boxRound {
  background-color: #00599d;
}

.wrappContentBox:nth-child(5) .circleLine2 {
  border: solid 5px #00599d;
}

.wrappContentBox:nth-child(5) .circleLineIn2 {
  border: solid 5px #00599d;
}

.wrappContentBox:nth-child(5) .circleLine {
  border: solid 5px #00599d;
}

.wrappContentBox:nth-child(5) .circleLineIn {
  border: solid 5px #00599d;
}

.wrappContentBox:nth-child(5) .Line {
  background-color: #00599d;
}

.wrappContentBox:nth-child(5) p {
  transform: rotate(180deg);
}

.wrappContentBox:nth-child(6) {
  transform: translate(1120px, -350px) scaleX(-1);
}

.wrappContentBox:nth-child(6) .boxRound {
  background-color: #004e96;
}

.wrappContentBox:nth-child(6) .circleLine2 {
  border: solid 5px #004e96;
}

.wrappContentBox:nth-child(6) .circleLineIn2 {
  border: solid 5px #004e96;
}

.wrappContentBox:nth-child(6) .circleLine {
  border: solid 5px #004e96;
}

.wrappContentBox:nth-child(6) .circleLineIn {
  border: solid 5px #004e96;
}

.wrappContentBox:nth-child(6) p {
  transform: scaleX(-1);
}

.wrappContentBox:nth-child(6) .Line {
  transform: translate(83px, 78px) rotate(180deg);
  background-color: #004e96;
}

.wrappContentBox:nth-child(6) .circleLine2 {
  transform: translate(163px, 154px);
}

.wrappContentBox:nth-child(7) {
  transform: translate(1035px, -144px) scaleX(-1);
}

.wrappContentBox:nth-child(7) .boxRound {
  background-color: #03428e;
}

.wrappContentBox:nth-child(7) .circleLine2 {
  border: solid 5px #03428e;
}

.wrappContentBox:nth-child(7) .circleLineIn2 {
  border: solid 5px #03428e;
}

.wrappContentBox:nth-child(7) .circleLine {
  border: solid 5px #03428e;
}

.wrappContentBox:nth-child(7) .circleLineIn {
  border: solid 5px #03428e;
}

.wrappContentBox:nth-child(7) .Line {
  transform: translate(83px, 78px) rotate(180deg);
  background-color: #03428e;
}

.wrappContentBox:nth-child(7) p {
  transform: scaleX(-1);
}

.wrappContentBox:nth-child(7) .circleLine2 {
  transform: translate(163px, 154px);
}

.wrappContentBox:nth-child(8) {
  transform: translate(729px, 68px) scaleX(-1);
}

.wrappContentBox:nth-child(8) .boxRound {
  background-color: #223771;
}

.wrappContentBox:nth-child(8) .circleLine2 {
  border: solid 5px #223771;
}

.wrappContentBox:nth-child(8) .circleLineIn2 {
  border: solid 5px #223771;
}

.wrappContentBox:nth-child(8) .circleLine {
  border: solid 5px #223771;
}

.wrappContentBox:nth-child(8) .circleLineIn {
  border: solid 5px #223771;
}

.wrappContentBox:nth-child(8) .Line {
  background-color: #223871;
}

.wrappContentBox:nth-child(8) p {
  transform: scaleX(-1);
}

.boxDescription {
  position: absolute;
  z-index: 1;
  background-color: #f1f1f1;
  width: 350px;
  height: 92px;
  height: 130px;
  bottom: 55px;
  right: 10%;
  padding: 1rem;
  text-align: justify;
}

.stake {
  width: auto;
  height: 100vh;
  background-color: white;
  position: relative;
  z-index: 1;
}

.imgBackground {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

.overlayBackground {
  background: #ffffffd0 0 0 no-repeat padding-box;
  opacity: 0.1;
  position: absolute;
  /* border-color: transparent; */
}

.boxBanner {
  height: 65vh;
  width: 100%;
}

.imgCenter {
  height: 100%;
  width: 100%;
}

.boxTitle {
  /* width: 828px; */
  height: 62px;
  align-self: center;
  left: 14%;
  top: 14%;
}

p.title {
  font: 36px/55px Helvetica;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 2rem;
}

p.desc {
  font: 16px/32px Helvetica;
  color: black;
}

.boxText {
  width: 1306px;
  height: 53px;
  align-self: center;
  left: 14%;
  top: 20%;
}

p.text {
  font: 16px/32px Helvetica;
}

.flex_0 {
  flex-direction: row;
  display: flex;
  margin: auto;
  position: relative;
  padding-top: 3rem;
}

.box_0 {
  width: 285px;
  height: 527px;
}

.box_1 {
  width: 504px;
  height: 221px;
  padding-left: 5rem;
}

.box_2 {
  width: 617px;
  height: 501px;
  padding-left: 5rem;
}

p.contentLink {
  font: 16px/24px Helvetica;
  margin: auto 0;
  font-weight: bold;
  color: black;
  opacity: 0.3;
  cursor: pointer;
}

.activeCircle {
  opacity: 1 !important;
}

.outerCircle {
  padding: 5px;
  /* background-color: rgb(45, 57, 69, 0.5); */
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  bottom: -1rem;
  left: 0rem;
  opacity: 0;
  margin: auto 0.5rem auto 0;
}

.innerCircle {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  opacity: 0;
  border-radius: 100%;
}

p.mainRead {
  font: 20px Helvetica;
  font-weight: bold;
  color: black;
}

p.secondRead {
  font: 16px Helvetica;
  color: black;
  line-height: 25px;
  letter-spacing: 1px;
  text-align: justify;
}

.boxStake {
  width: 253px;
  height: 40px;
  opacity: 1;
}

p.contentLink:hover {
  opacity: 1;
  color: #223771;
}

.boxStake:hover .outerCircle,
.innerCircle {
  opacity: 1;
}

p.textChange {
  font: 16px/24px Helvetica;
  margin: auto 0;
  font-weight: bold;
  color: #223771;
  /* color: red; */
  opacity: 1;
}

.boxC {
  max-width: 1540px;
  height: 100%;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.textTitle {
  font: bold 46px Helvetica;
}

.boxImage {
  /* height: 500px;
  width: 500px; */
  margin: auto;
}

@media only screen and (max-width: 1400px) {
  .stake {
    height: 768px;
    background-color: white;
  }
  .customContainer {
    margin: 3.5rem 9.5rem;
    width: 1062px;
  }

  .boxText {
    width: auto;
    left: 7%;
    top: 20%;
  }

  .boxTitle {
    left: 7%;
    top: 12%;
  }

  .imgBackground {
    width: 1366px;
    height: 768px;
  }

  p.title {
    font: bold 28px Helvetica;
  }

  .box_1 {
    padding-left: 0;
    width: 425px;
  }

  p.mainRead {
    font: bold 18px Helvetica;
  }

  p.contentLink {
    font: bold 14px Helvetica;
  }

  p.secondRead {
    font: 14px Helvetica;
  }

  .box_0 {
    width: 256px;
  }

  .box_2 {
    width: 464px;
    padding-left: 2rem;
  }

  p.text {
    font: 14px Helvetica;
  }

  .boxC {
    max-width: 1120px;
  }

  p.desc {
    font: normal 14px Helvetica;
  }
  .te {
    font: normal 14px Helvetica;
  }
}
