/* GENERAL */
:root {
  --title-hd: bold 28px Helvetica;
  --subtitle-hd: bold 18px Helvetica;
  --p-hd: normal 14px Helvetica;
  --container-hd: 1062px !important;
  --padding-section-hd: 3.5rem 0;

  --title-fhd: bold 36px Helvetica;
  --subtitle-fhd: bold 26px Helvetica;
  --p-fhd: normal 16px Helvetica;
  --container-fhd: 1540px !important;
  --padding-section-fhd: 5rem 0;
}

/* SPECIFIC */
.aCard:nth-child(3n + 2) {
  /* margin-top: 60px; */
  /* margin-bottom: 30px; */
  padding-bottom: 30px;
  /* margin: 0 auto; */
}

.aCard:nth-last-child(0) {
  /* margin-top: 60px; */
  /* margin-bottom: 30px; */
  padding-bottom: 30px;
  background: tomato;
  /* opacity: .1; */
  margin: 0 0 0 25%;
}

.dummyCard {
  opacity: 0;
  cursor: default !important;
  z-index: -1;
}

.aCard:last-child {
  margin-top: 0;
}

.aCard {
  margin-bottom: -30px;
}

.aCard:hover {
  cursor: pointer;
}

.card {
  position: relative;
}

.overlayCardImage:hover {
  opacity: 0;
}

.figure {
  /* width: 424px;
    height: 472px; */
  width: 422px;
  height: 354px;
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
}

.cardImageFront {
  background-color: #000000;
  width: 472px;
  /* height: 454px; */
  object-fit: cover;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.cardImageFront:hover {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.cardTitle {
  text-align: left;
  font: normal normal bold 16px/21px Helvetica;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-top: 23px;
  margin-bottom: 13px;
}

.inActive {
  opacity: 0.5;
}

.cardSubTitle {
  text-align: left;
  font: normal normal medium 14px/19px Helvetica;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

.profileImg {
  height: 100vh;
  width: 720px;
  object-fit: cover;
}

.modalImg {
  display: inline-block;
  width: 720px;
}

.modalDetail {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: calc(97vw - 720px - 10%);
  overflow-y: scroll;
}

.boxContent {
  padding: 0 0 2rem 0;
}

.boxContent:before {
  content: "";
  position: absolute;
  height: 10px;
  width: 100%;
  bottom: calc(100% - 354px);
  left: 0;
  background-color: rgb(34, 55, 113);
}

.backgroundInActive:before {
  content: "";
  position: absolute;
  height: 10px;
  width: 100%;
  bottom: calc(100% - 354px);
  left: 0;
  background-color: rgb(34, 55, 113);
}

.backgroundActive:before {
  content: "";
  position: absolute;
  height: 10px;
  width: 100%;
  bottom: calc(100% - 354px);
  left: 0;
  background-color: rgb(241, 102, 36);
}

.backgroundActive {
  animation: transitionColor 1s;
  background-color: #d4d4d4;
}

.backgroundActive h4,
.backgroundActive p {
  padding-left: 2rem;
}

@keyframes transitionColor {
  0% {
    background-color: #fff;
  }

  100% {
    background-color: #d4d4d4;
  }
}

@-webkit-keyframes transitionColor {
  0% {
    background-color: #fff;
  }

  100% {
    background-color: #d4d4d4;
  }
}

.modalContainer {
  margin-left: 104px;
  margin-right: 68px;
  color: white;
}

.pageNum {
  top: 37px;
  position: relative;
  font: normal normal 300 36px/43px Helvetica;
}

.hrPageNum {
  display: inline-block;
  border-color: white;
  width: 40px;
  margin-bottom: 10px;
}

.closeBtn {
  position: absolute;
  display: flex;
  right: 0px;
  font-size: 26px;
  cursor: pointer;
}

.navigatorBtnTop {
  display: flex;
  /* align-items: stretch; */
  position: absolute;
  right: 44px;
  font-size: 26px;
  cursor: pointer;
  vertical-align: middle;
}

.navigatorBtnBottom {
  display: flex;
  align-items: stretch;
  position: absolute;
  margin-top: 32px;
  right: 44px;
  font-size: 26px;
  cursor: pointer;
}

.navigatorText {
  font-size: 14px;
  margin-right: 16px;
  display: flex;
}

.separator {
  position: relative;
  height: 1px;
  width: 100%;
  background-color: white;
  margin-top: 70px;
}

.modalContent {
  position: relative;
  margin-top: 20px;
  max-height: 946px;
}

.contentProfileName {
  text-align: left;
  font: normal normal medium 55px/64px Helvetica;
}

.contentProfilePosition {
  margin-top: 27px;
}

.contentTitleQualification {
  font: normal normal bold 20px Helvetica;
  margin-top: 100px;
}

.contentQualification {
  font: normal normal normal 14px Helvetica;
  margin-top: 12px;
}

.contentTitlePosition {
  font: normal normal bold 20px Helvetica;
  margin: 100px 0 0 0;
}

.contentPosition {
  font: normal normal normal 14px/24px Helvetica;
  margin: 12px 0 0 0;
}

.contentDescription {
  font: normal normal normal 14px/24px Helvetica;
  margin: 100px 0 0 0;
}

.separatorBottom {
  position: relative;
  height: 1px;
  width: 100%;
  background-color: white;
  margin-top: 100px;
}

.footerContent {
  position: relative;
  padding-top: 40px;
  padding-bottom: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.footerRightArrow {
  font-size: 26px;
  margin-left: 60px;
}

.footerNextProfile {
  margin-left: 52px;
  font-size: 14px;
}

.footerNextProfile p {
  margin: 0;
}

.footerNextBtn {
  font-size: 18px;
  font-weight: 300;
}

/* .aCard:nth-child(3n+2) {
    margin-top: 60px;
} */

@media only screen and (max-width: 1200px) {
  .aCard:nth-child(3n + 2) {
    margin-top: 0;
  }
}

@media only screen and (max-width: 1400px) {
  .figure {
    width: 332px;
    height: 279px;
  }

  .cardImageFront {
    width: 371px;
    /* height: 357px; */
  }

  .boxContent {
    padding: 0 0 1.5rem 0;
  }

  .boxContent:before {
    bottom: calc(100% - 279px);
  }

  .profileImg {
    width: 480px;
  }

  .modalImg {
    width: 480px;
  }

  .modalDetail {
    width: calc(97vw - 480px - 10%);
  }

  .cardTitle {
    font: bold 14px Helvetica;
    padding-top: 1rem;
  }

  .cardSubTitle {
    font: normal 12px Helvetica;
  }
}
