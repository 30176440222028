.customSearch {
    background-color: #fff;
    padding: 5px 5px 5px 25px!important;
    width: 20rem;
    font-size: 15px;
    border-radius: 20px;
    margin-bottom: 3rem;
}

.customSearch input[type=text] {
    font-weight: bold;
    min-height: 30px;
    width: 100%;
    border: none;
    padding: 0px 0px 0px 0px;
    outline: none;
    border-radius: 20px;
}

.faCustom {
    position: absolute;
    top: 0.8rem;
    right: 1rem;
    color: darkgrey;
}

@media only screen and (max-width: 1376px) {
    .customSearch {
        margin-bottom: 2rem;
    }
    .customSearch input[type=text] {
        font-weight: bold;
        min-height: 20px;
        width: 100%;
        border: none;
        padding: 0px 0px 0px 0px;
        outline: none;
        border-radius: 10px;
    }
    .itemList {
        max-width: 30%;
    }
}

@media only screen and (max-width: 420px) {
    .customSearch {
        width: 15rem;
    }
    .customSearch input[type=text] {
        min-height: 10px;
    }
    .itemList {
        max-width: 100%;
    }
    .customSearch:focus {
        position: sticky;
        top: 10%;
    }
}

@media only screen and (max-width: 360px) {
    .customSearch {
        width: 15rem;
    }
    .customSearch input[type=text] {
        min-height: 10px;
    }
    .itemList {
        max-width: 100%;
    }
}   