.v-line,
.h-line {
    position: absolute;
    background-color: #223971;
}

.chart-content-animate:hover {
    font-weight: bold;
}

.title-chart {
    height: 100%;
    width: 100%;
    text-transform: uppercase;
}

.chart-icon-1 img, .chart-icon-2 img, .chart-icon-3 img, .chart-icon-4 img, .chart-icon-5 img, .chart-icon-6 img {
    height: 50px;
    width: 50px;
    object-fit: contain;
}

.chart-icon-0 img{
    height: 180px;
    width: 180px;
    object-fit: contain;
}

.chart-icon-0 {
    width: 15%;
    height: 1px;
    top: -20px;
    left: 45.8%;
    display: block;
    opacity: 1;
    position: absolute;
}

.chart-icon-1 {
    width: 15%;
    height: 1px;
    top: 145px;
    left: 38%;
    display: block;
    opacity: 1;
    position: absolute;
    /* position: absolute;
    width: 15%;
    height: 1px;
    top: 200px;
    left: 16.5%;
    display: block;
    opacity: 1;
    visibility: hidden; */
}
.chart-icon-2 {
    width: 15%;
    height: 1px;
    top: 400px;
    left: 38%;
    display: block;
    position: absolute;
}
.chart-icon-3 {
    width: 15%;
    height: 1px;
    top: 670px;
    left: 38%;
    display: block;
    position: absolute;
}
.chart-icon-4 {
    width: 15%;
    height: 1px;
    top: 285px;
    right: 26%;
    display: block;
    position: absolute;
}
.chart-icon-5 {
    width: 15%;
    height: 1px;
    top: 525px;
    right: 26%;
    display: block;
    position: absolute;
}
.chart-icon-6 {
    width: 15%;
    height: 1px;
    top: 620px;
    right:26%;
    display: block;
    position: absolute;
}

.title-chart:hover {
    -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes text-pop-up-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: none;
    }

    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
}

@keyframes text-pop-up-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: none;
    }

    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
}

/* Left */
.left-1 {
    width: 1px;
    height: 600px;
    top: 0;
    right: 5%;
    display: block;
    opacity: 1;
    animation: left-1 2s;
}

@keyframes left-1 {
    0% {
        height: 0;
    }

    100% {
        height: 550px;
    }
}

.left-1:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: 0;
    left: -2px;
}

/* start kiri item 1 */

.left-2 {
    width: 8%;
    height: 1px;
    top: 80px;
    right: 5%;
    display: block;
    opacity: 1;
    animation: left-2 2s;
}

@keyframes left-2 {
    0% {width: 0;}
    100% {width: 8%;}
}

.left-2:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: -0;
}

.left-x1-title {
    width: 15%;
    height: 1px;
    top: 60px;
    right: 15%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: center;
}

.left-21 {
    width: 22%;
    height: 1px;
    top: 80px;
    right: 32%;
    display: block;
    opacity: 1;
    animation: left-21 2s;
}

@keyframes left-21 {
    0% {width: 0;}
    100% {width: 22%;}
}

.left-21:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    right: 0;
}


.left-22 {
    width: 1px;
    height: 40px;
    top: 110px;
    right: 22%;
    display: block;
    opacity: 1;
    animation: left-22 2s;
}

@keyframes left-22 {
    0% {height: 0;}
    100% {height: 30px;}
}

.left-22:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: -2px;
}


.left-x2-title {
    width: 15%;
    height: 1px;
    top: 160px;
    right: 15%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: center;
}

.left-23 {
    width: 8%;
    height: 1px;
    top: 170px;
    right: 32%;
    display: block;
    opacity: 1;
    animation: left-23 2s;
}

@keyframes left-23 {
    0% {width: 0;}
    100% {width: 8%;}
}

.left-23:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    right: 0;
}


.left-x3-content {
    width: 15%;
    height: 1px;
    top: 160px;
    right: 41%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: right;
}

.left-24 {
    width: 1px;
    height: 111px;
    top: 170px;
    right: 36%;
    display: block;
    opacity: 1;
    animation: left-24 2s;
}

@keyframes left-24 {
    0% {height: 0;}
    100% {height: 111px;}
}

.left-25 {
    width: 4%;
    height: 1px;
    top: 200px;
    right: 36%;
    display: block;
    opacity: 1;
    animation: left-25 2s;
}

@keyframes left-25 {
    0% {width: 0;}
    100% {width: 4%;}
}

.left-x4-content {
    width: 15%;
    height: 1px;
    top: 190px;
    right: 41%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: right;
}

.left-26 {
    width: 4%;
    height: 1px;
    top: 240px;
    right: 36%;
    display: block;
    opacity: 1;
    animation: left-26 2s;
}

@keyframes left-26 {
    0% {width: 0;}
    100% {width: 4%;}
}

.left-x5-content {
    width: 15%;
    height: 1px;
    top: 230px;
    right: 41%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: right;
}

.left-27 {
    width: 4%;
    height: 1px;
    top: 280px;
    right: 36%;
    display: block;
    opacity: 1;
    animation: left-27 2s;
}

@keyframes left-27 {
    0% {width: 0;}
    100% {width: 4%;}
}

.left-x6-content {
    width: 15%;
    height: 1px;
    top: 270px;
    right: 41%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: right;
}

/* end kiri item 1 */

/* start kiri item 2 */

.left-x3-title {
    width: 20%;
    height: 1px;
    top: 60px;
    right: 56%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: center;
}

.left-28 {
    width: 1px;
    height: 150px;
    top: 110px;
    right: 65%;
    display: block;
    opacity: 1;
    animation: left-28 2s;
}

@keyframes left-28 {
    0% {height: 0;}
    100% {height: 150px;}
}

.left-28:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: -2px;
}


.left-29 {
    width: 4%;
    height: 1px;
    top: 140px;
    right: 65%;
    display: block;
    opacity: 1;
    animation: left-29 2s;
}

@keyframes left-29 {
    0% {width: 0;}
    100% {width: 4%;}
}

.left-x7-content {
    width: 15%;
    height: 1px;
    top: 122px;
    right: 70%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: right;
}

.left-30 {
    width: 4%;
    height: 1px;
    top: 180px;
    right: 65%;
    display: block;
    opacity: 1;
    animation: left-30 2s;
}

@keyframes left-30 {
    0% {width: 0;}
    100% {width: 4%;}
}

.left-x8-content {
    width: 15%;
    height: 1px;
    top: 172px;
    right: 70%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: right;
}

.left-31 {
    width: 4%;
    height: 1px;
    top: 220px;
    right: 65%;
    display: block;
    opacity: 1;
    animation: left-31 2s;
}

@keyframes left-31 {
    0% {width: 0;}
    100% {width: 4%;}
}


.left-x9-content {
    width: 15%;
    height: 1px;
    top: 212px;
    right: 70%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: right;
}

.left-32 {
    width: 4%;
    height: 1px;
    top: 260px;
    right: 65%;
    display: block;
    opacity: 1;
    animation: left-32 2s;
}

@keyframes left-32 {
    0% {width: 0;}
    100% {width: 4%;}
}

.left-x10-content {
    width: 15%;
    height: 1px;
    top: 252px;
    right: 70%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: right;
}


/* end kiri item 2 */

/* start kiri item 3 */

.left-3 {
    width: 8%;
    height: 1px;
    top: 330px;
    right: 5%;
    display: block;
    opacity: 1;
    animation: left-3 2s;
}

@keyframes left-3 {
    0% {width: 0;}
    100% {width: 8%;}
}

.left-3:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: -0;
}

.left-x4-title {
    width: 40%;
    height: 1px;
    top: 320px;
    right: 15%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: right;
}

.left-33 {
    width: 1px;
    height: 30px;
    top: 350px;
    right: 22%;
    display: block;
    opacity: 1;
    animation: left-33 2s;
}

@keyframes left-33 {
    0% {height: 0;}
    100% {height: 30px;}
}

.left-33:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: -2px;
}


.left-34 {
    width: 60%;
    height: 1px;
    top: 380px;
    right: 15%;
    display: block;
    opacity: 1;
    animation: left-34 2s;
}

@keyframes left-34 {
    0% {width: 0;}
    100% {width: 60%;}
}


.left-35 {
    width: 1px;
    height: 30px;
    top: 380px;
    right: 15%;
    display: block;
    opacity: 1;
    animation: left-35 2s;
}

@keyframes left-35 {
    0% {height: 0;}
    100% {height: 30px;}
}

.left-x11-content {
    width: 10%;
    height: 1px;
    top: 422px;
    right: 10%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

.left-36 {
    width: 1px;
    height: 30px;
    top: 380px;
    right: 25%;
    display: block;
    opacity: 1;
    animation: left-33 2s;
}

.left-x12-content {
    width: 10%;
    height: 1px;
    top: 422px;
    right: 20%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

.left-37 {
    width: 1px;
    height: 30px;
    top: 380px;
    right: 35%;
    display: block;
    opacity: 1;
    animation: left-33 2s;
}

.left-x13-content {
    width: 10%;
    height: 1px;
    top: 422px;
    right: 30%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

.left-38 {
    width: 1px;
    height: 30px;
    top: 380px;
    right: 45%;
    display: block;
    opacity: 1;
    animation: left-33 2s;
}

.left-x14-content {
    width: 10%;
    height: 1px;
    top: 422px;
    right: 40%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

.left-39 {
    width: 1px;
    height: 30px;
    top: 380px;
    right: 55%;
    display: block;
    opacity: 1;
    animation: left-33 2s;
}

.left-x15-content {
    width: 10%;
    height: 1px;
    top: 422px;
    right: 50%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

.left-40 {
    width: 1px;
    height: 30px;
    top: 380px;
    right: 65%;
    display: block;
    opacity: 1;
    animation: left-33 2s;
}

.left-x16-content {
    width: 10%;
    height: 1px;
    top: 422px;
    right: 60%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

.left-41 {
    width: 1px;
    height: 30px;
    top: 380px;
    right: 75%;
    display: block;
    opacity: 1;
    animation: left-33 2s;
}

.left-x17-content {
    width: 10%;
    height: 1px;
    top: 422px;
    right: 70%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

/* end kiri item 3 */

/* start kiri item 4 */
.left-42 {
    width: 8%;
    height: 1px;
    top: 600px;
    right: 5%;
    display: block;
    opacity: 1;
    animation: left-42 2s;
}

@keyframes left-42 {
    0% {width: 0;}
    100% {width: 13%;}
}

.left-42:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: -2px;
}


.left-x5-title {
    width: 40%;
    height: 1px;
    top: 590px;
    right: 18%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: right;
}

.left-43 {
    width: 8%;
    height: 1px;
    top: 600px;
    right: 32%;
    display: block;
    opacity: 1;
    animation: left-43 2s;
}

@keyframes left-43 {
    0% {width: 0;}
    100% {width: 8%;}
}

.left-43:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    right: -0;
}

.left-x6-title {
    width: 40%;
    height: 1px;
    top: 590px;
    right: 42%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: right;
}

.left-44 {
    width: 1px;
    height: 40px;
    top: 620px;
    right: 22%;
    display: block;
    opacity: 1;
    animation: left-44 2s;
}

@keyframes left-44 {
    0% {height: 0;}
    100% {height: 50px;}
}

.left-44:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: -2px;
}

.left-x7-title {
    width: 15%;
    height: 1px;
    top: 675px;
    right: 18%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: right;
}

.left-45 {
    width: 1px;
    height: 40px;
    top: 710px;
    right: 22%;
    display: block;
    opacity: 1;
    animation: left-45 2s;
}

@keyframes left-45 {
    0% {height: 0;}
    100% {height: 20px;}
}

.left-45:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: -2px;
}

.left-46 {
    width: 18%;
    height: 1px;
    top: 750px;
    right: 14%;
    display: block;
    opacity: 1;
    animation: left-46 2s;
}

@keyframes left-46 {
    0% {width: 0;}
    100% {width: 20%;}
}


.left-47 {
    width: 1px;
    height: 20px;
    top: 750px;
    right: 14%;
    display: block;
    opacity: 1;
    animation: left-47 2s;
}

@keyframes left-47 {
    0% {height: 0;}
    100% {height: 20px;}
}

.left-x18-content {
    width: 15%;
    height: 1px;
    top: 780px;
    right: 7%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

.left-48 {
    width: 1px;
    height: 20px;
    top: 750px;
    right: 32%;
    display: block;
    opacity: 1;
    animation: left-47 2s;
}

.left-x19-content {
    width: 15%;
    height: 1px;
    top: 780px;
    right: 25.5%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: center;
}

/* end kiri item 4 */



/* Right */

.r-1 {
    width: 1px;
    height: 549px;
    top: 0;
    left: 5%;
    display: block;
    opacity: 1;
    animation: r-1 2s;
}

@keyframes r-1 {
    0% {height: 0;}
    100% {height: 520px;}
}

.r-1:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: 0;
    left: -2px;
}

/* start kanan item 1 */

.r-2 {
    width: 8%;
    height: 1px;
    top: 215px;
    left: 5%;
    display: block;
    opacity: 1;
    animation: r-2 2s;
}

@keyframes r-2 {
    0% {width: 0;}
    100% {width: 8%;}
}

.r-2:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    right: 0px;
}

.r-y1-title {
    width: 20%;
    height: 1px;
    top: 205px;
    left: 15%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: left;
}

.r-3 {
    width: 8%;
    height: 1px;
    top: 215px;
    left: 28%;
    display: block;
    opacity: 1;
    animation: r-2 2s;
}

.r-3:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: 0px;
}

.r-4 {
    width: 1px;
    height: 380px;
    top: 20px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-4 2s;
}

@keyframes r-4 {
    0% {height: 0;}
    100% {height: 380px;}
}

.r-5 {
    width: 8%;
    height: 1px;
    top: 20px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

@keyframes r-5 {
    0% {width: 0;}
    100% {width: 8%;}
}

.r-y1-content {
    width: 40%;
    height: 1px;
    top: 10px;
    left: 47%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-6 {
    width: 23%;
    height: 1px;
    top: 55px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-6 2s;
}

@keyframes r-6 {
    0% {width: 0;}
    100% {width: 23%;}
}

.r-y2-content {
    width: 40%;
    height: 1px;
    top: 40px;
    left: 62%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-7 {
    width: 8%;
    height: 1px;
    top: 90px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-y3-content {
    width: 40%;
    height: 1px;
    top: 80px;
    left: 47%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-8 {
    width: 23%;
    height: 1px;
    top: 125px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-6 2s;
}

.r-y4-content {
    width: 40%;
    height: 1px;
    top: 110px;
    left: 62%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-9 {
    width: 8%;
    height: 1px;
    top: 160px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-y5-content {
    width: 40%;
    height: 1px;
    top: 150px;
    left: 47%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-10 {
    width: 23%;
    height: 1px;
    top: 195px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-6 2s;
}

.r-y6-content {
    width: 40%;
    height: 1px;
    top: 185px;
    left: 62%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-11 {
    width: 8%;
    height: 1px;
    top: 230px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-y7-content {
    width: 40%;
    height: 1px;
    top: 220px;
    left: 47%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-12 {
    width: 23%;
    height: 1px;
    top: 265px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-6 2s;
}

.r-y8-content {
    width: 40%;
    height: 1px;
    top: 250px;
    left: 62%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-13 {
    width: 8%;
    height: 1px;
    top: 300px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-y9-content {
    width: 40%;
    height: 1px;
    top: 290px;
    left: 47%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-14 {
    width: 23%;
    height: 1px;
    top: 335px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-6 2s;
}

.r-y10-content {
    width: 40%;
    height: 1px;
    top: 320px;
    left: 62%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-15 {
    width: 8%;
    height: 1px;
    top: 365px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-y11-content {
    width: 40%;
    height: 1px;
    top: 355px;
    left: 47%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-16 {
    width: 23%;
    height: 1px;
    top: 400px;
    left: 36%;
    display: block;
    opacity: 1;
    animation: r-6 2s;
}

.r-y12-content {
    width: 40%;
    height: 1px;
    top: 385px;
    left: 62%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

/* end kanan item 1 */

/* start kanan item 2 */

.r-17 {
    width: 8%;
    height: 1px;
    top: 455px;
    left: 5%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-17:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    right: 0px;
}

.r-y2-title {
    width: 30%;
    height: 1px;
    top: 445px;
    left: 15%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: left;
}

/* end kanan item 2 */

/* start kanan item 3 */


.r-18 {
    width: 8%;
    height: 1px;
    top: 548px;
    left: 5%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-18:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    right: 0px;
}

.r-y3-title {
    width: 30%;
    height: 1px;
    top: 537px;
    left: 15%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: left;
}

.r-19 {
    width: 8%;
    height: 1px;
    top: 549px;
    left: 28%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-19:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: 0px;
}

.r-y4-title {
    width: 20%;
    height: 1px;
    top: 537px;
    left: 38%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: left;
}

.r-20 {
    width: 1px;
    height: 40px;
    top: 570px;
    left: 21%;
    display: block;
    opacity: 1;
    animation: r-20 2s;
}

@keyframes r-20 {
    0% {height: 0;}
    100% {height: 60px;}
}

.r-20:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    right: -2px;
}

.r-y5-title {
    width: 15%;
    height: 1px;
    top: 630px;
    left: 13%;
    display: block;
    opacity: 1;
    position: absolute;
    font: bold 18px Helvetica;
    text-align: center;
}

/* .r-y12-content {
    width: 20%;
    height: 1px;
    top: 385px;
    left: 62%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
} */

.r-21 {
    width: 8%;
    height: 1px;
    top: 640px;
    left: 28%;
    display: block;
    opacity: 1;
    animation: r-5 2s;
}

.r-21:before {
    content: "";
    width: 5px;
    height: 5px;
    background: #223971;
    position: absolute;
    border-radius: 50px;
    top: -2px;
    left: 0px;
}

.r-22 {
    width: 1px;
    height: 120px;
    top: 640px;
    left: 32%;
    display: block;
    opacity: 1;
    animation: r-22 2s;
}

@keyframes r-22 {
    0% {height: 0;}
    100% {height: 90px;}
}

.r-y13-content {
    width: 25%;
    height: 1px;
    top: 630px;
    left: 38%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-23 {
    width: 4%;
    height: 1px;
    top: 680px;
    left: 32%;
    display: block;
    opacity: 1;
    animation: r-23 2s;
}

@keyframes r-23 {
    0% {width: 0;}
    100% {width: 4%;}
}

.r-y14-content {
    width: 25%;
    height: 1px;
    top: 670px;
    left: 38%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-24 {
    width: 4%;
    height: 1px;
    top: 720px;
    left: 32%;
    display: block;
    opacity: 1;
    animation: r-23 2s;
}

.r-y15-content {
    width: 30%;
    height: 1px;
    top: 710px;
    left: 38%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

.r-25 {
    width: 4%;
    height: 1px;
    top: 760px;
    left: 32%;
    display: block;
    opacity: 1;
    animation: r-23 2s;
}

.r-y16-content {
    width: 30%;
    height: 1px;
    top: 750px;
    left: 38%;
    display: block;
    opacity: 1;
    position: absolute;
    font: 16px Helvetica;
    text-align: left;
}

/* end kanan item 3 */

@media only screen and (max-width: 1376px) {
    .title-chart {
        font: bold 16px Helvetica;
    }
    .--subtitle-chart {
        font: bold 16px Helvetica;
    }
    .--chart-content{
        font: 14px Helvetica;
    }
    .chart-icon-1 img, .chart-icon-2 img, .chart-icon-3 img, .chart-icon-4 img, .chart-icon-5 img, .chart-icon-6 img {
        height: 40px;
        width: 40px;
        object-fit: contain;
    }
    
    .chart-icon-0 img{
        height: 160px;
        width: 160px;
        object-fit: contain;
    }
    
    .chart-icon-0 {
        width: 15%;
        height: 1px;
        top: -140px;
        left: 44.1%;
        display: block;
        opacity: 1;
        position: absolute;
    }
    
    .chart-icon-1 {
        width: 15%;
        height: 1px;
        top: -5px;
        left: 38%;
        display: block;
        opacity: 1;
        position: absolute;
    }
    .chart-icon-2 {
        width: 15%;
        height: 1px;
        top: 270px;
        left: 38%;
        display: block;
        position: absolute;
    }
    .chart-icon-3 {
        width: 15%;
        height: 1px;
        top: 540px;
        left: 38%;
        display: block;
        position: absolute;
    }
    .chart-icon-4 {
        width: 15%;
        height: 1px;
        top: 155px;
        right: 26%;
        display: block;
        position: absolute;
    }
    .chart-icon-5 {
        width: 15%;
        height: 1px;
        top: 395px;
        right: 26%;
        display: block;
        position: absolute;
    }
    .chart-icon-6 {
        width: 15%;
        height: 1px;
        top: 490px;
        right:26%;
        display: block;
        position: absolute;
    }

    .left-x1-title {
        top: 45px;
    }

    .left-x3-title {
        top: 45px;
    }

    .r-19 {
        left: 32.5%;
    }

    .r-y4-title {
        left: 42.5%;
    }
}