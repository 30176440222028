.contentSection {
  height: 100%;
  width: 100%;
  position: relative;
}

.title {
  font: bold 46px/55px Helvetica;
  text-transform: uppercase;
  color: #000000;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-name: header-anim;
}

.contentBanner {
  margin: auto;
}

.marg0 {
  margin: 0 !important;
}

@keyframes header-anim {
  0%,
  30% {
    transform: translateX(-5%);
    opacity: 0;
  }

  30%,
  70% {
    transform: translateX(0);
    opacity: 1;
  }

  70%,
  99% {
    opacity: 1;
  }

  99%,
  100% {
    opacity: 0;
  }
}

.contentImage {
  width: 100%;
  height: 65vh;
  position: absolute;
  top: 0;
  left: 0;
  /* margin: auto; */
}

.contentTitle {
  width: 100%;
  height: 65vh;
  position: relative;
  display: flex;
  justify-content: center;
}

.img {
  width: 100%;
  height: 65vh;
  object-fit: cover;
}

.boxC {
  max-width: 1540px;
  padding: 5rem 0 0 0;
}

.p_0_underline {
  display: block;
  height: 2px;
  width: 500px;
  margin-left: auto;
  margin-right: 12%;
  background-color: black;

  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-iteration-count: alternate;
  animation-name: underline-anim;
}

@keyframes underline-anim {
  0%,
  10% {
    width: 0px;
    transform: translateX(-250px);
  }

  10%,
  20% {
    width: 400px;
    transform: translateX(0);
  }

  /* 20%, 30% { 
    width: 0px;
    transform: translateX(450px);
  } */
  30%,
  100% {
    /* transform: translateX(0); */
    width: 0px;
  }
}
@media only screen and (min-width: 2560px) {
  .boxC {
    max-width: 2090px !important;
  }
}

@media only screen and (min-width: 1920px) {
  .boxC {
    padding: 0;
  }
}
@media only screen and (max-width: 1400px) {
  .contentImage {
    width: 100%;
  }

  .img {
    width: 100%;
  }

  .boxC {
    max-width: 1120px;
  }
}
