.icon {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 20px;
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 50%;
  width: 56%;
  height: 3px;
  border-radius: 10px;
  background-color: #f16b26;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  animation: arrow 700ms linear infinite;
}

.arrow::after,
.arrow::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  right: -5px;
  border-radius: 10px;
  background-color: #f16b26;
}

.arrow::after {
  top: -5px;
  transform: rotate(45deg);
}

.arrow::before {
  top: 5px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  transform: rotate(-45deg);
}

.content-item {
  color: white;
  font-family: "Helvetica";
}

.content-item:hover {
  color: #f16b26;
}

.content-item:hover ~ .btn-require {
  background-color: #223771 !important;
}

.content-item:hover ~ div > button:before {
  transform: rotate(90deg);
  background: white;
}

.content-item:hover ~ div > button:after {
  transform: rotate(180deg);
  background: white;
}

.content-on-hover {
  position: absolute;
  top: calc(30px + 2rem);
  height: 100%;
  left: 28vw;
  width: 60vw;
}

.custom-link {
  letter-spacing: unset !important;
}

p.content-link-menu {
  font: 26px/32px Helvetica;
  color: white;
}

@media only screen and (max-width: 1400px) {
  .arrow {
    position: absolute;
    top: 50%;
    width: 69%;
    height: 2px;
    border-radius: 10px;
    background-color: #f16b26;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    animation: arrow 700ms linear infinite;
  }
  .arrow::after,
  .arrow::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    right: -4px;
    border-radius: 10px;
    background-color: #f16b26;
  }

  .arrow::after {
    top: -3px;
    transform: rotate(45deg);
  }

  .arrow::before {
    top: 3px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    transform: rotate(-45deg);
  }
}

@media only screen and (max-width: 420px) {
  .icon {
    width: 25px;
    height: 15px;
  }
}

@media only screen and (max-width: 360px) {
  .icon {
    width: 25px;
    height: 15px;
  }
}
