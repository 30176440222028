.navbar {
    width: 100%;
    height: 100%;
}

.navTitle {
    text-align: left;
    font: bold 28px/55px Helvetica;
    color: #000;
    text-transform: uppercase;
}

.customNavbar {
    padding-top: 5rem;
    background-color: #F1F1F1;
    position: sticky;
    z-index: 2;
    top: 0;
}

.boxNavbar {
    display: flex;
    position: relative;
    padding: 0.5rem 0 0.5rem 0;
    border-top: 1px solid #d4d4d4;
}

.boxNavbar>select {
    border: none;
    text-align: left;
    font: 16px/32px Helvetica;
    color: #000;
    width: 20%;
    background: transparent;
    margin-left: 5rem;
}

.boxNavbar>select>option {
    color: #000;
}

.boxNavbar>select:focus {
    outline: none;
    border: none;
}

.date {
    font: 14px Helvetica;
    color: #000;
    text-align: left;
    width: 100%;
    margin: auto 1rem;
}

.boxSearch {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.inputSearch {
    width: 360px;
    height: 40px;
    border: 2px solid var(--unnamed-color-f1f1f1);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #f1f1f1;
    border-radius: 28px;
    padding: 1rem;
}

.inputSearch:focus {
    outline: none;
}

.searchHere {
    background-color: transparent;
    color: black;
    text-align: left;
    font: bold 28px/33px Helvetica;
    color: #494949;
    width: 100%;
    border: none;
    padding-left: 5rem;
    margin-bottom: 1rem;
}

.searchHere:focus {
    outline: none;
    border: none;
}

.placeholder {
    text-align: left;
    font: 14px/16px Helvetica;
    color: #d4d4d4;
    padding-left: 5rem;
}

.sticky {
    position: sticky;
    /* top: calc(-60px - 2rem); */
    top: calc(12px);
    z-index: 100;
    margin-bottom: 60px;
    margin-left: 82px;
    height: 56px;
    width: 100vw;
    background-color: #f1f1f1;
}

.contentSticky {
    background-color: #f1f1f1;
}

.contentTotal {
    margin: auto 0 auto 2rem;
}

.total {
    color: #f26522;
}

@media only screen and (max-width: 1400px) {
    .customNavbar {
        padding-left: 0;
        padding-top: 4rem;
    }

    .searchHere {
        font: bold 22px Helvetica;
        line-height: 1.5rem;
    }

    .boxSearch {
        padding-right: 10%;
    }
}