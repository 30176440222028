p.pageTitle {
  font: 80px/96px Helvetica;
  -webkit-text-fill-color: #223771;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  text-align: end;
  padding-bottom: 2rem;
}

.sectionEcosystem {
  background-color: white;
  /* padding-top: 5vh;
    padding-bottom: 2vh; */
  height: 100vh;
  width: 100vw;
  display: flex;
  position: relative;
}

.sectionEcosystem p {
  color: white;
}

p.title {
  /* font: 50px/61px Helvetica; */
  font: bold 60px/72px Helvetica;
}

p.subTitle {
  font: 30px/37px Helvetica;
  font-weight: bold;
}

p.content {
  /* font: 16px/24px Helvetica; */
  font: 18px/22px Helvetica;
}

.contentCenter {
  margin: auto;
  align-self: center;
  width: 896px;
  height: 608px;
  /* background-color: #223771; */
}

.contentRight {
  position: absolute;
  right: -15rem;
  top: 55%;
}

.contentImgRight {
  width: 507px;
  height: 456px;
  object-fit: cover;
}

.boxCenter {
  width: 896px;
  height: 456px;
  background-color: white;
  /* background: url(../../../../assets/Business/News_IdeaCloud.jpg) 0% 0% no-repeat padding-box; */
}

.boxRight {
  width: 507px;
  height: 456px;
  position: relative;
  /* background-color: gray; */
}

.contentBoxCenter {
  padding: 2rem;
  margin-top: 4rem;
}

.stairs {
  margin-top: 8rem;
}

.boxTop {
  width: 538px;
  height: 72px;
  background-color: white;
  display: flex;
  margin: 0 auto 0.5rem auto;
}

.boxMiddle {
  width: 721px;
  height: 72px;
  background-color: white;
  margin: 0 auto 0.5rem auto;
  display: flex;
}

.boxBottom {
  width: 896px;
  height: 72px;
  background-color: white;
  margin: auto;
  display: flex;
}

.contentStairs {
  text-align: center;
  margin: auto;
  color: #223771 !important;
  font: bold 30px/36px Helvetica;
  text-transform: uppercase;
}

.contentLogo {
  width: 113px;
  height: 113px;
  background-color: white;
  /* margin: 0 auto 3rem auto; */
  margin: auto 45% auto auto;
  /* margin: auto; */
  border-radius: 50%;
  display: flex;
  z-index: 0;
  box-shadow: 0px 3px 6px #00000029;
  position: absolute;
  top: calc(50% - 62px);
  left: calc(50% - 72px);
}

.contentBigLogo {
  width: 724px;
  height: 100vh;
  /* margin: 2rem auto 1.5rem auto; */
  margin: auto;
  display: flex;
  transition: opacity 1s ease-in-out;
  opacity: 0;
  position: relative;
  top: -12.7rem;
  right: 5rem;
}

.imgLogo {
  margin: auto;
  object-fit: contain;
  width: 724px;
  height: auto;
}

.imgLogoSmall {
  height: 113px;
  width: 80px;
  margin: auto;
  object-fit: contain;
}

.adaptive {
  margin-top: -12rem;
  position: relative;
}

.backgroundCircuit {
  width: 857px;
  height: 581px;
  background-image: url("./assets/circuit-board.svg");
  display: flex;
  margin: auto;
  position: relative;
}

.backgroundCircuitLarge {
  width: 857px;
  height: 100vh;
  background-image: url("./assets/circuit-board.svg");
  display: flex;
  margin: auto;
  position: relative;
  opacity: 0;
}

.boxCenterLarge {
  width: 857px;
  height: 100vh;
}

.isAbsolute {
  position: absolute;
  /* top: 19%;
    left: 20.5%;
    height: 50vh;
    width: 60vw; */
  top: 18%;
  left: 0.5rem;
  height: 50vh;
  width: 100%;
}

.scaleIncenter {
  -webkit-animation: scale-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scaleOutCenter {
  -webkit-animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/* .relative {
    position: relative;
} */

.absolute {
  position: absolute;
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    position: absolute;
    opacity: 1;
  }
}

@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}

.topLeftImage {
  position: absolute;
  top: -30vh;
  left: -40vh;
}

.st0 {
  fill: none;
  stroke: #f2f2f2;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.st1 {
  fill: #f2f2f2;
}

@media only screen and (max-width: 1400px) and (max-height: 768px) {
  .topLeftImage img {
    width: 65%;
  }
  .isAbsolute {
    top: 11%;
  }
  .contentCenter {
    transform: scale(1);
  }
}

@media only screen and (max-width: 1400px) {
  .topLeftImage img {
    width: 65%;
  }
  .isAbsolute {
    top: 11%;
  }
  .contentCenter {
    transform: scale(1);
  }
}

@media only screen and (max-height: 768px) {
  .contentCenter {
    transform: scale(1);
  }
  .isAbsolute {
    top: 11%;
  }
}
