.contentSection {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 1rem 0 0 0;
}

.blogTitle {
  text-align: left;
  font: bold 20px/32px Helvetica;
  color: #000000;
  text-transform: uppercase;
}

.blogContent {
  text-align: left;
  font: 16px/32px Helvetica;
  color: #000000;
}

.blogAuthor {
  text-align: left;
  font: 14px/55px Helvetica;
  color: #223771;
  float: left;
}

.blogDate {
  text-align: left;
  font: 12px/55px Helvetica;
  color: #b5b5b5;
  float: right;
}

.blogLink {
  text-align: left;
  font: bold 14px/55px Helvetica;
  color: #000000;
  float: left;
  margin: 0;
  position: relative;
  height: 40px;
}

.customContainer {
  padding-left: 4rem;
  display: flex;
  position: relative;

  .boxBlog {
    width: 944px;
    height: 205px;
    background: #f1f1f1 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    margin-bottom: 2rem;

    .boxBody {
      width: 944px;
      height: 105px;
      // background-color: turquoise;
      padding: 2rem calc(944px / 3) 0 2rem;
    }

    .boxFooter {
      width: 944px;
      height: 100px;
      // background-color: seagreen;
      padding: 0 2rem 2rem 2rem;
      display: flex;
      flex-direction: column;
    }

    .imgProfile {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      object-fit: cover;
      margin: 58px -44px auto auto;
      position: absolute;
      padding: 10px;
      top: 0;
      right: 0;
      background-color: #f1f1f1;
    }
  }

  .boxMask {
    width: 312px;
    height: 100%;
    background: #f1f1f1 0% 0% no-repeat padding-box;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.Accordion {
  position: fixed;
  top: 10rem;
  left: 0;
  width: calc(192px + 4rem);
  padding: 0 2rem;
  z-index: 99;

  .titleAccordion {
    font: bold 16px Helvetica;
  }
  .titleAccordion {
    font: bold 16px Helvetica;
  }

  .boxAcc {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .titleAcc {
    height: 30px;
    width: 192px;
    padding: 0.75rem 1.25rem 0.75rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  .ckCustom {
    opacity: 0;
    position: absolute;
  }

  .ckCustom,
  .ckCustomLbl {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
  }

  .ckCustom + .ckCustomLbl:before {
    content: "";
    background: #fff;
    border-radius: 5px;
    border: 2px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
    border-radius: 50%;
  }

  .ckCustom:checked + .ckCustomLbl:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: red;
  }

  @media only screen and (max-width: 1400px) {
    .contentImgRight {
      width: 100%;
    }

    .titleAcc {
      width: 115px;
    }
  }
}

.loadMore {
  width: 416px;
  height: 64px;
  background-color: #f16b26;
  margin: 50px auto;
  box-shadow: 3px -1px 6px #00000061;
  border-radius: 50px;
  justify-content: center;
  display: flex;
  position: relative;
}
._p {
  font: bold 20px Helvetica;
  color: white;
  text-align: center;
  margin: auto;
  // align-self: center;
  // padding: 1rem;
  // margin: none !important;
  cursor: pointer;
}
// .btn-phenikaa {
//   font: bold 12px/24px Helvetica;
//   background-color: #223771;
//   color: white;
//   border: none;
//   height: 2.4rem;
//   width: 7rem;
//   border-radius: 50px;
//   margin: auto;
//   padding: auto;
// }

.readMore:hover {
  background-color: #223771;
}

.fadeInBottom {
  -webkit-animation: fade-in-bottom 0.5s ease-in both;
  animation: fade-in-bottom 0.5s ease-in both;
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1400px) {
  .customContainer {
    padding-left: 14rem;

    .boxBlog {
      width: 744px;
      .boxBody {
        width: 744px;
        padding: 2rem calc(500px / 3) 0 2rem;
      }
      .boxFooter {
        width: 744px;
      }
      .imgProfile {
        width: 100px;
        height: 100px;
        margin: 113px -33px auto auto;
      }
    }
  }
  .Accordion {
    left: -15px;
  }

  .blogTitle {
    font: bold 18px/32px Helvetica;
  }
}
