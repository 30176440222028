.card-img .image_zoom_2 {
  opacity: 0;
  height: 300px;
  width: auto;
  margin-top: -260px;
  margin-left: 3rem;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
  outline: 0;
}

.row {
  margin-left: 0;
  margin-right: 0;
}