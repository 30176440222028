/* GENERAL */
:root {
  --title-hd: bold 28px Helvetica;
  --subtitle-hd: bold 18px Helvetica;
  --p-hd: normal 14px Helvetica;
  --container-hd: 1062px !important;
  --padding-section-hd: 3.5rem 0;

  --title-fhd: bold 36px Helvetica;
  --subtitle-fhd: bold 26px Helvetica;
  --p-fhd: normal 16px Helvetica;
  --container-fhd: 1540px !important;
  --padding-section-fhd: 5rem 0;
}

.contentSection {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 5rem 0 5rem 0;
}

.title {
  font: bold 36px Helvetica;
  color: #000000;
  margin-bottom: 2rem;
}

.content {
  font: 16px/32px Helvetica;
  color: #000000;
  text-align: justify;
}

.box {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.boxLeft {
  width: 50%;
  height: 424px;
  background-color: #fff;
  padding-right: 2rem;
}

.boxRight {
  width: 50%;
  height: 424px;
  background-color: #000;
}

.titleChart {
  text-align: left;
  font: 22px/27px Helvetica;
  color: #ffffff;
  padding: 2rem 2rem 0 2rem;
}

.lineChart {
  animation: fadeIn 600ms cubic-bezier(0.57, 0.25, 0.65, 1) 1 forwards;
  opacity: 0;
  max-width: 640px;
  width: 100%;
  margin: auto 0;
  padding: 0 2rem;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.boxLinks {
  position: relative;
  margin: auto 0;
}

.boxLink {
  position: relative;
  margin-top: 2rem;
}

.outerCircle {
  padding: 5px;
  /* background-color: rgb(45, 57, 69, 0.5); */
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  margin: 0 1rem 0 auto;
  align-self: center;
  opacity: 0;
  position: absolute;
  top: 1px;
}

.innerCircle {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.linkAll {
  text-align: left;
  font: 14px/24px Helvetica;
  color: #000000;
  cursor: pointer;
  transition: all 1;
  margin: auto 0;
}

.boxLink:hover .outerCircle {
  opacity: 1;
}

.boxLink:hover .linkAll {
  margin-left: 1.5rem;
  text-decoration: none;
  color: #000;
}

.boxC {
  max-width: 1540px;
}

@media only screen and (min-width: 2560px) {
  .boxC {
    max-width: 2090px !important;
    padding: 0;
  }
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1400px) {
  .contentSection {
    padding: 3.5rem 0 3.5rem 0;
  }

  .boxC {
    max-width: var(--container-hd) !important;
    padding-right: 0;
    padding-left: 0;
  }

  .title {
    font: var(--title-hd);
    margin-bottom: 1.5rem;
  }

  .boxLink {
    margin-top: 1.5rem;
  }
}
