.sectionDistribution {
  background-color: white;
  height: 100%;
  width: 100%;
}

.boxImage {
  width: 100%;
  /* height: 504px; */
  background-color: gray;
  margin-top: 1rem;
}

.imgContent {
  width: 100%;
  /* height: 504px; */
  object-fit: cover;
}

.imgContentD {
  width: 100%;
  height: 504px;
  object-fit: contain;
}

.contentDummy ul li {
  line-height: 2rem;
}

.boxContent {
  /* padding: 2rem 5rem 0 0; */
  width: 85vw;
}

.boxOverflow {
  width: calc((100% - 504px) + 10vw);
  height: 352px;
  background-color: transparent;
}

.boxContentOverflow {
  padding: 2rem;
  width: 504px;
  height: 352px;
  background-color: #223771;
  margin-top: -10rem;
  box-shadow: 0px 6px 16px #00000073;
  overflow-y: auto;
}

.boxContentOverflow p,
.boxContentOverflow span,
.boxContentOverflow li {
  font: bold 16px/19px Helvetica;
  color: white;
}

p.title {
  font: bold 18px/31px Helvetica;
}

p.content {
  font: 16px/32px Helvetica;
}

p.contentLink {
  font: bold 12px/24px Helvetica;
  margin: auto 0;
}

.outerCircle {
  padding: 5px;
  /* background-color: rgb(45, 57, 69, 0.5); */
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  bottom: -1rem;
  left: 0rem;
  opacity: 1;
  margin: auto 0.5rem auto 0;
}

.innerCircle {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.boxContentOverflow > ul > li {
  font: 16px/19px Helvetica;
  margin-bottom: 1rem;
}

.boxContentOverflow > div > ul > li {
  font: 16px/19px Helvetica;
  margin-bottom: 1rem;
}

.link {
  text-decoration: none !important;
  color: black;
}

@media only screen and (max-width: 1400px) {
  .customContainer {
    max-width: 1200px;
    background-color: #f3f3f3;
    padding-bottom: 2rem;
  }

  .boxContentOverflow {
    width: 404px;
  }

  .boxContentOverflow > div > ul > li {
    margin-bottom: 0.8rem;
  }

  .boxContentOverflow > div > ul {
    padding-inline-start: 20px;
  }
}
