.logo {
    height: 60px;
    width: 112px;
    position: fixed;
    z-index: 10;
    left: 1%;
    /* margin-top: 0.5rem; */
    top: 1%;
}

.newNavbar {
    height: 56px;
    width: 100%;
    background-color: #f1f1f1;
    transition: all 1s ease;
    z-index: 80;
    margin-left: 69px;
    /* position: absolute; */
    top: 1rem;
    transition: all 1s linear 0.3s;
    /* position: -webkit-sticky; */
    position: fixed;
    top: 1.5%;
    box-shadow: -5px 6px 6px #00000029;
}

.navbarMask {
    height: 56px;
    width: 100%;
    background-color: #f1f1f1;
    z-index: 99;
    margin-left: 192px;
    position: absolute;
    right: 0;
}

.nav_fake {
    background-color: #f1f1f1;
    height: 56px;
    width: 300px;
    position: fixed;
    right: 0;
    top: 1%;
    z-index: 99;
    margin-top: 4.5px;
}

.backgroundsticky {
    background-color: #f1f1f1;
    width: 100vw;
    height: 440px;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.stickyBackground {
    position: fixed;
    top: calc(-440px + 85px);
}

.img_list {
    width: 20px;
}

.img_grid {
    width: 20px;
}

.wrap_tab {
    background-color: white;
    height: 100px;
    border-radius: 10px;
    margin-top: 10px;
    width: 135px;
    position: absolute;
    left: 4%;
    top: 1%;
    opacity: 0;
}

.btn_wrap {
    height: 50px;
    width: 50px;
    text-align: center;
    margin-top: -5px;
}

.button_tab {
    width: 10px;
    height: 10px;
}

.box {
    height: 56px;
    padding: 1.4rem;
    display: flex;
    position: relative;
}

.box:hover .wrap_tab {
    opacity: 1;
}

.box span {
    color: #000;
    font: bold 14px/28px Helvetica;
    align-self: center;
}

.box span:hover {
    cursor: pointer;
}

.isActive span {
    color: #223771;
    text-transform: uppercase;
}

.isActive {
    border-bottom: 3px solid #223771;
}

.box .isActive span {
    color: #223771;
    text-transform: uppercase;
}

.wrap_option {
    display: flex;
    position: absolute;
    left: 42%;
}

.input_search {
    width: 375px;
    height: 31px;
    border: none;
    font-size: 18px;
    position: absolute;
    top: 10%;
}

.input_search:focus {
    border: hidden !important;
    outline: none;
}

.box_search {
    width: 416px;
    height: 35px;
    background-color: white;
    padding: 10px 20px;
    display: flex;
    border-radius: 20px;
    border: hidden;
    margin: 8px;
    position: absolute;
    right: 30%;
}

.grid {
    width: 40px;
    height: 40px;
    padding: 1rem;
}

.grid_fake {
    position: absolute;
    top: 40%;
    left: 20%;
    opacity: 0;
}

.list_fake {
    position: absolute;
    top: 40%;
    /* left: 70%; */
    opacity: 0;
    right: 0;
}

.grid:hover .grid_fake {
    opacity: 1;
}

.list {
    width: 40px;
    height: 40px;
    padding: 1rem;
}

.box_search p {
    text-align: left;
    font: 19px Helvetica;
}

.box_search img {
    position: absolute;
    right: 3%;
    width: 20px;
    height: 20px;
    top: 23%;
}

.main {
    min-height: 100vh;
    background: #f1f1f1;
    position: relative;
    z-index: 1;
    box-shadow: 0 3px 6px #00000029;
}

.content {
    /* margin-top: 4rem; */
    position: relative;
    display: flex;
}

.contentWrap {
    position: relative;
    display: flex;
    margin: 2rem auto;
    justify-content: center;
}

p.title {
    font: 18px/32px Helvetica;
    color: black;
}

p.contentTitle {
    font: bold 24px/24x Helvetica;
    color: black;
}

p.contentDesc {
    font: 16px/24px Helvetica;
    color: black;
}

.boxContent {
    width: 872px;
    height: 256px;
    background-color: #acacac;
    text-decoration: none;
    color: black;
}

.boxContentL1 {
    width: 872px;
    height: 256px;
    background-color: #223771;
    text-decoration: none;
    color: black;
}

.boxContentL2 {
    width: 872px;
    height: 256px;
    background-color: #f16b26;
    text-decoration: none;
    color: black;
}

.boxContent1 {
    width: 432px;
    height: 426px;
    background-color: #f1f1f1;
    text-decoration: none;
    color: white;
    margin: 0 12px;
    position: relative;
    -webkit-animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.boxContent2 {
    width: 432px;
    height: 376px;
    background-color: #223771;
    text-decoration: none;
    color: white;
    margin: 0 12px;
    position: relative;
    -webkit-animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.boxContent3 {
    width: 432px;
    height: 376px;
    background-color: #f16b26;
    text-decoration: none;
    color: white;
    margin: 0 12px;
    position: relative;
    -webkit-animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes shadow-drop-2-center {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }

    100% {
        -webkit-transform: translateZ(50px);
        transform: translateZ(50px);
        -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
}

@keyframes shadow-drop-2-center {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }

    100% {
        -webkit-transform: translateZ(50px);
        transform: translateZ(50px);
        -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
}

.boxflex {
    width: 472px;
}

.boxContent:hover {
    text-decoration: none;
    box-shadow: 0 3px 6px #00000029;
}

.contentBox {
    position: relative;
    z-index: 1;
    padding: 1rem 2rem;
    text-transform: uppercase;
    color: white;
}

.backBox {
    padding: 2px 14px;
    border-radius: 50px;
    width: fit-content;
    background-color: #223771;
}

.boxImage {
    width: 472px;
    height: 256px;
    /* background-color: #F16B26; */
    overflow: hidden;
    position: relative;
}

.newsColor {
    background-color: #f1f1f1;
}

.newsColor {
    background-color: #f1f1f1;
}

.eventColor {
    background-color: #223771;
}

/* 
.upEventColor {
    background-color: #F16B26;
} */

.title_content {
    padding: 0 2rem;
    font: bold 24px/30px Helvetica;
    color: white;
    font-weight: bold;
    height: 65px;
    /* margin-bottom: 1rem; */
}

.title_content1 {
    /* padding: 0 2rem; */
    font: bold 18px/30px Helvetica;
    color: black;
    font-weight: bold;
    height: 56px;
    /* margin-bottom: 1rem; */
    /* max-height: 6rem; */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.txt_content {
    padding: 1rem 2rem;
    font: 16px Helvetica;
    height: 70px;
    overflow: scroll;
    color: white;
}

.txt_content1 {
    padding: 1rem 2rem;
    font: 16px Helvetica;
    max-height: 125px;
    overflow: hidden;
    width: 100%;
    word-break: break-all;
    /* overflow: scroll; */
}

.foot_content {
    position: relative;
    display: flex;
    padding: 1rem 2rem 1rem;
    font: 16px Helvetica;
    color: white;
}

.foot_content1 {
    position: relative;
    display: flex;
    padding: 1rem 0 0 0;
    font: 16px Helvetica;
    color: black;
}

.date_right {
    position: absolute;
    right: 6%;
}

.date_right1 {
    position: absolute;
    right: 10%;
}

.box_image {
    width: 432px;
    height: 266px;
    object-fit: cover;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
    overflow: hidden;
}

.div_readmore {
    position: relative;
    background-color: #f1f1f1;
    width: 420px;
    height: 156px;
}

.readmore_btn {
    /* margin: 3rem auto 3rem auto; */
    position: absolute;
    background-color: #223771;
    width: 192px;
    height: 48px;
    color: white;
    border-radius: 40px;
    text-align: center;
    padding: 0.75rem;
    top: 30%;
    left: 25%;
}

.box_behind {
    position: absolute;
    top: 0;
    opacity: 01;
}

.box_behind:hover img {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    transition: 1s ease-in-out;
}

.boxContent1:hover .box_behind {
    opacity: 1;
}

.div_readmore1 {
    position: relative;
    background-color: #f1f1f1;
    width: 432px;
    height: 160px;
    padding: 2rem;
}

.readmore_btn1 {
    /* margin: 3rem auto 3rem auto; */
    position: absolute;
    background-color: #223771;
    width: 192px;
    height: 48px;
    color: white;
    border-radius: 40px;
    text-align: center;
    padding: 0.75rem;
    top: 35%;
    left: 25%;
}

.box_content_image {
    position: relative;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
    overflow: hidden;
}

figure.wrapp_image {
    width: 880px;
    height: 424px;
    overflow: hidden;
    /* background: #eee5e5bd; */
}

.img_content {
    width: 472px;
    height: 256px;
    object-fit: cover;
    -we-webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
    overflow: hidden;
}

.inside_image {
    width: 472px;
    height: 256px;
    margin: auto;
    position: absolute;
    /* display: block; */
    top: 0;
    left: 0;
    padding: 15%;
    text-align: center;
    background-color: rgba(234, 236, 236, 0.945);
    opacity: 0;
    color: black;
}

.icon_share {
    /* position: absolute; */
    width: 50px;
    height: 50px;
    margin-bottom: 7%;
    /* top: 40%; */
    /* left: 47%; */
    /* opacity: 0; */
}

figure.wrapp_image:hover .inside_image {
    opacity: 1;
}

figure.wrapp_image:hover .img_content {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    transition: 2s ease-in-out;
}

.icon {
    margin: 5px;
    top: 50%;
}

.img_content:hover {
    /* opacity: 0.1; */
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    transition: 2s ease-in-out;
}

.background_blur {
    z-index: 8;
    height: 75px;
    background-color: rgb(255 255 255);
    width: 100vw;
    position: fixed;
    top: 0;
    width: 100vw;
    background-color: #f1f1f1;
}

.link {
    text-decoration: none !important;
}

.component {
    -webkit-animation: scale-up-hor-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-hor-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-hor-center {
    0% {
        -webkit-transform: scaleX(0.4);
        transform: scaleX(0.4);
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

@keyframes scale-up-hor-center {
    0% {
        -webkit-transform: scaleX(0.4);
        transform: scaleX(0.4);
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

.fadeInBottom {
    -webkit-animation: fade-in-bottom 0.5s ease-in both;
    animation: fade-in-bottom 0.5s ease-in both;
}

@-webkit-keyframes fade-in-bottom {
    0% {
        -webkit-transform: translateY(25px);
        transform: translateY(25px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-in-bottom {
    0% {
        -webkit-transform: translateY(25px);
        transform: translateY(25px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

/* .componentgrid {
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
} */

.images_slide {
    width: 100%;
    height: 1080px;
    background-color: tomato;
}

.older {
    width: 416px;
    height: 64px;
    background-color: #f16b26;
    margin: 2rem auto 0 auto;
    /* padding: 2rem 0 5rem 0; */
    box-shadow: 3px -1px 6px #00000061;
    border-radius: 50px;
    display: flex;
    position: relative;
}

.older p {
    font: 20px Helvetica;
    color: white;
    text-align: center;
    /* padding: 1rem; */
    margin: auto;
    cursor: pointer;
}

.box_grid {
    position: relative;
    display: flex;
    margin: 1rem auto;
    justify-content: center;
}

.customSelect {
    border: none;
    background-color: transparent;
    margin: 15px 0 0 0;
    display: flex;
    height: 100%;
    width: 10%;
    position: relative;
    font: 16px Helvetica;
}

.contentNoResult {
    position: relative;
    display: flex;
    margin: 0 auto;
    justify-content: center;
}

.warningResultText {
    text-align: left;
    font: bold 38px/45px Helvetica;
    text-align: center;
}

@media only screen and (max-width: 1366px) {
    .newNavbar {
        margin-left: 209px;
    }

    .box_grid {
        width: 100%;
        margin: -1rem auto 1rem auto;
    }

    .date_right1 {
        right: 0;
    }

    .older {
        width: 316px;
        height: 44px;
    }

    .input_search {
        width: 245px;
        font-size: 14px;
    }

    .content {
        margin-top: -20rem;
    }

    .img_grid {
        width: 17px;
    }

    .img_list {
        width: 17px;
    }

    .box_search {
        right: 33%;
        width: 285px;
    }

    .box_search p {
        font: 16px Helvetica;
    }

    .wrap_option {
        left: 38%;
    }

    .boxContent {
        width: 600px;
        height: 200px;
    }

    .boxContentL1 {
        width: 600px;
        height: 200px;
    }

    .boxContentL2 {
        width: 600px;
        height: 200px;
    }

    .div_readmore {
        width: 290px;
        height: 90px;
    }

    .div_readmore1 {
        width: 290px;
        height: 70px;
        padding: 1rem;
    }

    .boxContent1 {
        width: 290px;
        height: 320px;
    }

    .boxContent2 {
        width: 290px;
        height: 280px;
    }

    .boxContent3 {
        width: 290px;
        height: 280px;
    }

    .box_image {
        width: 290px;
        height: 210px;
    }

    .boxImage {
        width: 318px;
        height: 200px;
    }

    .title_content {
        padding: 10px 25px;
        font: bold 16px Helvetica;
        height: 60px;
    }

    .contentBox {
        padding: 15px 25px 5px;
        text-decoration: none;
    }

    .txt_content {
        padding: 10px 25px;
        font: 14px Helvetica;
        height: 60px;
    }

    .foot_content {
        font: 14px Helvetica;
        padding: 0 25px;
        height: 50px;
    }

    .img_content {
        /* object-fit: cover; */
        width: 318px;
        height: 200px;
    }

    .readmore_btn {
        top: 25%;
        left: 17%;
    }

    .readmore_btn1 {
        top: 25%;
        left: 17%;
    }

    .title_content1 {
        font: bold 14px/22px Helvetica;
        height: 40px;
        /* padding: 0 1.5rem; */
    }

    .txt_content1 {
        font-size: 14px;
        height: 70px;
        padding: 0 1.5rem;
        margin-bottom: 1rem;
    }

    .foot_content1 {
        font: 14px Helvetica;
        /* padding: 0 1.5rem; */
    }
}

/* @media only screen and (max-width:2350px) {
    .content {
        margin-top: -29rem;
    }
    .newNavbar {

        margin-left: 225px;
    }

    .box_grid {
        width: 80%;
    }

    .input_search {
        width: 245px;
        font-size: 18px;
    }

    .contentWrap{
        width: 70%;
    }
.wrap_option {
    left: 35%;
}
.inside_image {
    width: 371px;
}

} */