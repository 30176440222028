.customContainer {
    padding: 5rem 0;
    height: 100%;
    width: 100%;
}

.boxNav {
    display: flex;
    margin-bottom: 1rem;
}

.customLink {
    display: flex;
    text-decoration: none;
}

.customLink:hover {
    text-decoration: none;
}

.backButton {
    font: 12px/16px Helvetica;
    color: #000000;
    margin: 0 0 0 1rem;
}

.category {
    font: 18px/32px Helvetica;
    color: #000000;
}

.title {
    text-align: left;
    font: bold 24px/32px Helvetica;
    letter-spacing: -0.24px;
    color: #000000;
    text-transform: uppercase;
}

.boxImage {
    width: 100%;
    height: 504px;
}

.contentImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.titleContent {
    font: bold 26px/31px Helvetica;
    color: #000000;
}

.content {
    font: 16px/32px Helvetica;
    color: #000000;
}

.boxLeft {
    padding: 2rem 5rem 0 0;
    width: 60vw;
}

.boxRight {
    width: calc((100% - 624px) + 10vw);
    height: 464px;
    background-color: transparent;
    display: block;
    position: relative;
}

.boxContentOverflow {
    padding: 2rem 2rem;
    width: 624px;
    height: 280px;
    background-color: #D4D4D4;
    margin-top: 4rem;
    /* box-shadow: 0px 6px 16px #00000073; */
    overflow-y: hidden;
    text-align: justify;
}

.contentDesc {
    font: 16px/32px Helvetica;
    color: #000000;
    text-align: justify;
}

.boxContent ul>li {
    font: 16px/32px Helvetica;
    margin-bottom: 1rem;
    color: #000000;
}

.sectionContent {
    font: 16px/32px Helvetica;
    text-align: justify;
}

.sectionContent ul {
    margin-top: 1rem;
}

.sectionContent strong {
    display: block;
    margin-bottom: 1rem;
    font: 20px/24px Helvetica;
    color: #000000;
}

.sectionContent div {
    margin-top: 2rem;
}

.section2 {
    margin: 2rem 0;
}

.boxWrapping {
    background-color: #F1F1F1;
}

.s2_boxLeft,
.s2_boxRight {
    width: 50%;
    height: 100%;
    margin: 0 !important;
}

.s2_boxLeft div,
.s2_boxRight div {
    padding: 2rem;
    margin: 0;
    text-align: justify;
}

.boxFullImage {
    width: 100%;
    height: 512px;
    background-color: #F1F1F1;
    display: flex;
    position: relative;
}

.boxFullImage img {
    width: 100%;
    height: 472px;
    margin: auto;
    object-fit: contain;
}

.section3 {
    margin: 2rem 0;
}

.box {
    width: 336px;
    height: 176px;
    margin-right: 1rem;
    position: relative;
}

.box:hover .contentFront {
    opacity: 0;
}

.box:hover .contentBack {
    opacity: 1;
}

.box:hover .boxMask {
    opacity: .8;
}

.box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contentFront,
.contentBack {
    width: 336px;
    height: 176px;
    position: absolute;
    top: 0;
    left: 0;
}

.contentFront {
    opacity: 1;
}

.contentBack {
    opacity: 0;
}

.boxMask {
    width: 336px;
    height: 176px;
    position: absolute;
    top: 0;
    left: 0;
    background: #223771 0% 0% no-repeat padding-box;
    opacity: .5;
}

.boxContentIn {
    width: 336px;
    height: 176px;
    display: flex;
    justify-content: flex-end;
    align-self: center;
    position: relative;
}

.boxContentIn p {
    text-align: right;
    font: bold 16px/15px Helvetica;
    color: #FFFFFF;
    margin: auto 0;
    padding: 0 1rem 0 0;
    display: block;
}

.outerCircle {
    padding: 5px;
    transition: transform 0.2s;
    border-radius: 50%;
    border: 1px solid #F16624;
    display: inline-block;
    position: relative;
    opacity: 1;
    margin: .5rem 0 auto auto;
}

.innerCircle {
    content: "";
    background: #F16624;
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

.boxContentIn .viewMore {
    text-align: right;
    font: 14px Helvetica;
    color: #FFFFFF;
    margin: .6rem 2rem 0 0;
    padding: 0 0 0 .5rem;
    display: block;
}