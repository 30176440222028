header {
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.bg-image {
  background-image: url(../assets/image-background.jpg);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.img-logo {
  height: 80px;
  width: 272px;
  color: white;
  fill: white;
  z-index: 100;
  margin: 0 0 0 1rem;
}

.navbar-top {
  position: fixed;
  top: 0px;
  width: 100vw;
  border: none;
  /* background-color: transparent !important; */
  z-index: 100;
  padding: 1rem;
}

.navbar-top-transparent {
  position: fixed;
  top: 0px;
  width: 100vw;
  border: none;
  background-color: transparent;
  z-index: 100;
  padding: 1rem;
}

.nav-text {
  color: white !important;
  font-weight: bold;
  z-index: 100;
}

header h1 {
  color: white;
}

header p {
  color: white;
}

@media only screen and (max-width: 1400px) {
  .img-logo {
    width: 77px;
  }
}
