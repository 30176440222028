/* GENERAL */
:root {
  --title-hd: bold 28px Helvetica;
  --subtitle-hd: bold 18px Helvetica;
  --p-hd: normal 14px Helvetica;
  --container-hd: 1062px !important;
  --padding-section-hd: 3.5rem 0;

  --title-fhd: bold 36px Helvetica;
  --subtitle-fhd: bold 26px Helvetica;
  --p-fhd: normal 16px Helvetica;
  --container-fhd: 1540px !important;
  --padding-section-fhd: 5rem 0;
}

.hrGeneral {
  margin: 5rem 0 0 0;
}

/* SPECIFIC */
.contentSection {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 5rem 0 0 0;
}

.title {
  font: bold 36px Helvetica;
  color: #000000;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.contentTitle {
  font: bold 16px/32px Helvetica;
  color: #000000;
}

.content {
  font: 16px/32px Helvetica;
  color: #000000;
  text-align: justify;
}

.boxImage {
  width: 1440px;
  height: 440px;
  position: relative;
}

.boxImage img {
  object-fit: cover;
  width: 1440px;
  height: 440px;
}

.boxLinks {
  position: relative;
  margin: auto 0;
}

.boxLink {
  position: relative;
  margin-top: 2rem;
}

.outerCircle {
  padding: 5px;
  /* background-color: rgb(45, 57, 69, 0.5); */
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  margin: 0 1rem 0 auto;
  align-self: center;
  opacity: 0;
  position: absolute;
  top: 1px;
}

.innerCircle {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.linkAll {
  text-align: left;
  font: 14px/24px Helvetica;
  color: #000000;
  cursor: pointer;
  transition: all 1;
  margin: auto 0;
}

.boxLink:hover .outerCircle {
  opacity: 1;
}

.boxLink:hover .linkAll {
  margin-left: 1.5rem;
  text-decoration: none;
  color: #000;
}

.boxC {
  max-width: 1540px;
}

@media only screen and (min-width: 2560px) {
  .boxC {
    max-width: 2090px !important;
  }
  .contentSection {
    padding: 0;
  }
  .boxImage img {
    width: 2000px;
  }
  .boxImage {
    width: 2000px;
  }
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1400px) {
  .contentSection {
    padding: 3.5rem 0 0 0;
  }

  .hrGeneral {
    margin: 3.5rem 0 0 0;
  }

  .boxImage {
    width: 100%;
  }

  .boxImage img {
    width: 100%;
  }

  .boxC {
    max-width: var(--container-hd) !important;
    padding-left: 0;
    padding-right: 0;
  }

  .title {
    font: var(--title-hd);
    margin-bottom: 1.5rem;
  }

  .boxLink {
    margin-top: 1.5rem;
  }
}
