.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-right-rev {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
	animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
}

.content-hamburger {
	position: absolute;
	top: 1rem;
	right: 1rem;
	width: 56px;
	height: 56px;
	background: #00000080 0% 0% no-repeat padding-box;
}

.burger-item {
	mix-blend-mode: color;
}