/* Box Banner Section */
.boxBanner {
  width: 100vw;
  height: 65vh;
  background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
  background: #000000 0% 0% no-repeat padding-box;
  display: block;
  position: relative;
}

.sectionTitle {
  max-width: 1520px;
  padding: 2rem 0 2rem 0;
  font: bold 26px Helvetica;
}

.sectionTitle h2 {
  font: bold 36px Helvetica;
  margin-bottom: 0 !important;
}
.boxBanner .frontDiv {
  width: 100%;
  height: 65vh;
  background-color: black;
  opacity: 0.5;
  position: absolute;
}

.boxBanner img {
  width: 100vw;
  height: 65vh;
  object-fit: cover;
}

.boxBanner .navigator {
  height: 80px;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: auto;
  color: white;
  font: normal normal normal 16px Helvetica;
  display: flex;
  align-items: center;
}

.boxBanner .navigator .navLeft,
.boxBanner .navigator .navRight {
  display: flex;
  align-items: center;
  font: bold 16px Helvetica;
}

.boxBanner .navigator .navLeft {
  float: left;
}

.boxBanner .navigator .navRight {
  float: right;
}

.navIcon {
  font: bold 24px/48px Helvetica;
  cursor: pointer !important;
}

.navRight .navIcon {
  margin-left: 20px;
}
.customLink {
  text-decoration: none;
}

.customLink:hover {
  text-decoration: none;
}

/* .customLink {
  text-decoration: none !important;
  color: black !important;
}

.customLink p {
  color: black !important;
} */

.navLeft {
  display: flex;
  justify-content: left;
  align-self: center;
  max-width: 1520px;
  padding-top: 1rem;
}

.navLeft .navIcon {
  margin-right: 20px;
  cursor: pointer;
}

.navLeft p {
  cursor: pointer;
}

/* Page Link Section */
.pageLinkContainer {
  margin-top: 47px;
  margin-bottom: 44px;
  font: normal normal normal 18px Helvetica;
  color: #cfcfcf;
  display: flex;
  align-items: center;
}

.pageLinkContainer .text {
  color: black;
}

.pageLinkContainer .icon {
  font-size: 8px;
  margin: 0 16px;
}

/* Main Section */
.mainClass {
  padding-bottom: 5rem;
}

.blogs {
  position: relative;
  max-width: 1520px;
  background-color: #f6f6f6;
  padding-bottom: 5rem;
}

.marg {
  margin-left: 16.5rem;
}

/* .blogs span,
p,
ul,
li {
  font: 16px Helvetica;
} */

.contP {
  width: 100%;
}

.boxContent {
  /* margin: 2rem; */
  height: 100%;
  max-width: 1520px;
  padding: 3rem;
}

.boxProfile {
  width: 336px;
  height: 504px;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  padding: 2rem;
}

.jobTitle {
  text-align: left;
  font: normal normal bold 26px Helvetica;
  color: #000000;
  margin-bottom: 0 !important;
}

.companyAndLocContainer {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
}

.companyAndLocContainer .loc {
  margin-left: 40px;
}

.companyAndLocContainer span {
  font: 16px Helvetica;
  font-weight: bold;
}

.date {
  text-align: left;
  font: 16px Helvetica;
  color: #000000;
}

.contentQualification {
  margin-top: 60px;
  margin-right: 35px;
  font: normal normal normal 16px/32px Helvetica;
}

.spacer {
  height: 1px;
  background-color: #d5d5d5;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 40px;
}

.jobDesc {
  margin: 0;
}

.jobDesc h2 {
  font: normal normal bold 24px Helvetica;
}

.contentJobDesc {
  margin-top: 28px;
  margin-right: 35px;
  font: normal normal normal 16px/32px Helvetica;
}

.boxFlex {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.leftOnly {
  width: 80vw;
  position: relative;
  justify-content: end;
  align-self: flex-end;
  display: flex;
}

.leftOnly p {
  font: bold 26px Helvetica;
  color: black;
  text-align: left;
  margin-bottom: 0 !important;
}

.content {
  font: normal 16px Helvetica;
  line-height: 1.8rem;
  margin-bottom: 0 !important;
}

.jobDesc {
  font: normal 26px Helvetica;
  line-height: 1.8rem;
  margin-bottom: 2rem !important;
}

.line {
  margin: 2rem 0;
}

.reQ p {
  font: normal 16px Helvetica;
  line-height: 1.8rem;
  margin-bottom: 0 !important;
}
.reQ1 p {
  font: normal 26px Helvetica;
  margin-bottom: 0 !important;
}
.content p {
  margin-bottom: 0 !important;
}

.jobDesc p {
  margin-bottom: 0 !important;
}

.jobDesc b,
strong {
  margin-bottom: 1rem !important;
}

.faIcon {
  margin: auto 0;
}

.share {
  width: 50%;
  height: 100%;
  justify-content: center;
  align-self: center;
  margin: auto;
  display: flex;
}

.share span {
  align-self: center;
  justify-content: center;
  font: bold 16px Helvetica;
}

.shareSaveApplyCon {
  position: relative;
  width: 20vw;
  display: flex;
  flex-direction: column;
}

.applySquare {
  width: 100px;
  height: 3vh;
  display: flex;
  background-color: #d6d6d6;
  justify-content: center;
  align-self: center;
  margin: auto;
}

.applySquare p {
  font: 16px Helvetica;
  margin: auto;
}

.shareSaveApplyCon .topShare {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  /* margin: 1rem 0; */
}

.shareSaveApplyCon .share span {
  margin-left: 8px;
}

.bottomShare {
  margin-top: 32px;
}

.contBtn {
  width: 100%;
  max-width: 1540px;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
}

.back {
  width: 50%;
  height: auto;
}

.back p {
  font: 16px Helvetica;
  text-align: left;
  margin-bottom: 0;
}
.next p {
  font: 16px Helvetica;
  text-align: right;
  margin-bottom: 0;
}
.next {
  width: 50%;
  height: auto;
}
.bottomShare .applyBtn {
  height: 48px;
  width: 100%;
  background-color: white;
  border: none;
  font-weight: bold;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.textBack {
  font: normal 16px/32px Helvetica;
  color: black;
  margin: 0;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.customMargin {
  margin: auto 1rem auto auto;
}

@media only screen and (max-width: 1400px) {
  .blogs {
    max-width: 81vw;
    padding-bottom: 3.5rem;
  }

    .boxContent {
        /* margin: 2rem; */
        padding: 2rem;
    }

  .marg {
    margin-left: 10.7rem;
  }

  .textBack {
    font: normal 14px Helvetica;
  }

  .mainClass {
    padding-bottom: 3.5rem;
  }
  .navLeft {
    max-width: 1062px;
  }
  .sectionTitle {
    max-width: 1062px;
  }

  .leftOnly p {
    font: bold 18px Helvetica;
  }

  .date,
  .companyAndLocContainer {
    font: 14px Helvetica;
  }

  .reQ p {
    font: normal 14px Helvetica;
    line-height: 1.5rem;
  }

  .jobDesc {
    font: normal 18px Helvetica;
    line-height: 1.5rem;
    margin-bottom: 1rem !important;
  }

  .container{
      width: 80vw;
  }

  .line {
    margin: 1rem 0;
  }

  .sectionTitle {
    padding: 0.5rem 0 2rem 0;
  }

  .share span {
    font: bold 14px Helvetica;
  }

  .contBtn {
    min-width: 1062px !important;
  }
}

@media only screen and (min-width: 2560px) {
  .blogs {
    min-width: 2000px !important;
  }
  .sectionTitle {
    min-width: 2000px;
  }
  .contBtn {
    min-width: 2000px;
  }

  .navLeft {
    max-width: 2000px;
  }

  .boxContent {
    max-width: 2000px;
  }

  .leftOnly p {
    font: bold 36px Helvetica;
  }

  .jobDesc {
    font: bold 26px Helvetica;
  }

  .reQ p {
    line-height: 2rem;
  }

  .marg {
    margin-left: 21.5rem;
  }
}
