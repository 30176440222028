.customContainer {
  padding: 2rem 0 2rem 0;
}

.wrapHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 60px;
  background-color: #d4d4d4;
}

.firstColumn {
  width: 20%;
  height: 100%;
}
.secondColumn {
  width: 40%;
  height: 100%;
}
.thirdColumn {
  width: 40%;
  height: 100%;
}
.fourthColumn {
  width: 40%;
  height: 100%;
}

.wrapHeader span {
  font: 18px Helvetica;
  text-align: left;
  color: black;
  margin: auto;
}

.wrapHeader div {
  display: flex;
  justify-content: center;
}

.customRow {
  height: 100%;
  width: 100%;
  display: flex;
  border-bottom: 1px solid black;
  font: 16px Helvetica;
  text-align: justify;
}

.customRow ul > li {
  padding-bottom: 0.5rem;
}

.wrapTable {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  /* padding: 0 0 4rem 0; */
}

.wrapTable .customRow > div {
  padding: 2rem;
}

.boxIcon {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 120px;
}

.firstColumnT {
  width: 20%;
  height: 100%;
  text-align: center;
}
.secondColumnT {
  width: 40%;
  height: 100%;
}
.thirdColumnT {
  width: 40%;
  height: 100%;
}
.fourthColumnT {
  width: 40%;
  height: 100%;
}

.titleIcon {
  font: bold 16px/32px Helvetica;
}

.iconTable {
  height: 80px;
  width: 80px;
  object-fit: contain;
  margin: auto;
  padding: 0 0 0.5rem 0;
  transform: scale(1.5);
}

@media only screen and (max-width: 1400px) {
  .customRow {
    font: normal 14px Helvetica;
  }
}

@media only screen and (min-width: 2559px) {
  .customContainer {
    max-width: 1800px;
  }
}
