.customContainer {
    padding-left: 0;
    padding-right: 0;
}

.titleSection {
    position: absolute;
    width: auto;

    top: 20%;
    left: 11%;
}

.titleSection p {
    font: Bold 80px/50px Helvetica;
    width: 100%;
    text-transform: uppercase;
    color: white;
}

.imagesBanner {
    width: auto;
}

.imagesBanner img {
    width: 1510px;
    height: 450px;
    object-fit: cover;
    margin: 3rem 0;
}

.contentWrap {
    padding: 5rem 0 0 0;
}

.titleAccordion {

    font-size: bold 20px Helvetica;

    .btnSpan {
        transition-timing-function: linear;
        transition: all 1s linear 0.3s;
        font-size: 20px;
    }

}

.bannerTop {
    top: 0;
    left: 0;
    width: 100vw;
    height: 65vh;
}

.bannerTop img {
    width: 100vw;
    position: absolute;
    height: 65vh;
    //   filter: blur(2px);
    object-fit: cover;
}

.navBack {
    /* position: fixed;
    bottom: 5%;
    left: 4%; */
    position: relative;
    /* margin-left: -250px;
    margin-top: -165px;
    padding: 0px 0 5rem 0; */
    padding: 2rem 0 5rem 0;
    width: 30%;
}

.arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
}

.textBack {
    font:normal 16px/32px Helvetica;
    color: black;
    margin: 0;
}

.customMargin {
    margin: auto 1rem auto auto;
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.customLink {
    text-decoration: none;
}

.customLink:hover {
    text-decoration: none;
}

.componentViews {
    // margin-top: 2rem;
    // padding-bottom: 2rem;

    .groupAccordion {
        font: bold 16px Helvetica;

        .dateArcticle {
            margin: 1rem;
            width: 85px;
        }
    }

    .customAccordion > .customCard .cardHeader {
        border: 1px solid rgba(212, 212, 212, .6)
    }  
    
    // .customAccordion > .customCard:last-of-type > .cardHeader{
    //     border: 1px solid rgba(0,0,0, 1)
    // } 

    .cardHeader {
        font: bold 16px Helvetica;
        display: flex;
    }

    .dateArcticle {
        margin: 1rem;
        width: 85px;
    }

    .selectStyle {
        outline: transparent;
        border: none;
        background-color: transparent;
        font: 26px/20px Helvetica;
        text-transform: uppercase;
        padding-right: 2rem;
        margin-top: -1rem;
        width: auto;
        margin-right: 3rem;
    }

    .selectStyle option {
        font: normal 18px Helvetica;
    }

    .titleArticle {
        margin: 1rem;
    }

    .highlight {
        position: relative;
        display: flex;
        width: auto;

        width: fit-content;
        margin: 3rem 1rem 2rem 1rem;
    }

    .year {
        font: 1000 32px/20px Helvetica;
        width: 100%;
        text-transform: uppercase;
        margin-right: 2rem;
        opacity: 0.1;
        cursor: pointer;
    }

    .yearActive {
        opacity: 1 !important;
        font: 1000 32px/20px Helvetica;
        width: 100%;
        text-transform: uppercase;
        margin-right: 2rem;
        opacity: 0.1;
        cursor: pointer;

    }

    .titleTxt {

        font: bold 36px Helvetica;
        text-transform: uppercase;
        margin-bottom: 2rem;

    }

    .contentArtTxt {
        font: normal 16px/1.8rem Helvetica;
        height: 100%;
    }

    .imgLogo {
        margin: 0.5rem;
        border-left: solid 2px #ddd;
        padding-left: 1rem;
        width: 50px;
        height: 40px;
    }

    .title1 {
        font: bold 32px Helvetica;
        text-transform: uppercase;
    }

    .catAwards {
        font: bold 16px Helvetica;
        opacity: 0.5;
        margin-bottom: 2rem;
    }

    .titleAccor {
        border: 1px solid #ddd;
        padding: 1.2rem;

        font: 20px Helvetica;

    }

    .titleAccorContent {
        font: bold 16px Helvetica;
        color: black;
    }

    .textAccor {
        padding: 2rem 3rem 2rem 10rem;
        border: solid #f1f1f1;
        font: normal 16px/32px Helvetica;
        text-align: justify;
    }

    .accordion__section {
        display: flex;
        flex-direction: column;
    }

    /* Style the buttons that are used to open and close the accordion panel */
    .accordion {
        background-color: #eee;
        color: #444;
        cursor: pointer;
        padding: 18px;
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        transition: background-color 0.6s ease;
    }

    /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
    .accordion:hover,
    .active {
        background-color: #ccc;
    }

    /* Style the accordion content title */
    .accordion__title {
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        font-size: 14px;
    }

    /* Style the accordion chevron icon */
    .accordion__icon {
        margin-left: auto;
        transition: transform 0.6s ease;
    }

    /* Style to rotate icon when state is active */
    .rotate {
        transform: rotate(90deg);
    }

    /* Style the accordion content panel. Note: hidden by default */
    .accordion__content {
        background-color: white;
        overflow: hidden;
        transition: max-height 0.6s ease;
    }

    /* Style the accordion content text */
    .accordion__text {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-size: 14px;
        padding: 18px;
    }
}

@media only screen and (max-width:1366px) {
    .customContainer {
        max-width: 1062px !important;
    }

    .navBack {
        padding: 2rem 0 5rem 0;
    }
    .contentWrap {

        padding: 3.5rem 0 0 0;

        .componentViews {

            .titleTxt {
                font:bold 28px Helvetica;
                margin-bottom: 1.5rem;
            }
            .year {
                font:  1000 26px Helvetica;
            }
            .selectStyle {
                font: normal 18px helvetica;
            }

            .dateArcticle {
                min-width: 85px;
            }

        }

        .contentArtTxt {
            font: normal 14px Helvetica;
            line-height: 1.8;
        }
       
    }

    
}

@media only screen and (min-width: 2560px) {
    .customContainer {
        max-width: 2000px !important;
    }
}