/* GENERAL */
:root {
  --left-fixed: 224px;
}

/* SPECIFIC */
.backgroundchart {
  /* background-color: violet */
  width: 100%;
  margin-top: -7.4rem;
  transform: scale(0.9);
  /* height: 100vh; */
}

.wrappContent {
  margin-top: 0rem;
  width: 100%;
  height: 567px;
  position: relative;
  /* background-color: palegreen; */
}

.circleFake {
  position: absolute;
  /* margin: 18rem auto auto auto; */
  border: 2px solid #ddd;
  width: 356px;
  height: 360px;
  /* display: flex; */
  align-items: center;
  border-radius: 50%;
  top: 8px;
  left: 577px;
  z-index: 0;
}

.circle {
  margin: 18rem auto auto auto;
  border: 20px dotted #ddd;
  width: 375px;
  height: 375px;
  display: flex;
  align-items: center;
  border-radius: 50%;

  /* background-color: turquoise; */
}

.circleInner {
  margin: auto;
  width: 300px;
  height: 300px;
  display: flex;
  text-align: center;
  background-color: #ececec;
  align-items: center;
  border-radius: 50%;
  transition: 0.3s;
  box-shadow: -15px 13px 24px -8px rgba(140, 140, 140, 0.9);
}

.textInnerCircle {
  font: bold 20px Helvetica;
  margin: auto;
  text-transform: uppercase;
}

.chart {
  position: relative;
}

.boxRound {
  position: absolute;
  z-index: 4;
  display: flex;
  align-items: center;
  margin: auto auto auto auto;

  /* border: solid; */
  background-color: #eead34;
  width: 300px;
  height: 60px;
  border-radius: 40px;
  justify-content: flex-end;
  padding-right: 5px;
  right: 0;
  bottom: 0;
  animation: move 5s;
}
@keyframes move {
  from {
    bottom: 22%;
  }
  to {
    bottom: 0%;
  }
}

@-webkit-keyframes move {
  from {
    bottom: 22%;
  }
  to {
    bottom: 0%;
  }
}

.textInBox {
  font: normal 16px Helvetica;
}

.textInboxRound {
  font: normal 18px Helvetica;
  color: white;
  margin: auto;
  text-align: center;
  width: 190px;
}

.Line {
  position: absolute;
  width: 70px;
  height: 5px;
  background-color: #eead34;
  z-index: 2;
  transform: rotate(90deg);
  right: -1.5%;
  bottom: 36%;
}

.circleLine {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 20px;
  text-align: center;
  align-items: center;
  padding: 5px;
  border: solid 5px #eead34;
}

.circleLineIn {
  margin: auto;
  width: 20px;
  height: 20px;

  border-radius: 15px;
  border: solid 5px #eead34;
  text-align: center;
  align-items: center;
}

.circleLine2 {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 20px;
  text-align: center;
  align-items: center;
  padding: 5px;
  border: solid 5px #eead34;
  position: absolute;
  right: 6px;
  top: 34%;
  /* animation: move 5s infinite; */
}

.circleLineIn2 {
  margin: auto;
  width: 20px;
  height: 20px;

  border-radius: 15px;
  border: solid 5px #eead34;
  text-align: center;
  align-items: center;
}

.wrappContentBox {
  position: absolute;
  width: 600px;
  /* background-color: aliceblue; */
  height: 250px;
  left: 55px;
  top: 57%;
  z-index: 1;
}

.wrappContentBox:nth-child(2) {
  transform: translate(-232px, -290px);
  top: 76%;
  left: 123px;
}

.wrappContentBox:nth-child(2) .Line {
  transform: translate(83px, 78px) rotate(180deg);
  background-color: #f19924;
  right: 2.5%;
  bottom: 42%;
}

.wrappContentBox:nth-child(2) .circleLine2 {
  transform: translate(163px, 154px);
  border: solid 5px #ffaa3b;
  right: 55px;
  top: 19%;
}

.wrappContentBox:nth-child(2) .circleLineIn2 {
  border: solid 5px #f19924;
}

.wrappContentBox:nth-child(2) .boxRound {
  background-color: #f19924;
  right: 0;
  animation: move2 5s infinite;
  -webkit-animation: move2 5s;
}

@keyframes move2 {
  from {
    right: -18%;
  }
  to {
    right: 0%;
  }
}

@-webkit-keyframes move2 {
  ffrom {
    right: -18%;
  }
  to {
    right: 0%;
  }
}

.wrappContentBox:nth-child(2) .circleLine {
  border: solid 5px #f19924;
}

.wrappContentBox:nth-child(2) .circleLineIn {
  border: solid 5px #f19924;
}

.wrappContentBox:nth-child(3) .boxDescription {
  right: 15%;
}

.wrappContentBox:nth-child(3) {
  transform: translate(-179px, -478px);
  top: 85%;
  left: 119px;
}

.wrappContentBox:nth-child(3) .Line {
  transform: translate(83px, 78px) rotate(180deg);
  right: 2.5%;
  bottom: 42%;
}

.wrappContentBox:nth-child(3) .boxRound {
  background-color: #f18624;
  right: 0;
  animation: move3 5s;
  -webkit-animation: move3 5s;
}
@keyframes move3 {
  from {
    right: -18%;
  }
  to {
    right: 0%;
  }
}

@-webkit-keyframes move3 {
  ffrom {
    right: -18%;
  }
  to {
    right: 0%;
  }
}

.wrappContentBox:nth-child(3) .circleLine2 {
  border: solid 5px #f18624;
  transform: translate(163px, 154px);
  right: 56px;
  /* bottom: 42%; */
  top: 19%;
}

.wrappContentBox:nth-child(3) .circleLineIn2 {
  border: solid 5px #f18624;
}

.wrappContentBox:nth-child(3) .circleLine {
  border: solid 5px #f18624;
}

.wrappContentBox:nth-child(3) .circleLineIn {
  border: solid 5px #f18624;
}

.wrappContentBox:nth-child(3) .Line {
  background-color: #f18624;
}

.wrappContentBox:nth-child(4) {
  transform: translate(160px, -519px) scalex(-1) rotate(180deg);
  top: 102%;
  left: 10px;
}

.wrappContentBox:nth-child(4) .boxRound {
  background-color: #f16624;
}

.wrappContentBox:nth-child(4) .circleLine2 {
  border: solid 5px #f16624;
}

.wrappContentBox:nth-child(4) .circleLineIn2 {
  border: solid 5px #f16624;
}

.wrappContentBox:nth-child(4) .circleLine {
  border: solid 5px #f16624;
}

.wrappContentBox:nth-child(4) .circleLineIn {
  border: solid 5px #f16624;
}

.wrappContentBox:nth-child(4) .Line {
  background-color: #f16624;
}

.wrappContentBox:nth-child(4) p {
  transform: scaley(-1);
}

.wrappContentBox:nth-child(5) {
  transform: translate(893px, -441px) rotate(180deg);
  top: 92%;
  left: -51px;
}

.wrappContentBox:nth-child(5) .boxRound {
  background-color: #00599d;
}

.wrappContentBox:nth-child(5) .circleLine2 {
  border: solid 5px #00599d;
}

.wrappContentBox:nth-child(5) .circleLineIn2 {
  border: solid 5px #00599d;
}

.wrappContentBox:nth-child(5) .circleLine {
  border: solid 5px #00599d;
}

.wrappContentBox:nth-child(5) .circleLineIn {
  border: solid 5px #00599d;
}

.wrappContentBox:nth-child(5) .Line {
  background-color: #00599d;
}

.wrappContentBox:nth-child(5) p {
  transform: rotate(180deg);
}

.wrappContentBox:nth-child(6) {
  transform: translate(1120px, -350px) scaleX(-1);
  top: 75%;
  left: -104px;
}

.wrappContentBox:nth-child(6) .boxRound {
  background-color: #004e96;
  right: 0;
  animation: move4 5s;
  -webkit-animation: move4 5s;
}
@keyframes move4 {
  from {
    right: -18%;
  }
  to {
    right: 0%;
  }
}
@-webkit-keyframes move4 {
  ffrom {
    right: -18%;
  }
  to {
    right: 0%;
  }
}

.wrappContentBox:nth-child(6) .circleLine2 {
  border: solid 5px #004e96;
  transform: translate(163px, 154px);
  right: 55px;
  top: 19%;
}

.wrappContentBox:nth-child(6) .circleLineIn2 {
  border: solid 5px #004e96;
}

.wrappContentBox:nth-child(6) .circleLine {
  border: solid 5px #004e96;
}

.wrappContentBox:nth-child(6) .circleLineIn {
  border: solid 5px #004e96;
}

.wrappContentBox:nth-child(6) p {
  transform: scaleX(-1);
}

.wrappContentBox:nth-child(6) .Line {
  transform: translate(83px, 78px) rotate(180deg);
  background-color: #004e96;
  right: 2.5%;
  bottom: 42%;
}

.wrappContentBox:nth-child(7) {
  transform: translate(1035px, -144px) scaleX(-1);
  top: 60%;
  left: -58px;
}

.wrappContentBox:nth-child(7) .boxRound {
  background-color: #03428e;
  animation: move5 5s;
  -webkit-animation: move5 5s;
}
@keyframes move5 {
  from {
    right: -18%;
  }
  to {
    right: 0%;
  }
}
@-webkit-keyframes move5 {
  ffrom {
    right: -18%;
  }
  to {
    right: 0%;
  }
}

.wrappContentBox:nth-child(7) .circleLine2 {
  border: solid 5px #03428e;
  right: 56px;
  top: 18.5%;
}

.wrappContentBox:nth-child(7) .circleLineIn2 {
  border: solid 5px #03428e;
}

.wrappContentBox:nth-child(7) .circleLine {
  border: solid 5px #03428e;
}

.wrappContentBox:nth-child(7) .circleLineIn {
  border: solid 5px #03428e;
}

.wrappContentBox:nth-child(7) .Line {
  transform: translate(83px, 78px) rotate(180deg);
  background-color: #03428e;
  right: 2.5%;
  bottom: 42%;
}

.wrappContentBox:nth-child(7) p {
  transform: scaleX(-1);
}

.wrappContentBox:nth-child(7) .circleLine2 {
  transform: translate(163px, 154px);
}

.wrappContentBox:nth-child(8) {
  transform: translate(729px, 68px) scaleX(-1);
  left: 31px;
  top: 51%;
}

.wrappContentBox:nth-child(8) .boxRound {
  background-color: #223771;
}

.wrappContentBox:nth-child(8) .circleLine2 {
  border: solid 5px #223771;
}

.wrappContentBox:nth-child(8) .circleLineIn2 {
  border: solid 5px #223771;
}

.wrappContentBox:nth-child(8) .circleLine {
  border: solid 5px #223771;
}

.wrappContentBox:nth-child(8) .circleLineIn {
  border: solid 5px #223771;
}

.wrappContentBox:nth-child(8) .Line {
  background-color: #223871;
}

.wrappContentBox:nth-child(8) p {
  transform: scaleX(-1);
}

.boxDescription {
  position: absolute;
  z-index: 1;
  background-color: #f1f1f1;
  width: 350px;
  height: 92px;
  height: 130px;
  bottom: 55px;
  right: 10%;
  padding: 1rem;
  text-align: justify;
}

.boxC {
  max-width: 1540px;
}

@media only screen and (min-width: 2560px) {
  .boxC {
    max-width: 2090px !important;
  }

  .circle {
    margin-right: 51.9rem;
  }
  .flexContent {
    margin-top: 2%;
  }

  .backgroundchart {
    margin-top: -7.4rem;
  }

  .chart {
    margin-left: 18rem;
  }
}

@media only screen and (max-width: 1400px) {
  .circleFake {
    left: calc(577px - var(--left-fixed));
  }

  .wrappContentBox {
    left: calc(55px - var(--left-fixed));
  }

  .wrappContentBox:nth-child(2) {
    left: calc(123px - var(--left-fixed));
  }

  .wrappContentBox:nth-child(3) {
    left: calc(119px - var(--left-fixed));
  }

  .wrappContentBox:nth-child(4) {
    left: calc(10px - var(--left-fixed));
  }

  .wrappContentBox:nth-child(5) {
    left: calc(-51px - var(--left-fixed));
  }

  .wrappContentBox:nth-child(6) {
    left: calc(-104px - var(--left-fixed));
  }

  .wrappContentBox:nth-child(7) {
    left: calc(-58px - var(--left-fixed));
  }

  .wrappContentBox:nth-child(8) {
    left: calc(31px - var(--left-fixed));
  }

  .backgroundchart {
    transform: scale(0.7);
    margin-top: -12.5rem;
  }

}
