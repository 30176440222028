/* HEADER */
.headMapContainer {
  height: 400px;
  width: 100%;
}

.frontMap {
  height: 400px;
  width: 100%;
  position: absolute;
  z-index: 1;
  background-color: black;
  opacity: 0.67;
}

.headMapImg {
  position: absolute;
  z-index: 0;
  height: 400px;
  width: 100%;
  object-fit: cover;
}

/* ROW SECTION */
.heading {
  font: bold 36px Helvetica;
  margin-bottom: 0 !important;
  color: black;
  text-transform: uppercase;
}

/* SEND MESSAGE SECTION */
.sendMessageSection {
  max-width: 625px;
}
.input1 {
  display: block;
  width: 100%;
  height: 5vh;
  margin-top: 2rem;
  padding-left: 24px;
  font: 18px Helvetica;
  border: 1px solid #c8c8c8;
}

.input {
  display: block;
  width: 100%;
  height: 5vh;
  margin-top: 16px;
  padding-left: 24px;
  font: 18px Helvetica;
  border: 1px solid #c8c8c8;
}

.bigInput {
  display: block;
  width: 100%;
  margin-top: 16px;
  height: 168px;
  padding: 16px 24px;
  font: 18px Helvetica;
  border: 1px solid #c8c8c8;
}

.alertBox {
  display: flex;
  height: 40px;
  background-color: #ca0000;
  margin-top: 8px;
}

.alertBoxText {
  color: white;
  font: 16px Helvetica;
  align-self: center;
  justify-content: center;
  text-align: center;
  margin: auto auto auto 1.6rem;
}

.btnSend {
  color: white;
  background-color: #223870;
  height: 56px;
  font: 26px Helvetica;
  padding: 0 80px;
  margin-top: 24px;
  float: right;
  border-radius: 50px;
}

/* CONTACT SECTION */
.companyName {
  font: bold 30px Helvetica;
  color: #223771;
  text-transform: uppercase;
  margin-top: 2rem;
  margin-bottom: 0 !important;
}

.officeDiv {
  margin-top: 1rem;
}

.factoryDiv {
  margin-top: 1rem;
}

.smallAnchor {
  font: 20px Helvetica;
  color: #f16b26;
  text-transform: uppercase;
  margin: 2rem 0 0 0 !important;
}

.icon {
  margin-left: 11px;
}

.address {
  margin: 1rem 0 0 0 !important;
  font: bold 16px Helvetica;
}

.thirdDiv {
  display: flex;
  margin-top: 4rem;
}

.QRCode {
  height: 187px;
  width: 187px;
  border: 1px solid #707070;
  background-color: #afafaf;
}

.imgQR {
  height: 185px;
  width: 185px;
  object-fit: cover;
}

.emailPhoneFaxContainer {
  margin-left: 3rem;
}

.label {
  font: 16px Helvetica;
  margin: 33px 0 0 0;
  color: #696969;
}

.label.top {
  margin: 0;
}

.text {
  color: black;
  font: 16px Helvetica;
  margin: 1rem 0;
}

.text.email {
  color: #223771;
}

.mWid {
  max-width: 1520px !important;
  padding: 5rem 0;
}

.prBox {
  height: 100%;
  width: 100vw;
}

@media only screen and (min-width: 2560px) {
  .mWid {
    max-width: 2090px !important;
  }

  .sendMessageSection {
    max-width: 885px;
  }

  .input1 {
    border: 1px solid #afafaf !important;
  }

  .input {
    margin-top: 2rem;
    border: 1px solid #afafaf !important;
  }

  .bigInput {
    margin-top: 2rem;
    border: 1px solid #afafaf !important;
  }

  .alertBox {
    height: 5vh;
  }

  .btnSend {
    height: 5vh;
  }
}

@media only screen and (max-width: 1400px) {
  .mWid {
    max-width: 1062px !important;
    padding: 3.5rem 0;
  }

  .heading {
    font: bold 28px Helvetica;
  }

  .input1 {
    margin-top: 1.5rem;
  }

  .input1,
  .input,
  .bigInput {
    font: 14px Helvetica;
  }

  .companyName {
    font: bold 22px Helvetica;
    margin-top: 1.5rem;
  }

  .smallAnchor {
    font: 16px Helvetica;
    margin: 1rem 0 0 0 !important;
  }

  .address {
    font: bold 14px Helvetica;
  }

  .label,
  .text {
    font: 14px Helvetica;
  }

  .btnSend {
    font: 18px Helvetica;
    height: 45px;
    margin-top: 1rem;
  }

  .thirdDiv {
    margin-top: 3rem;
  }
}
