p.date {
  color: #f16b26;
}
div.sectionPreview {
  position: absolute;
  top: 12%;
  left: 40rem;
  width: 50vw;
}

p.title {
  color: #f16b26;
  text-transform: uppercase;
  font: 16px/32px Helvetica;
  width: 100%;
  cursor: pointer;
}

p.subTitle {
  color: white;
  text-transform: uppercase;
  font: 16px/32px Helvetica;
}

p.contentLink {
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}

p.contentLink:after {
  content: " \2192";
  color: #f16b26;
}

p.content {
  color: white;
  font-family: "Helvetica";
  text-transform: uppercase;
}

p.content-custom {
  color: white;
  font-family: "Helvetica";
}

p.content:after,
p.content-custom:after {
  content: " \2192";
  color: white;
}

p.content:hover:after,
p.content-custom:hover:after {
  color: #f16b26;
}

/*  */

p.content-d {
  color: white;
  font-weight: bold;
  font-family: "Helvetica";
  text-transform: uppercase;
}

p.contentCustomD {
  color: white;
  font-weight: bold;
  /* font-family: 'Helvetica'; */
  font: 16px/32px Helvetica;
  width: 100%;
  cursor: pointer;
}

p.content-d:after {
  content: " \2192";
  color: #f16b26;
}

p.content-d:hover:after,
p.contentCustomD:hover:after {
  content: " \2192";
  color: #f16b26;
}

p.subTitleD {
  color: #fff;
  text-transform: uppercase;
  opacity: 1;
  font: 16px/32px Helvetica;
  cursor: pointer;
}

p.subTitleD:hover::after {
  content: " \2192";
  color: #f16b26;
}

p.contentLink-d {
  color: black;
  text-transform: uppercase;
  font-weight: bold;
}

p.contentLink-d:after {
  content: " \2192";
  color: #f16b26;
}

p.content-ds {
  color: black !important;
  font-family: "Segoe UI";
}

a.customLink {
  /* font: 16px/32px Helvetica; */
  font-size: 16px !important;
  font-family: Helvetica;
  letter-spacing: normal !important;
}
/*  */

.custom-arrow {
  position: relative;
  height: 0px;
  width: 0px;
  border: 3px solid;
  border-color: white white transparent transparent;
  transform: rotate(45deg);
}

.custom-arrow:before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  height: 3px;
  width: 6px;
  background-color: white;
  transform: rotate(-45deg) translate(2px, 1px);
}

.cardPreviewBody {
  border: none;
  background-color: transparent;
}

.cardPreviewBody p.date {
  color: #f16b26;
}

p.title-disable {
  color: #707070;
}

p.default {
  color: black;
  text-transform: uppercase;
}

p.default:after {
  content: " \2192";
  color: #f16b26;
}

.square {
  height: 220px;
  width: 320px;
  background-color: #fff;
}

.square-d {
  height: 150px;
  width: 250px;
  background-color: #ececec;
}

.box-preview {
  padding: 20px;
  height: auto;
  width: 100%;
  filter: blur(3px);
  border: 0.5px solid white;
  /* box-shadow: 5px 5px 5px 5px white; */
}
/* a.custom-link p {
	font: 14px/28px Helvetica;
} */

@media only screen and (max-width: 1400px) {
  div.sectionPreview {
    top: 15%;
    left: 24rem;
  }

  p.title,
  p.subTitle p.contentCustomD,
  p.subTitleD {
    font: 14px/32px Helvetica;
  }
}
