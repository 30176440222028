.customContainer {
    padding: 5rem 0;
    height: 100%;
    width: 100%;
}

.boxNav {
    display: flex;
    margin-bottom: 1rem;
}

.customLink {
    text-decoration: none;
}

.customLink:hover {
    text-decoration: none;
}

.backButton {
    font: 12px/16px Helvetica;
    color: #000000;
    margin: 0 0 0 1rem;
}

.category {
    font: 18px/32px Helvetica;
    color: #000000;
}

.title {
    text-align: left;
    font: bold 24px/32px Helvetica;
    letter-spacing: -0.24px;
    color: #000000;
    text-transform: uppercase;
}

.boxImage {
    width: 100%;
    height: 504px;
}

.contentImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.titleContent {
    font: bold 26px/31px Helvetica;
    color: #000000;
}

.content {
    font: 16px/32px Helvetica;
    color: #000000;
}

.boxLeft {
    padding: 2rem 5rem 0 0;
    width: 60vw;
}

.boxRight {
    width: calc((100% - 624px) + 10vw);
    height: 464px;
    background-color: transparent;
    display: block;
    position: relative;
}

.boxContentOverflow {
    padding: 2rem;
    width: 624px;
    height: 464px;
    background-color: #D4D4D4;
    margin-top: -4rem;
    /* box-shadow: 0px 6px 16px #00000073; */
    overflow-y: hidden;
    text-align: justify;
}

.contentDesc {
    font: 16px/32px Helvetica;
    color: #000000;
    text-align: justify;
}

.boxContent ul>li {
    font: 16px/32px Helvetica;
    margin-bottom: 1rem;
    color: #000000;
}

.sectionContent {
    font: 16px/32px Helvetica;
    text-align: justify;
}

.sectionContent ul{
    margin-top: 1rem;
}
.sectionContent strong {
    display: block;
    margin-bottom: 1rem;
    font: 20px/24px Helvetica;
    color: #000000;
}

.sectionContent div {
    margin-top: 2rem;
}

.section2 {
    margin: 2rem 0;
}

.s2_boxLeft {
    width: 30%;
    height: 100%;
    background-color: #D4D4D4;
}

.s2_boxRight {
    width: 60%;
}

.s2_boxLeft div, .s2_boxRight div {
    padding: 2rem;
    margin: 0;
    text-align: justify;
}

.section3 {
    margin: 2rem 0;
}

