.contentDetail {
  width: 100vw;
  height: 100%;
  background-color: #f1f1f1;
  position: relative;
}

p.sectionTitle {
  font: Bold 36px/48px Helvetica;
}

p.title {
  font: Bold 36px Helvetica;
}

p.content {
  font: 18px/32px Helvetica;
}

.boxParent {
  height: 512px;
  width: 100%;
  display: block;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: row;
}

.boxParentXs {
  /* height: 50vh; */
  display: block;
  position: relative;
  padding: 2rem 0 3rem 0;
}

.boxContentParentXs {
  /* height: 35vh; */
  background-color: transparent;
  display: flex;
  flex-direction: row;
}

.boxContentDetail {
  position: relative;
  /* width: 31.4rem;
    height: 35vh; */
  width: 480px;
  height: 288px;
  background-color: transparent;
}

.boxContentDetail:hover .boxContentBehind {
  box-shadow: 0px 2px 10px rgb(0, 0, 0, 0.5);
}

.contentImage {
  width: 480px;
  height: 288px;
  object-fit: cover;
}

.contentImageD {
  width: 480px;
  height: 288px;
  object-fit: contain;
}

.boxContents {
  position: relative;
  /* width: 31.4rem;
    height: 35vh; */
  width: 480px;
  height: 288px;
  background-color: transparent;
  display: flex;
  /* background-color: #F2F2F2; */
  background-color: rgb(0, 0, 0, 0.1);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.boxContentDesc {
  height: auto;
  min-height: 512px;
  width: 100%;
  min-width: 700px;
}

.boxLinks {
  position: relative;
  margin: auto 0;
  text-align: left;
}
.outerCircle2 {
  padding: 5px;
  /* background-color: rgb(45, 57, 69, 0.5); */
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  margin: 0 1rem 0 auto;
  align-self: left;
  opacity: 0;
  position: absolute;
  /* top: 1px; */
  /* left: 42%; */
}
.readMore {
  cursor: pointer;
  color: white;
  font: normal 16px Helvetica;
}

.innerCircle2 {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.boxLinks:hover .outerCircle2 {
  opacity: 1;
}

.boxLinks:hover .readMore {
  margin-left: 1.5rem;
}

.boxContentBehind {
  background-color: rgb(0, 0, 0, 0.5);
  /* width: 31.4rem;
    height: 35vh; */
  width: 480px;
  height: 288px;
  position: absolute;
  opacity: 0;
  backdrop-filter: blur(5px);
  cursor: pointer;
}

.contentBehindBox {
  color: white;
  font-weight: bold;
  /* width: 31.4rem;
    height: 35vh; */
  width: 480px;
  height: 288px;
  position: absolute;
  top: 46%;
  left: 30%;
  opacity: 0;
  cursor: pointer;
}

.boxChild {
  /* width: 35vw; */
  /* height: 512px; */
  width: 700px;
  height: 100%;

  background-color: #f2f2f2;
}

.imgLeft {
  background-color: transparent;
  position: absolute;
  top: 10rem;
  right: 0;
}

.imgOverflow {
  width: 904px;
  height: 512px;
  object-fit: cover;
}

.imgOverflowD {
  width: 904px;
  height: 512px;
  object-fit: contain;
}

.contentBoxChild {
  padding: 2rem 3rem 2rem 3rem;
  width: 700px;
  height: 100%;
}

.mask {
  background: radial-gradient(circle 20px at var(--x, 0) var(--y, 0), #fff 99%, black 100%) fixed;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.contentBoxChild p {
  font-family: Helvetica;
  font-size: 18px;
}

.boxContentDetail:hover .boxContentBehind,
.boxContentDetail:hover .boxContentBehind .contentBehindBox {
  opacity: 1;
}

.boxContentDetail:hover .imgUpload {
  animation: moveleft 0.5s;
  margin-right: 1rem;
}

.boxContentDetail:hover .imgUpload path {
  fill: red;
}

.boxContentDetail:hover .textUpload {
  animation: showUp 0.5s;
  opacity: 1;
}

.textUpload {
  align-self: center;
  color: #223771;
  opacity: 0;
  font: bold 24px/48px Helvetica;
}

.imgUpload {
  align-self: center;
  margin-right: -4.5rem;
}

.link {
  text-decoration: none !important;
}

@keyframes moveleft {
  0% {
    margin-right: -4.5rem;
  }

  100% {
    margin-right: 1rem;
  }
}

@keyframes showUp {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* MEDIA QUERY */

@media only screen and (max-width: 1400px) {
  .boxContents,
  .boxContentDetail,
  .contentImage,
  .contentImageD {
    width: 400px;
    height: 288px;
  }
  .imgOverflow,
  .imgOverflowD {
    width: 704px;
    height: 512px;
  }
  p.sectionTitle {
    font: Bold 32px/50px Helvetica;
    margin-left: 130px;
  }
  .contentBoxChild p {
    font-size: 15px;
  }
  .contentBehindBox {
    top: 43%;
    left: 30%;
    font-size: revert;
  }
  .boxContentBehind {
    width: 400px;
  }
  .contentBoxChild {
    padding: 2rem 3rem 2rem 8rem;
  }
  .imgLeft {
    top: 7rem;
  }
}

@media only screen and (max-width: 360px) and (max-height: 760px) {
  p.sectionTitle {
    font: Bold 22px Helvetica;
    margin-left: 1rem;
  }
}
