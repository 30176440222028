.wraperr {
  width: 100%;
  height: 100vh;
  position: relative;
}

.slide-sec1 {
  justify-content: center;
}

.partikel {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 60vh;
}
.ft-slick__dots--custom {
  height: 6px;
  width: 36px;
  background-color: #f2f2f2;
  position: relative;
  margin-top: 1rem;
}

.ft-slick__dots--custom .loading {
  height: 6px;
  animation: loading 1s ease-in;
  background-image: teal;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
}

.slick-dots li {
  width: 36px;
}

.card-sec1 .slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  right: 4rem;
}

li.slick-active div {
  background-color: #f16b26;
}
/* .slick-dots .slick-active {
  background-color: coral;
} */

.row_1 {
  position: absolute;
  justify-content: center;
  margin: none !important;
  margin-right: 0px;
  margin-left: 0px;
  width: 100%;
  z-index: 1;
  color: #fff !important;
  font-size: 36px;
  text-align: center;
  padding-top: 5%;
}
.pd-0 {
  max-width: 1520px !important;
  padding: 8.8rem 0;
}
.title_tap_doan {
  font-size: 30px;
  color: black;
  /* margin: auto; */
  /* position: absolute; */
  /* left: %; */
  text-align: center;
  /* top: 20%; */
  font-family: Helvetica;
}

.card_row1 {
  background-color: teal;
  border-color: transparent !important;
}

.row_2 {
  position: absolute;
  top: 35%;
  left: 11%;
  margin-bottom: 10px;
  box-shadow: 0 17px 16px rgba(0, 0, 0, 0.4);
  background-color: #223771;
}

.card-sec1 {
  width: 78vw;
  justify-content: center;
  height: 528px;
  background-color: #223771;
  border-color: white;
}

.text-card-sec1 {
  position: absolute !important;
  padding: 10px;
  background-color: #223771;
  color: white !important;
  font-family: Helvetica;
  margin: 50px;
}

.cardSec1 {
  width: 250px;
  background-color: #223771;
  /* box-shadow: 4px 4px 8px 0 rgba(0,0,0,0.2); */
}

.titleSec5 {
  color: black;
  font: bold 24px/60px Helvetica !important;
  /* font-weight: bold;
    font-family: Helvetica;
    font-size: 52px !important; */
}

.contentSec5 {
  font: 16px/32px Helvetica;
}

.text-midle-sec1 {
  color: black;
  font: bold 34px Helvetica;
  text-align: center;
  position: relative;
  line-height: 3rem;
  margin-bottom: 0 !important;
}

.textLogo {
  height: 23px;
  width: auto;
  position: absolute;
  top: 0.65rem;
  padding: 0 0 0 0.7rem;
}

.card-body-sec1 {
  padding: 0;
  margin: auto;
}

.cardImgSec1 {
  filter: blur(8px);
}

.img_sec_1 {
  height: 460px;
}

.logo_drag {
  position: absolute;
  z-index: 5;
  left: 44%;
  bottom: 10%;
}

.content-section_1 {
  background-color: #f1f1f1;
  height: 30vh;
}

.cursor {
  background-color: transparent;
}

.boxAll {
  width: 100vw;
  height: 20vh;
  position: relative;
}

@media only screen and (min-width: 2560px) {
  .wraperr {
    height: 60vh;
  }
  .pd-0 {
    padding: 7.4rem 0;
    max-width: 2000px !important;
  }
}

@media only screen and (max-width: 1400px) {
  .wraperr {
    width: 100%;
    height: 720px;
    position: relative;
  }

  .card-sec1 {
    height: 345px;
    max-width: 1008px;
    /* width: calc(100vw - 370px); */
    margin: auto;
  }

  .row_2 {
    left: 180px !important;
    top: 36%;
  }

  .text-midle-sec1 {
    font: bold 23px Helvetica;
    line-height: 28px;
  }

  .pd-0 {
    max-width: 1062px !important;
    padding: 6.3rem 1rem;
  }

  .title_tap_doan {
    font-size: 18px;
  }
  .card-sec1 .slick-dots {
    right: 0;
  }
}
