.navLinks {
  font: normal 16px Helvetica;
  color: bold black;
}

.selected {
  color: #f16b26 !important;
}

@media only screen and (max-width: 1400px) {
  .navLinks {
    font-size: 14px;
  }
}
