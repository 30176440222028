.wrapper {
  width: 100vw;
}
.main {
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
}

.images_slide {
  width: 960px !important;
  height: 1080px;
  /* background-color: tomato; */
  object-fit: cover;
}

.box {
  position: relative;
  display: flex;
}
.boxContent {
  width: 50%;
  height: 1080px;
  /* background-color: red */
}
.boxContent2 {
  width: 50%;
  height: 1080px;
  background-color: #f1f1f1;
}

.boxImage {
  width: 100%;
  margin-top: 1rem;

  /* height: 1080px;
  cursor: url("./assets/drag.svg") 39 39, auto;
  background-color: #f1f1f1; */
  /* background-color: blue; */
}
._img {
  width: 50vw;
  height: 50vh;
  object-fit: cover;
}
.pad {
  margin: 2rem auto;
}
.txt2 {
  font: normal 16px Helvetica;
  color: black;
  text-align: center;
}

._boxmain1 {
  width: auto;
  /* height: 60vh; */
  /* background-color: tomato; */
  padding: auto;
  /* margin: 5rem auto 2rem auto; */
  display: flex;
  flex-direction: column;
}

._img1 {
  width: 50vw;
  height: 50vh;
  object-fit: contain;
}

._boxmain {
  width: auto;
  /* height: 60vh; */
  /* background-color: tomato; */
  padding: auto;
  /* margin: 5rem auto 2rem auto; */
  display: flex;
  flex-direction: column;
}

._img_slide {
  width: 222px;
  height: 129px;
  object-fit: cover;
  cursor: url("./assets/drag.svg") 39 39, auto;
}

._box3 {
  width: auto;
  height: 20vh;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
}

._box2 {
  width: 222px;
  height: 129px;
  margin: auto 0.3rem;
}

.padd2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

._abs {
  position: relative;
  padding: auto 2rem;
}

.news_title {
  position: relative;
  display: flex;
  padding: 1rem 7rem 1rem 10rem;
  text-transform: uppercase;
  align-self: flex-end;
}
.news {
  padding: 5rem 7rem 1rem 10rem;
  font: 18px Helvetica;
}
.judul {
  padding: 1rem 7rem 1rem 10rem;
  font: bold 24px Helvetica;
}

.link {
  cursor: pointer;
  color: black;
}

.tgl {
  padding: 1rem 7rem 1rem 10rem;
}
._next {
  width: auto;
  height: 7vh;
  /* background-color: tomato; */
  text-align: right;
  margin-right: 2rem;
}

p._next {
  color: black;
  font: normal 22px Helvetica;
}

._btnBtm {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

._marg {
  margin: auto;
}

.isi {
  padding: 1rem 7rem 1rem 10rem;
  text-align: justify;
}

.img_box {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}
.wrappbtnback {
  width: 50vw;
  height: 15vh;
  background-color: white;
  position: relative;
}

.wrappbtnback2 {
  width: 50vw;
  height: 15vh;
  background-color: #f1f1f1;
  position: relative;
}
.box_scroll {
  height: 880px;

  /* padding: 110px 0px 20px 20px; */
}

.scroll_content {
  /* margin-top: 2rem; */
  height: 720px;
  overflow: auto;
  cursor: url("./assets/scrolldark.svg") 39 39, auto;
}
.box_togle {
  display: flex;
  padding: 2rem 7rem 1rem 10rem;
}
.images_goback {
  padding: 2rem 2rem 1rem 10rem;
  display: flex;
  margin-top: -3%;
  position: absolute;
  /* top: 20%; */
  bottom: 2%;
  /* width: 500vw; */
  /* height: 100px; */
  background-color: white;
}
.images_goback2 {
  padding: 2rem 2rem 1rem 10rem;
  display: flex;
  margin-top: -3%;
  position: absolute;
  /* top: 20%; */
  bottom: 2%;
  /* width: 500vw; */
  /* height: 100px; */
  background-color: #f1f1f1;
}
.share {
  /* z-index: 200; */
  position: absolute;
  display: flex;
  width: 200px;
  /* right: 48%; */
  right: 8%;
  /* left: 37%; */
  /* top: 16%; */
  font-family: Helvetica;
  bottom: 10%;
}

@media only screen and (max-width: 1400px) {
  .images_slide {
    width: 720px !important;
    height: 760px;
  }
}
.wrapper {
  width: 100vw;
}
.main {
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
}

.images_slide {
  width: 960px !important;
  height: 1080px;
  /* background-color: tomato; */
  object-fit: cover;
}

.box {
  position: relative;
  display: flex;
}
.boxContent {
  width: 50%;
  height: 1080px;
  /* background-color: red */
}
.boxContent2 {
  width: 50%;
  height: 1080px;
  background-color: #f1f1f1;
}

.boxImage {
  width: 100%;
  margin-top: 1rem;

  /* height: 1080px;
  cursor: url("./assets/drag.svg") 39 39, auto;
  background-color: #f1f1f1; */
  /* background-color: blue; */
}
._img {
  width: 50vw;
  height: 50vh;
  object-fit: cover;
}
.pad {
  margin: 2rem auto;
}
.txt2 {
  font: normal 16px Helvetica;
  color: black;
  text-align: center;
}

._boxmain1 {
  width: auto;
  /* height: 60vh; */
  /* background-color: tomato; */
  padding: auto;
  /* margin: 5rem auto 2rem auto; */
  display: flex;
  flex-direction: column;
}

._img1 {
  width: 50vw;
  height: 50vh;
  object-fit: contain;
}

._boxmain {
  width: auto;
  /* height: 60vh; */
  /* background-color: tomato; */
  padding: auto;
  /* margin: 5rem auto 2rem auto; */
  display: flex;
  flex-direction: column;
}

._img_slide {
  width: 222px;
  height: 129px;
  object-fit: cover;
  cursor: url("./assets/drag.svg") 39 39, auto;
}

._box3 {
  width: auto;
  height: 20vh;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
}

._box2 {
  width: 222px;
  height: 129px;
  margin: auto 0.3rem;
}

.padd2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

._abs {
  position: relative;
  padding: auto 2rem;
}

.news_title {
  position: relative;
  display: flex;
  padding: 1rem 7rem 1rem 10rem;
  text-transform: uppercase;
  align-self: flex-end;
}
.news {
  padding: 5rem 7rem 1rem 10rem;
  font: 18px Helvetica;
}
.judul {
  padding: 1rem 7rem 1rem 10rem;
  font: bold 24px Helvetica;
}

.link {
  cursor: pointer;
  color: black;
}

.tgl {
  padding: 1rem 7rem 1rem 10rem;
}
._next {
  width: auto;
  height: 7vh;
  /* background-color: tomato; */
  text-align: right;
  margin-right: 2rem;
}

p._next {
  color: black;
  font: normal 22px Helvetica;
}

._btnBtm {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

._marg {
  margin: auto;
}

.isi {
  padding: 1rem 7rem 1rem 10rem;
  text-align: justify;
}

.img_box {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}
.wrappbtnback {
  width: 50vw;
  height: 15vh;
  background-color: white;
  position: relative;
}

.wrappbtnback2 {
  width: 50vw;
  height: 15vh;
  background-color: #f1f1f1;
  position: relative;
}
.box_scroll {
  height: 880px;

  /* padding: 110px 0px 20px 20px; */
}

.scroll_content {
  /* margin-top: 2rem; */
  height: 720px;
  overflow: auto;
  cursor: url("./assets/scrolldark.svg") 39 39, auto;
}
.box_togle {
  display: flex;
  padding: 2rem 7rem 1rem 10rem;
}
.images_goback {
  padding: 2rem 2rem 1rem 10rem;
  display: flex;
  margin-top: -3%;
  position: absolute;
  /* top: 20%; */
  bottom: 2%;
  /* width: 500vw; */
  /* height: 100px; */
  background-color: white;
}
.images_goback2 {
  padding: 2rem 2rem 1rem 10rem;
  display: flex;
  margin-top: -3%;
  position: absolute;
  /* top: 20%; */
  bottom: 2%;
  /* width: 500vw; */
  /* height: 100px; */
  background-color: #f1f1f1;
}
.share {
  /* z-index: 200; */
  position: absolute;
  display: flex;
  width: 200px;
  /* right: 48%; */
  right: 8%;
  /* left: 37%; */
  /* top: 16%; */
  font-family: Helvetica;
  bottom: 10%;
}

@media only screen and (max-width: 1400px) {
  .images_slide {
    width: 720px !important;
    height: 760px;
  }
}
