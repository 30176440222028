.main {
    min-height: 100vh;
    background: white;
    position: relative;
    z-index: 1;
    box-shadow: 0px 3px 6px #00000029;
}



.bannerTop {
    top: 0px;
    left: 0px;
    min-width: 1920px;
    width: auto;
    min-height: 504px;
    background: url(../../assets/AboutUs/banner.png) 0% 0% no-repeat padding-box;
    /* background: gray 0% 0% no-repeat padding-box; */
    opacity: 1;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(6px);
    display: flex;
}



/* .bannerTop {
    top: 0;
    left: 0;
    width: 100vh;
    min-height: 504px;
    background: url("./../../assets/image/banner.png") 0 0 no-repeat padding-box;
    background-position: center;
    background: gray 0 0 no-repeat padding-box;
    opacity: 1;
  
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(6px);
} */

/* .bannerTop .contentInsideImage{
    min-height: 504px;
} */

.contentBannerTop {
    position: absolute;
    top: 0;
    left: 0;
    width: 200vh;
    height: 504px;
    background-color: rgb(0, 0, 0, 0.1);
    background-position: center;
    opacity: 1;
    backdrop-filter: blur(10px);
}

.contentInsideImage {
    position: absolute;
    z-index: 100;
    text-align: center;
    top: 50%;
    left: 26%;
}

.contentInsideImage h1 {
    color: white;
    font: Bold 60px/49px Helvetica;
}

.paralaxFooter {
    position: absolute;
}

.ListPageRight {
    
        position: fixed;
        right: 4rem;
        top: 44.3vh;
        z-index: 99;
    
}