.customLink {
  letter-spacing: unset !important;
}

.iconExpand {
  text-align: center;
  justify-items: center;
  margin: -0.8rem 1rem;
  height: 20px;
  width: 20px;
}


.iconExpandSafari {
    text-align: center;
    justify-items: center;
    margin: -1.27rem 1rem;
    height: 20px;
    width: 20px;
}

.iconLink {
  margin: 1rem 0 0 2rem;
  padding: 0 0 8px 0;
}

.iconLink .icon {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 20px;
  cursor: pointer;
}

p.contentLinkMenu {
  font: 26px/32px Helvetica;
  color: white;
}

.contentItem {
  color: white;
  font-family: "Helvetica";
}

.contentItem:hover {
  color: #f16b26;
}

.contentItem:hover > div.expandList {
  background-color: #223771 !important;
}

.contentItem:hover > div.iconExpand > button.expandList:before {
  transform: rotate(90deg);
  background: white;
}

.contentItem:hover > div.iconExpand > button.expandList:after {
  transform: rotate(180deg);
  background: white;
}

.contentItem:hover > div.iconLink > div > div.arrow,
.contentItem:hover > div.iconLink > div > div.arrow:before,
.contentItem:hover > div.iconLink > div > div.arrow:after {
  background-color: #fff;
}

@media only screen and (max-width: 1400px) {
  p.contentLinkMenu {
    font: 18px/32px Helvetica;
    margin-bottom: 0;
  }

  .contentItem {
    /* max-width: 400px; */
  }

  .iconLink .icon {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 10px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 420px) {
  p.contentLinkMenu {
    font: 14px/32px Helvetica;
  }

  .iconExpand {
    margin: -0.3rem 1rem;
    height: 5px;
    width: 5px;
  }

  .iconLink .icon {
    width: 25px;
    height: 15px;
  }
}

@media only screen and (max-width: 360px) {
  p.contentLinkMenu {
    font: 14px/32px Helvetica;
  }

  .iconExpand {
    margin: -0.3rem 1rem;
    height: 5px;
    width: 5px;
  }

  .iconLink .icon {
    width: 25px;
    height: 15px;
  }
}
