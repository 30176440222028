/* GENERAL */
:root {
  --title-hd: bold 28px Helvetica;
  --subtitle-hd: bold 18px Helvetica;
  --p-hd: normal 14px Helvetica;
  --container-hd: 1062px !important;
  --padding-section-hd: 3.5rem 0;

  --title-fhd: bold 36px Helvetica;
  --subtitle-fhd: bold 26px Helvetica;
  --p-fhd: normal 16px Helvetica;
  --container-fhd: 1520px !important;
  --padding-section-fhd: 5rem 0;
}

/* SPECIFIC */
.posRelative {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #f4f3ef;
}

.mouseImg {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 50vw;
  bottom: 60px;
  font: normal normal normal 12px Helvetica;
  letter-spacing: -0.12px;
}

.mouseImg p {
  margin: 16px 0 0 0;
}

.customContainer {
  max-width: 1520px;
  padding: 0;
}

.imageFull {
  width: 100%;
  height: 65vh;
  object-fit: cover;
}

.title {
  text-align: left;
  font: var(--title-fhd);
  color: #000000;
  text-transform: uppercase;
  padding: 0;
  margin-bottom: 2rem;
}

.contentDesc {
  text-align: left;
  font: var(--p-fhd);
  line-height: 1.8;
  letter-spacing: 0px;
  color: #000000;
}

.contentDesc p {
  margin: 0;
}

@media only screen and (max-width: 1400px) {
  .posRelative {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .customContainer {
    max-width: var(--container-hd) !important;
  }

  .title {
    font: var(--title-hd);
    margin-bottom: 1.5rem;
  }

  .contentDesc {
    font: var(--p-hd);
    line-height: 1.8;
  }
}

@media only screen and (min-width: 2560px) {
  .customContainer {
    max-width: 2000px !important;
  }
}
