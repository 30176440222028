.logo {
  height: 60px;
  width: 112px;
  position: fixed;
  z-index: 10;
  left: 1%;
  /* margin-top: 0.5rem; */
  top: 1%;
}

.boxLinks {
  position: relative;
  margin: auto 0;
  text-align: left;
}

.outerCircle2 {
  padding: 5px;
  /* background-color: rgb(45, 57, 69, 0.5); */
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  margin: 0 1rem 0 auto;
  align-self: left;
  opacity: 0;
  position: absolute;
  /* top: 1px; */
  /* left: 42%; */
}

.readMore {
  cursor: pointer;
  color: black;
  font: normal 16px Helvetica;
  line-height: 1.8rem;
}

.innerCircle2 {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.boxLinks:hover .outerCircle2 {
  opacity: 1;
}

.boxLinks:hover .readMore {
  margin-left: 2rem;
  /*font-weight: bold;*/
}

.Circuit {
  position: absolute;
  top: 440px;
  /* left: 0; */
  height: 100%;
  width: 28rem;
  opacity: 1;
  z-index: 0;
}

/* .Core {
  position: absolute;
  top: 440px;
  left: 0;
  height: auto;
  width: 100vw;
  opacity: 1;
  z-index: -1;
} */

.Core {
  position: absolute;
  top: 0px;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.1;
  z-index: -1;
}

.Core svg {
  left: 2rem;
}

/* .st0 {
  fill: none;
  stroke: #223771;
  stroke-width: 2;
  stroke-miterlimit: 10;
} */

.st0 {
  fill: none;
  stroke: #223771;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.st1 {
  fill: #223771;
}

.newNavbar {
  height: 56px;
  width: 100%;
  background-color: #f1f1f1;
  transition: all 1s ease;
  z-index: 80;
  margin-left: 69px;
  /* position: absolute; */
  top: 1rem;
  transition: all 1s linear 0.3s;
  /* position: -webkit-sticky; */
  position: fixed;
  top: 1.5%;
  box-shadow: -5px 6px 6px #00000029;
}

.navbarMask {
  height: 56px;
  width: 100%;
  background-color: #f1f1f1;
  z-index: 99;
  margin-left: 192px;
  position: absolute;
  right: 0;
}

.nav_fake {
  background-color: #f1f1f1;
  height: 56px;
  width: 300px;
  position: fixed;
  right: 0;
  top: 1%;
  z-index: 99;
  margin-top: 4.5px;
}

.backgroundsticky {
  /* background-color: #223771; */
  background-color: transparent;
  width: 100vw;
  height: 440px;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.stickyBackground {
  position: fixed;
  top: calc(-440px + 85px);
}

.stickyCircuit {
  position: fixed;
  top: calc(85px);
}

.img_list {
  width: 20px;
}

.img_grid {
  width: 20px;
}

.wrap_tab {
  background-color: white;
  height: 100px;
  border-radius: 10px;
  margin-top: 10px;
  width: 135px;
  position: absolute;
  left: 4%;
  top: 1%;
  opacity: 0;
}

.btn_wrap {
  height: 50px;
  width: 50px;
  text-align: center;
  margin-top: -5px;
}

.button_tab {
  width: 10px;
  height: 10px;
}

.box {
  height: 56px;
  padding: 1.4rem;
  display: flex;
  position: relative;
}

.box:hover .wrap_tab {
  opacity: 1;
}

.box span {
  color: #000;
  font: bold 14px/28px Helvetica;
  align-self: center;
  line-height: 1.8rem;
}

.box span:hover {
  cursor: pointer;
}

.isActive span {
  color: #223771;
  text-transform: uppercase;
}

.isActive {
  border-bottom: 3px solid #223771;
}

.box .isActive span {
  color: #223771;
  text-transform: uppercase;
}

.wrap_option {
  display: flex;
  position: absolute;
  left: 35%;
}

.input_search {
  width: 250px;
  height: 31px;
  border: none;
  font-size: 18px;
  line-height: 1.8rem;
  position: absolute;
  top: 10%;
}

.input_search:focus {
  border: hidden !important;
  outline: none;
}

.box_search {
  width: 20%;
  height: 35px;
  background-color: white;
  padding: 10px 20px;
  display: flex;
  border-radius: 20px;
  border: hidden;
  margin: 8px;
  position: absolute;
  right: 30%;
}

.grid {
  width: 40px;
  height: 40px;
  padding: 1rem;
}

.grid_fake {
  position: absolute;
  top: 40%;
  left: 20%;
  opacity: 0;
}

.list_fake {
  position: absolute;
  top: 40%;
  /* left: 70%; */
  opacity: 0;
  right: 0;
}

.grid:hover .grid_fake {
  opacity: 1;
}

.list {
  width: 40px;
  height: 40px;
  padding: 1rem;
}

.box_search p {
  text-align: left;
  font: 19px Helvetica;
  line-height: 1.8rem;
}

.box_search img {
  position: absolute;
  right: 3%;
  width: 20px;
  height: 20px;
  top: 23%;
}

.main {
  min-height: 100vh;
  background: #fff;
  position: relative;
  z-index: 1;
  box-shadow: 0 3px 6px #00000029;
  overflow: hidden;
}

.content {
  /* margin-top: 4rem; */
  position: relative;
  display: flex;
}

.contentWrap {
  position: relative;
  display: flex;
  margin: 2rem 1rem;
  justify-content: center;
}

.contentWrap1 {
  position: relative;
  display: flex;
  margin: 1rem auto;
  justify-content: center;
  width: 1345px;
}

.contentWrap1:hover {
  box-shadow: 0px 6px 16px #00000073;
}

p.title {
  font: 18px/32px Helvetica;
  color: black;
  line-height: 1.8rem;
}

p.contentTitle {
  font: bold 24px Helvetica;
  color: black;
  line-height: 1.8rem;
}

p.contentDesc {
  font: 16px/24px Helvetica;
  color: black;
  line-height: 1.8rem;
}

.titleBoxEvent {
  color: #223771;
  padding: 1rem 3rem 0;
  text-transform: uppercase;
}

.titleBoxNews {
  color: #f16b26;
  padding: 1rem 3rem 0;
  text-transform: uppercase;
}

.titleBoxUpcoming {
  color: #223771;
  padding: 1rem 3rem 0;
  text-transform: uppercase;
}

.customNavbar1 {
  padding-left: 2.2rem;
  margin-top: 4rem;
  /* background-color: #223771; */
}

.boxContentL {
  width: 872px;
  height: 256px;
  background-color: #f9f9f9;
  text-decoration: none;
  color: black;
}

.boxContentL:hover {
  background-color: #f16b26;
  color: white !important;
}

.boxContentL:hover .title_content {
  color: white;
}

.boxContentL:hover p {
  color: white;
}

.boxContentL1 {
  width: 872px;
  height: 260px;
  background-color: #f1f1f1;
  text-decoration: none;
  color: black;
  padding: 2rem 0;
  /* box-shadow: 0px 6px 16px #00000073; */
}

.boxContentL1:hover {
  background-color: #223771;
  color: white !important;
}

.boxContentL1:hover .title_content {
  color: white;
}

.boxContentL1:hover p {
  color: white;
}

.boxContentL2 {
  width: 872px;
  height: 260px;
  background-color: #f5f5f5;
  text-decoration: none;
  color: black;
  padding: 2rem 0;
}

.boxContentL2:hover {
  background-color: #f16b26;
  color: white !important;
}

.boxContentL2:hover .title_content {
  color: white;
}

.boxContentL2:hover p {
  color: white;
}

.boxContent1 {
  width: 432px;
  height: calc(376px + 4rem);
  background-color: #f1f1f1;
  text-decoration: none;
  color: black;
  /* margin: 0 12px; */
  position: relative;
  -webkit-animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.boxContent2 {
  width: 432px;
  height: 376px;
  background-color: #223771;
  text-decoration: none;
  color: white;
  margin: 0 12px;
  position: relative;
  -webkit-animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.boxContent3 {
  width: 432px;
  height: 376px;
  background-color: black;
  text-decoration: none;
  color: white;
  margin: 0 12px;
  position: relative;
  -webkit-animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes shadow-drop-2-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    -webkit-transform: translateZ(50px);
    transform: translateZ(50px);
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}

@keyframes shadow-drop-2-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    -webkit-transform: translateZ(50px);
    transform: translateZ(50px);
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}

.boxflex {
  width: 472px;
}

.boxContent:hover {
  text-decoration: none;
  box-shadow: 0 3px 6px #00000029;
}

.contentBox {
  padding: 1rem 2rem;
  text-transform: uppercase;
  color: black;
}

.boxImage {
  width: 472px;
  height: 256px;
  /* background-color: #F16B26; */
  overflow: hidden;
  position: relative;
}

.newsColor {
  background-color: #f1f1f1;
}

.newsColor {
  background-color: #f1f1f1;
}

.eventColor {
  background-color: #223771;
}

/* 
.upEventColor {
    background-color: #F16B26;
} */

.title_content {
  padding: 0 3rem;
  color: black;
  height: 65px;
}

.title_content p {
  font: bold 18px Helvetica;
  line-height: 1.8rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.title_content1 {
  padding: 2rem 2rem;

  /* margin-bottom: 1rem; */
  height: 8rem;
  overflow: hidden;
}

.title_content1 p {
  font: bold 18px Helvetica;
  line-height: 1.8rem;
  color: black;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.8rem;
}

.txt_content {
  padding: 1rem 3rem 0;
  font: 16px/32px Helvetica;
  height: 75px;
  color: black;
  line-height: 1.8rem;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.txt_content p {
  font: 16px/32px Helvetica;
  margin-bottom: 0;
  line-height: 1.8rem;
}

.txt_content1 {
  padding: 1rem 2rem;
  font: 16px Helvetica;
  height: 125px;
  overflow: hidden;
}

.foot_content {
  position: relative;
  display: flex;
  padding: 2rem 3rem 0;
  font: 16px Helvetica;
  color: black;
  line-height: 1.8rem;
}

.foot_content1 {
  position: relative;
  display: flex;
  padding: 0 2rem 1rem 2rem;
  font: 16px Helvetica;
  color: black;
  line-height: 1.8rem;
}

.date_right {
  position: absolute;
  right: 7%;
}

.date_right1 {
  position: absolute;
  right: 10%;
}

.box_image {
  width: 472px;
  height: 266px;
  object-fit: cover;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  overflow: hidden;
}

.boxImageGrid {
  width: 432px;
  height: 266px;
  object-fit: cover;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  overflow: hidden;
}

.boxVideo {
  width: 472px;
  height: 266px;
  border: 0px;
  object-fit: cover;
}

.boxVideoGrid {
  width: 432px;
  height: 266px;
  border: 0px;
  object-fit: cover;
}

.div_readmore {
  position: relative;
  background-color: #f1f1f1;
  width: 420px;
  height: 156px;
}

.readmore_btn {
  /* margin: 3rem auto 3rem auto; */
  position: absolute;
  background-color: #223771;
  width: 192px;
  height: 48px;
  color: white;
  border-radius: 40px;
  text-align: center;
  padding: 0.75rem;
  top: 30%;
  left: 25%;
}

.box_behind {
  position: absolute;
  top: 0;
  opacity: 01;
}

.box_behind:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  transition: 1s ease-in-out;
}

.boxContent1:hover .box_behind {
  opacity: 1;
}

.div_readmore1 {
  position: relative;
  background-color: #f1f1f1;
  width: 432px;
  height: 100%;
}

.readmore_btn1 {
  /* margin: 3rem auto 3rem auto; */
  position: absolute;
  background-color: #223771;
  width: 192px;
  height: 48px;
  color: white;
  border-radius: 40px;
  text-align: center;
  padding: 0.75rem;
  top: 35%;
  left: 25%;
}

.box_content_image {
  height: 266px;
  position: relative;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  overflow: hidden;
}

figure.wrapp_image {
  width: 880px;
  height: 424px;
  overflow: hidden;
  /* background: #eee5e5bd; */
}

.img_content {
  width: 472px;
  height: 256px;
  object-fit: cover;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  overflow: hidden;
}

.inside_image {
  width: 472px;
  height: 256px;
  margin: auto;
  position: absolute;
  /* display: block; */
  top: 0;
  left: 0;
  padding: 15%;
  text-align: center;
  background-color: rgba(234, 236, 236, 0.945);
  opacity: 0;
  color: black;
}

.icon_share {
  /* position: absolute; */
  width: 50px;
  height: 50px;
  margin-bottom: 7%;
  /* top: 40%; */
  /* left: 47%; */
  /* opacity: 0; */
}

figure.wrapp_image:hover .inside_image {
  opacity: 1;
}

figure.wrapp_image:hover .img_content {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  transition: 2s ease-in-out;
}

.icon {
  margin: 5px;
  top: 50%;
}

.img_content:hover {
  /* opacity: 0.1; */
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  transition: 2s ease-in-out;
}

.background_blur {
  z-index: 8;
  height: 75px;
  /* background-color: rgb(255 255 255); */
  width: 100vw;
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: transparent;
}

.link {
  text-decoration: none !important;
}

.component {
  margin-top: 2rem;
  /* -webkit-animation: scale-up-hor-center 0.4s
    cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-hor-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both; */
}

/* @-webkit-keyframes scale-up-hor-center {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes scale-up-hor-center {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
} */

.fadeInBottom {
  -webkit-animation: fade-in-bottom 0.5s ease-in both;
  animation: fade-in-bottom 0.5s ease-in both;
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* .componentgrid {
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
} */

.images_slide {
  width: 100%;
  height: 1080px;
  background-color: tomato;
}

.older {
  width: 416px;
  height: 64px;
  background-color: #f16b26;
  margin: 50px auto;
  box-shadow: 3px -1px 6px #00000061;
  border-radius: 50px;
}

.older:hover {
  background-color: #223771;
}

.older p {
  font: 20px Helvetica;
  color: white;
  text-align: center;
  padding: 1.3rem;
  margin: none !important;
  cursor: pointer;
  line-height: 1.8rem;
}

.box_grid {
  position: relative;
  display: flex;
  margin: 2rem auto;
  justify-content: center;
}

.customSelect {
  border: none;
  background-color: transparent;
  margin: 15px 0 0 20px;
  display: flex;
  height: 100%;
  width: 10%;
  position: relative;
  font: 16px Helvetica;
  border-radius: 1rem;
  line-height: 1.8rem;
}

.customSelect option {
  background-color: white;
  border-radius: 10px;
  width: 200px;
}

.customSelect option:hover {
  background-color: #223771;
}

.boxC {
  max-width: 1540px;
}

@media only screen and (min-width: 2560px) {
  .boxC {
    max-width: 2090px !important;
  }

  .boxContent1 {
    margin: 2rem;
    height: 445px;
  }

  .div_readmore1 {
    height: auto;
  }

  .foot_content1 {
    padding: 2rem 2rem 1rem 2rem;
  }

  .title_content1 {
    height: 90px;
    padding: 2rem 2rem 4rem 2rem;
  }


}

@media only screen and (max-width: 1400px) {
  .boxC {
    padding: 0;
  }

  .component {
    margin-top: 1rem;
  }

  .newNavbar {
    margin-left: 209px;
  }

  .box_grid {
    width: 80%;
    margin-top: 0rem;
  }

  .title_content1 p {
    font: bold 14px Helvetica;
  }

  .input_search {
    width: 245px;
    font-size: 14px;
  }

  .content {
    margin-top: -2rem;
  }

  .img_grid {
    width: 15px;
  }

  .img_list {
    width: 15px;
  }

  .box_search {
    right: 33%;
    width: 285px;
  }

  .box_search p {
    font: 14px Helvetica;
  }

  .wrap_option {
    left: 35%;
  }

  .boxContentL {
    width: 600px;
    height: 200px;
  }

  .boxContentL1 {
    width: 600px;
    height: 200px;
  }

  .boxContentL2 {
    width: 600px;
    height: 200px;
    padding: 1.5rem 0;
  }

  .div_readmore {
    width: 290px;
    height: 90px;
  }

  .div_readmore1 {
    width: 290px;
    height: 150px;
    padding: 1.5rem 0;
  }

  .boxContent1 {
    width: 290px;
    height: 335px;
  }

  .boxContent2 {
    width: 290px;
    height: 280px;
  }

  .boxContent3 {
    width: 290px;
    height: 280px;
  }

  .box_image {
    width: 318px;
    height: 210px;
  }

  .boxImage {
    width: 318px;
    height: 200px;
  }

  .boxImageGrid {
    width: 290px;
    height: 200px;
  }

  .boxVideo {
    width: 290pxpx;
    height: 200px;
    border: 0px;
    object-fit: cover;
  }
  
  .boxVideoGrid {
    width: 290px;
    height: 200px;
    border: 0px;
    object-fit: cover;
  }

  .box_content_image {
    height: 200px;
  }

  .title_content {
    padding: 0 25px 1rem 25px;
    height: 60px;
  }

  .title_content p {
    font: bold 14px Helvetica;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 28px;
  }

  .contentBox {
    padding: 15px 25px 5px;
    text-decoration: none;
  }

  .titleBoxEvent {
    padding: 15px 25px 5px;
    text-decoration: none;
  }

  .titleBoxNews {
    padding: 15px 25px 5px;
    text-decoration: none;
  }

  .titleBoxUpcoming {
    padding: 15px 25px 5px;
    text-decoration: none;
  }

  .txt_content {
    margin: 0 25px 1rem 25px;
    padding: 0;
    font: 14px Helvetica;
    height: 50px;
  }

  .txt_content p {
    font: 14px Helvetica;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 28px;
  }

  .foot_content {
    font: 14px Helvetica;
    padding: 0 25px;
    height: 50px;
  }

  .img_content {
    /* object-fit: cover; */
    width: 318px;
    height: 200px;
  }

  .readmore_btn {
    top: 25%;
    left: 17%;
  }

  .readmore_btn1 {
    top: 25%;
    left: 17%;
  }

  .title_content1 {
    height: 68px;
    padding: 0;
    margin: 0 0 1rem 0;
    padding: 0;
  }

  .title_content1 p {
    font: bold 14px Helvetica;
    padding: 0 1.5rem;
    /* text-align: justify; */
  }

  .txt_content1 {
    font-size: 14px;
    height: 70px;
    padding: 0 1.5rem;
    margin-bottom: 1rem;
  }

  .foot_content1 {
    font: 12px Helvetica;
    padding: 0 1.5rem;
  }

  .date_right {
    bottom: 17%;
    font: 14px Helvetica;
  }

  .date_right1 {
    bottom: 0;
    font: 14px Helvetica;
  }

  .readMore {
    font: 14px Helvetica;
  }

  .contentWrap1 {
    width: 918px;
  }

  .inside_image {
    width: 318px;
    height: 200px;
  }

  .icon_share {
    height: 38px;
    width: 38px;
    margin-bottom: 5%;
  }

  .customSelect {
    margin: 17px 0 0 -185px;
    font: normal 14px Helvetica;
  }

  .older {
    width: 366px;
    height: 54px;
  }

  .older p {
    padding: 1.1rem;
    line-height: 28px;
    font: 14px Helvetica;
  }
}

/* @media only screen and (max-width:2350px) {
    .content {
        margin-top: -29rem;
    }
    .newNavbar {

        margin-left: 225px;
    }

    .box_grid {
        width: 80%;
    }

    .input_search {
        width: 245px;
        font-size: 18px;
    }

    .contentWrap{
        width: 70%;
    }
.wrap_option {
    left: 35%;
}
.inside_image {
    width: 371px;
}

} */
