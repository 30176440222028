.mainClass {
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
}

.boxBanner {
  width: 100vw;
  height: 65vh;
  background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
  background: #000000 0% 0% no-repeat padding-box;
  display: block;
  position: relative;
}

.boxBanner img {
  width: 100vw;
  height: 65vh;
  object-fit: cover;
}

.blogs {
  position: relative;
  max-width: 1540px;
}

.previous {
  float: left;
  position: absolute;
  top: -4rem;
  left: 0;
}

.next {
  float: right;
  position: absolute;
  top: -4rem;
  right: 0;
}

.lblPagination {
  font: 14px/55px Helvetica;
  color: #ffffff;
}

.boxContent {
  margin: 0 10% 5rem 0;
  height: 100%;
  width: 100%;
  /* background-color: #f1f1f1; */
}

.boxProfile {
  width: 336px;
  height: 504px;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  padding: 2rem;
}

.blogTitle {
  margin-top: 5rem;
  text-align: left;
  font: 26px Helvetica;
  color: #000000;
}

.title {
  text-align: left;
  font: bold 26px Helvetica;
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.date {
  text-align: left;
  font: 16px Helvetica;
  color: #000000;
  margin-bottom: 2rem;
}

.content {
  text-align: left;
  font: normal 16px Helvetica;
  color: #000000;
  line-height: 1.8rem;
}

.profile {
  width: 144px;
  height: 144px;
  border-radius: 50%;
  background-color: transparent;
  box-shadow: 0px 3px 6px #00000029;
  margin: 0 auto 2rem auto;
  border: 1px solid white;
}

.profile img {
  width: 144px;
  height: 144px;
  border-radius: 50%;
  object-fit: cover;
  padding: 10px;
}

.titleProfile {
  text-align: left;
  font: bold 16px Helvetica;
  color: #000000;
}

.contentProfile {
  text-align: left;
  font: 16px Helvetica;
  color: #000000;
}

.share {
  display: flex;
  position: relative;
  flex-direction: row;
}

.icon {
  height: 24px;
  width: 24px;
  margin: 0 2px;
}

@media only screen and (min-width: 2560px) {
  .blogs {
    max-width: 2000px !important;
  }
}

@media only screen and (max-width: 1400px) {
  .blogs {
    max-width: 1062px !important;
  }

  .title {
    font: bold 18px Helvetica;
    margin-bottom: 1rem;
  }

  .date {
    font: 14px Helvetica;
    margin-bottom: 1rem;
  }

  .content {
    font: normal 14px Helvetica;
    line-height: 1.5rem;
  }

  .blogTitle {
    margin-top: 3.5rem;
    font: 18px Helvetica;
  }

  .boxContent {
    margin: 0 10% 3.5rem 0;
  }

  .boxProfile {
    width: 355px;
  }

  .titleProfile {
    font: bold 14px Helvetica;
  }

  .contentProfile {
    font: 14px Helvetica;
  }
}
