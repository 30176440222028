.content {
  width: 100%;
}

.contentt {
  width: 100%;
  display: flex !important;
}

.boxContainer {
  height: 528px;
  width: 45vw;
  padding: 4rem;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}

.title {
  position: relative;
  font-size: 26px;
  color: white !important;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 1rem;
}
.paragraph {
  font-size: 16px;
  position: relative;
  color: white !important;
  line-height: 1.8rem;
}

.boxL {
  width: auto;
  height: auto;
}

.boxL p {
  text-align: left;
}

.box {
  width: 100%;
  background-color: #223771;
  color: white;
  font-family: Helvetica;
}

.paragraph p,
.paragraph ul {
  font-size: 16px;
  color: white !important;
  text-align: justify;
  line-height: 2rem;
  margin: 0;
}

.paragraph li {
  text-align: justify;
}

.box_2 {
  width: 100%;
  object-fit: cover;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 528px;
}

.imageDefault {
  object-fit: contain;
  width: 100%;
  height: 528px;
}

@media only screen and (max-width: 1400px) {
  .image {
    height: 345px;
  }
  .title {
    position: relative;
    top: 0;
    font-size: 16px;
  }
  .paragraph p,
  .paragraph ul {
    font-size: 12px;
    line-height: 28px;
  }

  .paragraph {
    font-size: 12px;
    line-height: 28px;
  }

  .box {
    width: 100%;
  }

  .boxContainer {
    height: 345px;
    padding: 2rem;
  }
}
