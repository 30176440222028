.Link {
  color: white !important;
}

.Link:hover {
  text-transform: none;
  text-decoration: none;
}

.mainBox {
  width: 50%;
  height: 100%;
}

.boxMainNews {
  width: auto;
  height: 100%;
}

.custom {
  overflow: hidden;
  display: block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 85px;
  padding-top: 1rem;
}

.textTitleNews {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.textTitleNews,
.textTitleNews p {
  margin: 0 !important;
  text-transform: capitalize;
  font-size: small;
  letter-spacing: 0;
  color: white;
}

.dateTitleNews {
  font-size: small;
  color: #f16b26;
  letter-spacing: 0;
  /* color: #f16b26; */
}

.contentImg {
  height: 300px;
  width: 300px;
  object-fit: cover;
}

@media only screen and (max-width: 1400px) {
  .contentImg {
    height: 200px;
    width: 200px;
  }

  .custom {
    height: 76px;
  }

  .custom p {
    font: 14px Helvetica;
  }
}
