.category {
  display: flex;
  align-items: center;
  margin-top: 5rem;
  /* margin-bottom: 15px; */
}

.cat-button {
  background: #707070 0% 0% no-repeat padding-box;
  border-radius: 50px;
  opacity: 1;
  font-size: 16px;
  color: white;
  font-family: Helvetica, sans-serif;
  padding: 6px 20px 6px 20px;
  margin-right: 10px;
  cursor: pointer;
}

.cat-button-selected {
  background: black;
}

.cat-button-plus {
  background: #223771 0% 0% no-repeat padding-box;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  opacity: 1;
  margin: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cat-button-plus p {
  margin-top: 10px;
  font-size: 30px;
}

.box {
  height: 376px;
  width: 416px;
  background-color: rgba(34, 55, 113, 0.6);
  margin-bottom: 64px;
  border-radius: 0;
}

.content {
  margin-top: 39px;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 28px;
}

.box .text {
  font-family: Helvetica;
  letter-spacing: -0.18px;
  color: #f0f0f0;
  opacity: 1;
  margin: 0;
  text-align: left;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.titleCard {
  letter-spacing: -0.28px;
  color: #f0f0f0;
  opacity: 1;
  font: normal normal bold 28px/32px Helvetica;
  text-align: left;
  margin-bottom: 83px;
}

.status {
  margin-bottom: 25px;
}

.company {
  margin-top: 83px;
  margin-bottom: 30px;
}

.fwd-button {
  position: absolute;
  border-radius: 100%;
  bottom: 32px;
  right: 32px;
  height: 40px;
  width: 40px;
  border: 2px solid white;
  text-align: center;
}

.fwd-button i {
  font-size: 18px;
  margin-top: 10px;
  color: white;
}

.rightCol {
  text-align: center;
}

@media only screen and (max-width: 1400px) {
  .category {
    margin-top: 3.5rem;
  }

  .cat-button {
    font-size: 14px;
  }
}
