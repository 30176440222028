.customContainer {
  padding: 5rem 0 0 0;
}
.Row_1 {
  width: 100vw;
  height: 20vh;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
}

.Row_2 {
  width: 100vw;
  height: 100%;
  background-color: #f1f1f1;
  position: relative;
  /* padding: 5rem 0 5rem 0; */
}

.boxPhoto {
  width: 100%;
  height: 500px;
  background-color: #d6d6d6;
  /* justify-content: right; */
  position: relative;
  /* padding: 5rem 0 0 0; */
}

._flexDir {
  display: flex;
  flex-direction: column;
}

.blockquote {
  border: none;
  font-family: Helvetica;
}

.col_second h4 {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  justify-content: center;
  text-align: left;
  line-height: 1.8rem;
}

.col_second h2 {
  color: #f16624;
  font: bold 36px Helvetica;
  text-align: left;
  margin-bottom: 2rem;
  /* line-height: 1.8rem; */
}

/* .col_second {
  quotes: "» " " «";
} */

.col_second h2:before {
  content: open-quote;
  font-weight: bold;
  font-size: 60px;
  color: #f16624;
}
.col_second h2:after {
  content: close-quote;
  font-weight: bold;
  font-size: 60px;
  color: #f16624;
}

._boxB {
  width: 70%;
  height: auto;
  background-color: #223771;
  position: relative;
  margin-top: -10rem;
  margin-left: 3rem;
}

.box_1 {
  width: 488px;
  height: 288px;
}
._overlay {
  background: rgb(0 0 0 / 0.3);
  opacity: 0;
  width: 488px;
  height: 288px;
}
.box_c {
  padding-bottom: 5rem;
  /* margin: auto; */
  max-width: 1540px;
}
.img_box {
  width: 488px;
  height: 288px;
  object-fit: cover;
}
.box_hover {
  width: 488px;
  height: 288px;
}
.box_inside_h {
  width: 488px;
  height: 288px;
  background-color: rgba(127, 127, 228, 0.555);
  opacity: 0;
  padding: 6rem 2rem 2rem 2rem;
  top: 0;
  display: flex;
}

/* .box_inside_h span {
  font: normal 14px Helvetica;
  color: white;
} */

.circle {
  content: "";
  /* position: absolute;
  bottom: -1rem;
  left: 0.4rem; /
  position: absolute;
  top: 2rem;
  / margin: auto 0.5rem auto auto; relative */
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.outerCircleBot {
  padding: 5px;
  margin-right: 1rem;
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  align-self: center;
  opacity: 1;
}

.circle,
.innerCircle {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.innerCircleBot {
  content: "";
  background: #f26522;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.contentBottomTitle {
  position: relative;
  display: flex;
  opacity: 0;
  margin: auto 0;
  text-align: left;
}

.contentBottomTitle span a {
  font: 14px Helvetica;
  color: #f26522;
}

.box_hover:hover .contentBottomTitle {
  opacity: 1;
  font-weight: bold;
}

.contentBottomTitle:hover .outerCircle2 {
  opacity: 1;
}

.contentBottomTitle:hover .readMore2 {
  margin-left: 2rem;
  /*font-weight: bold;*/
}
._text p {
  font: bold 22px Helvetica;
  text-align: left;
  opacity: 1;
  color: white;
  position: absolute;
}
._absolute {
  top: 0;
  position: absolute;
}
._box {
  width: auto;
  height: 15px;
  margin: auto auto auto 15%;
  position: relative;
  text-decoration: none;
  opacity: 1;
}

.customUnderline,
.customUnderline:hover,
.customUnderline:focus,
.customUnderline:active {
  color: #fff;
  text-decoration: none;
}

.customUnderline::after {
  content: "";
  position: absolute;
}

._box .customUnderline::after {
  bottom: -22px;
  height: 5px;
  width: 0%;
  left: 0;
  background-color: #f16624;
  transition: 0.4s ease all;
}

._box:hover .customUnderline::after {
  width: 100%;
  transition: 0.5s ease all;
}

._box p {
  font: bold 22px Helvetica;
  text-transform: uppercase;
  text-align: left;
  align-self: center;
}

._box--offset {
  background-size: 100% 100%;
  background-position: 0 0.5em;
  opacity: 0.8;
  width: auto;
}

._box:hover {
  opacity: 0.8;
}

._content {
  position: relative;
  display: flex;
  width: 488px !important;
  height: 288px !important;
  background: transparent;
  /* backdrop-filter: blur(2px); */
}

._absolute p {
  font: bold 26px Helvetica;
  color: white;
  text-transform: uppercase;
}

.box_hover:hover ._box {
  opacity: 1;
}
.box_hover:hover ._content {
  background: transparent;
  /* backdrop-filter: none; */
  backdrop-filter: blur(2px);
}

.box_hover:hover .img_box {
  position: absolute;
}
.box_hover:hover ._overlay {
  opacity: 0.9;
}
.Row_3 {
  width: 100vw;
  height: 50vh;
  background-color: #223771;
}

.Card_Row_2 {
  position: absolute;
  width: 776px;
  height: 256px;
  background-color: #fff;
  z-index: 4;
  left: 10%;
  bottom: 27%;
  padding: 50px;
}

.card_footer_row_2 {
  color: #f26522;
  text-decoration: underline;
  border: none;
  background-color: #fff;
  padding: 0;
}

.card_text_row_1_col_2 {
  width: 100%;
  height: 197px;
  color: #fff;
  font-size: 32px;
  position: absolute;
  left: 13%;
  top: 24%;
  font-family: Helvetica;
  font-weight: bold;
  text-align: left;
  opacity: 1;
  display: flex;
  cursor: pointer;
}

.cardTextView {
  width: 100px;
  position: absolute;
  left: 25%;
  top: 65%;
  color: white;
  font: 12px Helvetica;
  opacity: 0;
  cursor: pointer;
}

.card_text_row_1_col_2:hover {
  color: #fff;
  font-weight: bolder;
  /* font-size: 40px; */
}

.card_text_row_1_col_2:hover .image_zoom {
  opacity: 1;
}

.card_text_row_1_col_2:hover .image_zoom_2 {
  opacity: 1;
}

.card_text_row_1_col_2:hover ~ .cardTextView {
  opacity: 1;
}

.btn:hover {
  color: #fff !important;
  text-decoration: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem #d1c0b8;
}

.btn-flat {
  background-color: #f26522;
  border-color: #f26522;
  color: #fff;
  border-color: #f26522;
}

.btn-flat:hover {
  color: #fff !important;
  background-color: #f26522 !important;
  border-color: #f26522 !important;
}

.Card_Row_3 {
  position: absolute;
  width: 920px;
  height: 308px;
  background-color: #223771 !important;
  z-index: 4;
  border: none;
  font-family: Helvetica;
}

.card_group_1 {
  width: 360px;
  height: 408px;
  object-fit: cover;
}

.card_row_3 {
  position: absolute;
  left: 10%;
  width: 1400px;
  margin-top: 84px;
  height: 270px;
  background-color: #223771 !important;
  /* align-self: flex-end; */
  border-color: #223771;
}

.content_wrap {
  position: relative;
  width: 416px;
  height: 298px;
  background: #223771;
  /* margin: 10px; */
  align-self: flex-end;
  margin-top: 20px;
  /* padding: 10px; */
}

.card_row_3_1 {
  position: absolute;
  left: 10%;
  bottom: 22.6%;
  width: 100%;
  height: 360px;
  background-color: #223771;
  z-index: 4;
  border-color: #223771;
}

.image_row_3 {
  width: 360px;
  height: 350px;
  object-fit: cover;
}

.card_row_3_title {
  position: absolute;
  top: 6%;
  left: 40%;
  font-size: 32px;
  font-weight: bold;
  padding: 10px;
  color: #fff;
  width: 30rem;
  font-family: Helvetica;
}

.card_text_3 {
  position: absolute;
  top: 32%;
  left: 40%;
  color: #fff;
  font-size: 16px;
  font-family: Helvetica;
  padding: 10px;
  width: 30rem;
}

.card_footer_row_3 {
  position: absolute;
  left: 40%;
  bottom: 5%;
  color: #f26522;
  text-decoration: underline;
  border: none;
  background-color: #223771;
  padding: 10px;
  font-family: Helvetica;
}

.col_3_2 {
  position: relative;
}

.img_row_3_2 {
  height: 266px;
  width: 256px;
  object-fit: cover;
  padding-right: 10px;
}

.image_zoom {
  opacity: 0;
  /* height: 400px;
  width: 720px;
  margin-top: -260px;
  margin-left: 3rem; */
  height: 250px;
  width: 570px;
  margin-top: -95px;
  margin-left: 8rem;
}

.image_zoom_2 {
  /* right: 103px;  */
  width: auto;
  height: 312px;
  opacity: 0;
  position: relative;
  margin: -242px;
  left: 1056px;
}

.title_row_3_2 {
  font-size: 32px;
  color: white;
  font-weight: bold;
  font-family: Helvetica;
}

/* .title_row_3_1 {
    font-size: 32px;
    font-weight: bold;
    font-family: Helvetica;
    color: black;
} */

.read_more {
  position: absolute;
  font-size: 16px;
  color: #f26522;
  font-family: Helvetica;
  right: 58%;
  top: 8%;
}

.sliderr {
  height: 250px;
}

.backgroundRowFirst {
  width: 100%;
  margin: auto;
  position: relative;
  background-color: #ececec;
  padding-bottom: 5rem;
}
.boxLinks {
  position: relative;
  margin: auto 0;
  text-align: left;
}
.boxLinks2 {
  position: relative;
  margin: auto 0;
  text-align: left;
  margin-top: 2rem;
}
.boxLinks2:hover .outerCircle2 {
  opacity: 1;
}

.boxLinks2:hover .readMore {
  margin-left: 1.5rem;
  /*font-weight: bold;*/
}
.outerCircle2 {
  padding: 5px;
  /* background-color: rgb(45, 57, 69, 0.5); */
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  margin: 0 1rem 0 auto;
  align-self: left;
  opacity: 0;
  position: absolute;
  /* top: 1px; */
  /* left: 42%; */
}

.innerCircle2 {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.boxLinks:hover .outerCircle2 {
  opacity: 1;
}

.boxLinks:hover .readMore {
  margin-left: 1.5rem;
}

.row_first {
  margin: auto;
  height: auto;
  /* display: flex;
  flex-direction: row-reverse;
  justify-content: space-between; */
}

.col_first {
  margin: auto;
  text-align: left;
  position: relative;
}

.col_second {
  position: relative;
  /* margin: auto; */
  width: auto;
  height: auto;
  background-color: #223771;
  padding: 2rem 4rem;
}

/* .col_second p {
  color: white;
  font-size: 16px;
  font-family: Helvetica;
} */

/* .col_second h4 {
  color: white;
  font-size: 24px;
  font-weight: bold;
  font-family: Helvetica;
} */

.p {
  text-align: center;
  /*color: black;*/
  color: #f16624;
  font-size: 32px;
  font-weight: bold;
  font-family: Helvetica;
}

.img_first {
  justify-content: center;
  object-fit: contain;
  height: 430px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.img_first_2 {
  width: auto;
  margin: 0;
  justify-content: center;
  object-fit: contain;
  height: 340px;
  right: 0;
  position: absolute;
}

.colom {
  display: flex;
  position: relative;
}

.customBox {
  position: absolute;
  top: 5%;
  left: 20%;
  height: 100%;
  width: 100%;
}

.boxContent {
  position: relative;
  height: 100%;
  width: 100%;
}

.textTitle {
  text-align: left;
  font: bold 36px/48px Helvetica;
  color: #ffffff;
  text-transform: uppercase;
}

.contentDesc {
  text-align: justify;
}
/* .outerCircle {
  padding: 5px;
 
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  bottom: -1rem;
  left: 0rem;
  opacity: 1;
  margin: auto 0.5rem auto 0;
} */

.innerCircle {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.box_hover {
  position: relative;
  width: auto;
  height: auto;
  background-color: transparent;
}
.content_hover {
  background-color: rgb(241 229 229 / 15%);
  width: 488px;
  height: 208px;
  position: absolute;
  opacity: 0;
  backdrop-filter: blur(5px);
}
p.contentBoxTitle {
  font: Bold 20px Helvetica;
  color: black;
  opacity: 1;
  margin: 5rem auto 5rem 1rem;
}

.box_ct {
  width: 488px;
  height: 30px;
  text-align: left;
}
.text_hover {
  width: 488px;
  /* height: 232px; */
  opacity: 1;
  color: white;
}

h2.text_hover {
  font: Bold 20px Helvetica;
  opacity: 1;
}

.padd {
  padding: 0 auto;
  display: flex;
  flex-direction: row;
  padding-top: 20%;
  padding-left: 20%;
}

.box_hover:hover .content_hover,
.box_hover:hover .content_hover .text_hover,
.box_hover:hover .content_hover .text_hover .outerCircle,
.box_hover:hover .content_hover .text_hover .outerCircle .innerCircle {
  opacity: 1;
}

.box_hover:hover .boxBottom {
  opacity: 0;
}

.Section6 {
  width: auto;
  height: 710px;
  background-color: white;
  position: relative;
}

.flexContent {
  margin: auto;
}
.boxTitle {
  height: 62px;
  position: absolute;
  align-self: center;
  left: 14%;
  top: 14%;
}

p.title {
  font: 46px/55px Helvetica;
  text-transform: uppercase;
  font-weight: bold;
}

p.contentTextBottom {
  font-size: 16px/32px Helvetica;
  letter-spacing: 0px;
  text-align: left;
}

.Box5 {
  padding: 7rem 2rem 5rem 2rem;
}
.boxImage1 {
  width: 100%;
  height: 440px;
}

figure.wrapp_image {
  width: 100%;
  height: 440px;
  overflow: hidden;
}

.img_content {
  width: 100%;
  height: 440px;
  object-fit: cover;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  overflow: hidden;
}

.inside_image {
  width: 1510px;
  height: 440px;
  position: absolute;
  background-color: rgb(6 6 6 / 23%);
  opacity: 0;
  color: rgb(0, 0, 0);
  top: 14.5%;
}

.iconhover {
  position: absolute;
  width: 200px;
  height: 100px;
  opacity: 1;
  bottom: 2px;
  left: 82%;
  object-fit: contain;
}

figure.wrapp_image:hover .inside_image {
  opacity: 1;
}
figure.wrapp_image:hover .img_content {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition: 1s ease-in-out;
}

.Box5_1 {
  width: 1440px;
  height: 440px;
}

.flexContent2 {
  padding-top: 4%;
}

.miniBox1 {
  width: 448px;
  height: 242px;
  padding: 0 2rem 0 0;
}
.miniBox2 {
  width: 448px;
  height: 242px;
  padding: 0;
  margin-right: 30px;
  margin-left: 30px;
}
.miniBox3 {
  width: 448px;
  height: 242px;
  padding: 0 0 0 2rem;
}

.image2 {
  width: 100%;
  height: 232px;
  object-fit: cover;
}

.box_title {
  width: 448px;
  height: 56px;
  background-color: transparent;
  filter: blur(10px);
}

p.text {
  position: relative;
  font-size: 20px Helvetica;
}

.boxBottom {
  width: 448px;
  height: 56px;
  position: absolute;
  background-color: rgb(100 20 20 / 10%);
  bottom: 0;
  backdrop-filter: blur(10px);
  z-index: 0;
  opacity: 1;
  transition: height 0.5s;
}

.contentInsideBox {
  position: absolute;
  bottom: 0;
  z-index: 2;
  transition: height 0.5s;
  opacity: 1;
}

.insideBox {
  width: 448px;
  height: 56px;
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: 1;
}

p.contentBoxTitle {
  font: Bold 20px Helvetica;
  color: white;
  opacity: 1;
}

.box {
  width: 448px;
  height: 232px;
  /* margin-right: 2rem; */
  position: relative;
}

.margin {
  margin: 1rem 2rem auto 2rem;
  opacity: 1;
}

.box_hover {
  position: relative;
  width: auto;
  height: auto;
  background-color: transparent;
}
.content_hover {
  background-color: rgb(241 229 229 / 15%);
  width: 448px;
  height: 232px;
  position: absolute;
  opacity: 0;
  backdrop-filter: blur(15px);
}
.text_hover {
  width: 448px;
  /* height: 232px; */
  opacity: 1;
  color: white;
}

h2.text_hover {
  font: Bold 20px Helvetica;
  opacity: 1;
}

p.contentLink {
  font: 16px/24px Helvetica;
  margin: auto 0;
  font-weight: bold;
  color: black;
  cursor: pointer;
}

.outerCircle {
  padding: 5px;
  /* background-color: rgb(45, 57, 69, 0.5); */
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  bottom: -1rem;
  left: 0rem;
  opacity: 1;
  margin: auto 0.5rem auto 0;
}

.innerCircle {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.box_hover:hover .content_hover,
.box_hover:hover .content_hover .text_hover,
.box_hover:hover .content_hover .text_hover .outerCircle,
.box_hover:hover .content_hover .text_hover .outerCircle .innerCircle {
  opacity: 1;
}

.box_hover:hover .boxBottom {
  opacity: 0;
}

.padd {
  padding: 0 auto;
  display: flex;
  flex-direction: row;
  padding-top: 20%;
  padding-left: 20%;
}

.link {
  color: transparent !important;
  cursor: pointer;
}

.link1 {
  text-decoration: none !important;
  margin: auto auto auto 0;
}

.link2 {
  text-decoration: none !important;
  margin: auto auto auto auto;
}

.link3 {
  text-decoration: none !important;
  margin: auto 0 auto auto;
}

.flexDir {
  display: flex;
  flex-direction: row;
  /* padding: 5rem 0 0 0; */
}

.boxC {
  max-width: 1540px;
  /* padding-top: 5rem; */
}

.box1 {
  width: 448px;
  height: 232px;
  margin: auto auto auto 0;
  position: relative;
}
.box2 {
  width: 448px;
  height: 232px;
  margin: auto;
  position: relative;
}
.box3 {
  width: 448px;
  height: 232px;
  margin: auto 0 auto auto;
  position: relative;
}

.modalHeader {
  position: absolute;
  right: 0;
  border: none;
}

.imgModal {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.Relative {
  position: relative !important;
}

.modalFull {
  width: 100vw;
  height: 100vh;
  max-width: unset;
}

.modalFullChart {
  width: 80vw;
  height: 80vh;
  max-width: unset;
  margin-top: 10vh;
}

.modalFullCustom > .modal-content {
  background-color: transparent !important;
}

.st0 {
  fill: none;
  stroke: #f2f2f2;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.Circuit {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 30rem;
  opacity: 0.1;
  z-index: 0;
}

.backgroundLogo {
  position: absolute;
  top: 10%;
  right: 3rem;
  height: 100%;
  width: 30rem;
  z-index: 0;
}

.underlined {
  color: white;
  flex: 1;
  text-decoration: none;
  background-image: linear-gradient(to right, yellow 0, yellow 100%);
  background-position: 0 1.2em;
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: background 0.5s;
}

.underlined:hover {
  background-size: 100% 100%;
}

.thick {
  background-position: 0 -0.1em;
}

.readMore {
  cursor: pointer;
  color: white;
  font: normal 16px Helvetica;
}
.readMore2 {
  cursor: pointer;
  color: white !important;
  font: normal 16px Helvetica;
}

.readMore2 a {
  cursor: pointer;
  color: white !important;
  font: normal 16px Helvetica;
  text-decoration: none;
}

.background {
  background-color: #ececec;
}

.slide_in_right {
  -webkit-animation: slide_in_right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide_in_right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* @keyframes slide_in_right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
} */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* @media only screen and (max-width: 1376px) {
  .boxC {
    max-width: 1200px;
  }
  p.title {
    font: bold 36px Helvetica;
  }

  .inside_image {
    width: 1090px;
    height: 441px;
    top: 11%;
  }

  .box {
    width: 354px;
  }

  .content_hover {
    width: 354px;
  }

  .boxBottom {
    width: 354px;
  }

  .boxC {
    max-width: 1120px;
  }

  .insideBox {
    width: 354px;
  }

  .iconhover {
    left: 80%;
  }
  .img_box {
    width: 345px;
    height: 188px;
  }
  ._content {
    width: 351px !important;
    height: 188px !important;
  }

  .box_1 {
    width: 345px;
    height: 188px;
  }
  ._overlay {
    width: 345px;
    height: 188px;
  }

  ._absolute p {
    font: bold 16px Helvetica;
  }

  ._boxB {
    width: 50%;
    margin-top: -6rem;
  }
  .col_second {
    padding: 2rem;
  }

  .img_first {
    height: 342px;
  }

  .boxPhoto {
    width: 1062px;
    height: 404px;
  }

  .col_second h2 {
    font: bold 28px Helvetica;
    margin-bottom: 1rem;
  }

  .col_second h4 {
    font: normal 14px/28px Helvetica;
  }

  .readMore {
    font: normal 12px Helvetica;
    padding-top: 3px;
  }

  .col_second h2:before {
    font-size: 30px;
  }
  .col_second h2:after {
    font-size: 30px;
  }
  .box_c {
    max-width: 1200px !important;
  }

  .customContainer {
    padding: 3.5rem 0 0 0;
    margin-left: 9.5rem;
    margin-right: 9.5rem;
  }
  .backgroundRowFirst {
    padding-bottom: 3.5rem;
  }
  .Row_2 {
    padding-bottom: 3.5rem;
  }
} */

@media only screen and (min-width: 2560px) {
  .customContainer {
    max-width: 2090px !important;
  }
  .img_box {
    width: 640px;
    height: 300px;
  }
  .box_1 {
    width: 640px;
    height: 300px;
  }

  ._content {
    width: 640px !important;
    height: 300px !important;
  }

  ._overlay {
    width: 640px;
    height: 300px;
  }

  .contentBottomTitle span a {
    font: bold 16px Helvetica;
  }
}

@media only screen and (max-width: 1400px) {
  ._box .customUnderline::after {
    bottom: -9px;
  }

  .boxC {
    max-width: 1200px;
  }
  p.title {
    font: bold 36px Helvetica;
  }

  .inside_image {
    width: 1090px;
    height: 441px;
    top: 11%;
  }

  .box {
    width: 354px;
  }

  .content_hover {
    width: 354px;
  }

  .boxBottom {
    width: 354px;
  }

  .boxC {
    max-width: 1120px;
  }

  .insideBox {
    width: 354px;
  }

  .iconhover {
    left: 80%;
  }
  .img_box {
    width: 345px;
    height: 188px;
  }
  ._content {
    width: 351px !important;
    height: 188px !important;
  }

  .box_1 {
    width: 345px;
    height: 188px;
  }
  ._overlay {
    width: 345px;
    height: 188px;
  }

  ._absolute p {
    font: bold 16px Helvetica;
  }

  ._boxB {
    width: 50%;
    margin-top: -156px;
  }
  .col_second {
    padding: 2rem;
  }

  .img_first {
    height: 342px;
  }

  .boxPhoto {
    width: 1062px;
    height: 404px;
  }

  .col_second h2 {
    font: bold 28px Helvetica;
    margin-bottom: 1rem;
  }

  .col_second h4 {
    font: normal 14px/28px Helvetica;
  }

  .readMore {
    font: normal 12px Helvetica;
    padding-top: 3px;
  }

  .col_second h2:before {
    font-size: 30px;
  }
  .col_second h2:after {
    font-size: 30px;
  }
  .box_c {
    max-width: 1200px !important;
  }

  .customContainer {
    padding: 3.5rem 0 0 0;
    margin-left: 9.5rem;
    margin-right: 9.5rem;
  }
  .backgroundRowFirst {
    padding-bottom: 3.5rem;
  }
  .Row_2 {
    padding-bottom: 3.5rem;
  }
}
