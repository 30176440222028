.customContainer {
    padding-top: 5rem;
}
.contentSection {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: white;
}

.box {
    display: flex;
    flex-direction: row;
}

.boxContent {
    width: 50%;
    height: 336px;
    background-color: #f1f1f1;
}

.boxImage {
    width: 50%;
    height: 336px;
    background-color: transparent;
    position: relative;
}

.contentWrapper {
    padding: 2rem;
}

.contentImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title {
    font: bold 28px Helvetica;
}

.contentTitle {
    font: bold 24px Helvetica;
}

.subTitle {
    font: bold 18px Helvetica;
}

.description {
    font: bold 16px Helvetica;
}
