.nav-brand {
  text-align: left;
  font-weight: bold;
  font-size: 26px;
  letter-spacing: 0px;
  font-family: Helvetica, sans-serif;
  color: black !important;
}

.navbar-custom {
  background-color: #f1f1f1 !important;
  border: 0;
  height: 90px;
}

.nav-link {
  font-family: Helvetica, sans-serif;
  color: #696969 !important;
  font-size: 16px;
}

.nav-link.selected {
  color: #f16b26 !important;
  font-weight: bold;
}

a.nav-link {
  margin-left: 15px;
  margin-right: 15px;
}

.search-box {
  height: 56px;
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 24px;
  padding-right: 0;
  min-width: 480px;
  border-radius: 50px 0 0 50px;
  font-size: 20px;
}

.search-box-btn {
  height: 56px;
  border: 0;
  border-radius: 0 50px 50px 0;
  background-color: white;
  color: #696969;
  font-size: 16px;
  padding-right: 16px;
}

.cusT {
  max-width: 1520px !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  padding-top: 1rem;
}

.search-box {
  height: 43px;
  min-width: 453px;
}

.search-box-btn {
  height: 43px;
}

@media only screen and (min-width: 2560px) {
  .cusT {
    max-width: 2090px !important;
    padding: 0 1rem !important;
  }

  .bottomContent {
    margin-left: 15.5rem;
  }

  .pad0 {
    padding: 0;
  }

  .search-box {
    min-width: 515px;
  }
}

@media only screen and (max-width: 1400px) {
  .cusT {
    max-width: 1062px !important;
  }

  .nav-brand {
    font-size: 18px;
  }

  a.nav-link {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .nav-link {
    font-size: 14px;
  }

  .search-box {
    font-size: 14px;
    min-width: 366px;
    height: 35px;
  }

  .search-box-btn {
    height: 35px;
  }
}
