.circle {

    position: absolute;
    height: 50px;
    width: 50px;
    border: solid 2px gray;
    border-radius: 100%;
}

#test {
    display: flex;
    position: relative;
    height: 150px;
    width: 150px;
    justify-content: center;
    align-items: center;
    right: 100%;
}