.joinC {
  padding: 5rem 0;
  height: 100%;
  width: 100%;
}

.boxNav {
  display: flex;
  margin-bottom: 1rem;
}

.customLink {
  display: flex;
  text-decoration: none;
}

.customLink:hover {
  text-decoration: none;
}

.backButton {
  font: 12px/16px Helvetica;
  color: #000000;
  margin: 0 0 0 1rem;
}

.category {
  font: 18px/32px Helvetica;
  color: #000000;
}

.title {
  text-align: left;
  font: bold 24px/32px Helvetica;
  letter-spacing: -0.24px;
  color: #000000;
  text-transform: uppercase;
}

.boxImage {
  width: 100%;
  height: 504px;
}

.boxImage2 {
  width: 100%;
  height: 328px;
  background-color: #D4D4D4;
}

.contentImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.titleContent {
  font: bold 26px/31px Helvetica;
  color: #000000;
}

.content {
  font: 16px/32px Helvetica;
  color: #000000;
}

.customSectionL {
  padding: 0 2rem 0 0 !important;
}

.customSectionR {
  padding: 0 0 0 2rem !important;
}

.boxLeft {
  padding: 2rem 5rem 0 0;
  width: 60vw;
}

.boxRight {
  width: calc((100% - 624px) + 10vw);
  height: 464px;
  background-color: transparent;
  display: block;
  position: relative;
}

.boxContentOverflow {
  padding: 2rem 2rem;
  width: 624px;
  height: 280px;
  background-color: #d4d4d4;
  margin-top: 1rem;
  box-shadow: 0px 6px 16px #00000073;
  overflow-y: hidden;
  text-align: justify;
}

.contentDesc {
  font: 16px/32px Helvetica;
  color: #000000;
  text-align: justify;
}

.boxContent ul > li {
  font: 16px/32px Helvetica;
  margin-bottom: 1rem;
  color: #000000;
}
._box1 {
  padding-top: 3rem;
  width: auto;
  height: 30vh;
  display: flex;
  flex-direction: column;
  /* background-color: blueviolet; */
}
._box2 {
  width: auto;
  height: auto;
  background-color: brown;
}
._txt1 {
  font: normal 20px Helvetica;
  color: black;
}

.sectionContent {
  font: 16px/32px Helvetica;
  text-align: justify;
}

.sectionContent ul {
  margin-top: 1rem;
}

.sectionContent strong {
  display: block;
  margin-bottom: 1rem;
  font: 20px/24px Helvetica;
  color: #000000;
}

.sectionContent div {
  margin-top: 2rem;
}

.section2 {
  margin: 2rem 0;
}

.subTitle,
.subTitle strong {
  text-align: left;
  font: bold 26px/31px Helvetica !important;
  color: #000000;
}

.boxWrapping {
  background-color: #f1f1f1;
}

.s2_left,
.s2_right {
  width: 50%;
  height: 100%;
  margin: 0 !important;
}

.s2_left div,
.s2_right div {
  padding: 2rem;
  margin: 0;
  text-align: justify;
}
._imgLitle {
  width: 720px;
  height: 328px;
}
._boxLitle {
  width: 336px;
  height: 176px;
  background-color: burlywood;
  margin: auto;
}

._txt3 {
  font: bold 24px Helvetica;
  letter-spacing: 0px;
  color: black;
  text-align: left;
}

._txt2 {
  font: normal 16px Helvetica;
  color: black;
  text-align: left;
}
.boxList {
  width: 100%;
  height: 100%;
  background-color: #d4d4d4;
}

.boxX {
  width: 320px;
  height: 152px;
  background: #03428e 0% 0% no-repeat padding-box;
  margin: 0 1rem 1rem 0 !important;
}

.num {
  text-align: center;
  font: 80px/96px Helvetica;
  color: #ffffff;
}

.boxxContent {
  text-align: center;
  font: 14px/32px Helvetica;
  color: #ffffff;
}

.titleSection {
  text-align: left;
  font: 20px/24px Helvetica;
  color: #000000;
}

.boxFullImage {
  width: 100%;
  height: 512px;
  background-color: #f1f1f1;
  display: flex;
  position: relative;
}

.boxFullImage img {
  width: 100%;
  height: 472px;
  margin: auto;
  object-fit: contain;
}

.section3 {
  margin: 2rem 0;
}

.box {
  width: 336px;
  height: 176px;
  margin-right: 1rem;
  position: relative;
}

.box:hover .contentFront {
  opacity: 0;
}

.box:hover .contentBack {
  opacity: 1;
}

.box:hover .boxMask {
  opacity: 0.8;
}

.box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contentFront,
.contentBack {
  width: 336px;
  height: 176px;
  position: absolute;
  top: 0;
  left: 0;
}

.contentFront {
  opacity: 1;
}

.contentBack {
  opacity: 0;
}

.boxMask {
  width: 336px;
  height: 176px;
  position: absolute;
  top: 0;
  left: 0;
  background: #223771 0% 0% no-repeat padding-box;
  opacity: 0.5;
}

.boxContentIn {
  width: 336px;
  height: 176px;
  display: flex;
  justify-content: flex-end;
  align-self: center;
  position: relative;
}

.boxContentIn p {
  text-align: right;
  font: bold 16px/15px Helvetica;
  color: #ffffff;
  margin: auto 0;
  padding: 0 1rem 0 0;
  display: block;
}

.outerCircle {
  padding: 5px;
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  position: relative;
  opacity: 1;
  margin: 0.5rem 0 auto auto;
}

.innerCircle {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.boxContentIn .viewMore {
  text-align: right;
  font: 14px Helvetica;
  color: #ffffff;
  margin: 0.6rem 2rem 0 0;
  padding: 0 0 0 0.5rem;
  display: block;
}
