/* GENERAL */
:root {
  --title-hd: bold 28px Helvetica;
  --subtitle-hd: bold 18px Helvetica;
  --p-hd: normal 14px Helvetica;
  --container-hd: 1062px !important;
  --padding-section-hd: 3.5rem 0;

  --title-fhd: bold 36px Helvetica;
  --subtitle-fhd: bold 26px Helvetica;
  --p-fhd: normal 16px Helvetica;
  --container-fhd: 1540px !important;
  --padding-section-fhd: 5rem 0;
}

.hrGeneral {
  margin: 5rem 0 0 0;
}

/* SPECIFIC */
.contentSection {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 5rem 0 0 0;
}

.title {
  font: bold 36px Helvetica;
  color: #000000;
  margin-bottom: 2rem;
}

.content {
  font: 16px/32px Helvetica;
  color: #000000;
  text-align: justify;
}

.boxs {
  position: relative;
  display: flex;
  flex-direction: row;
}

.box {
  width: 464px;
  height: 512px;
  position: relative;
}

.box:hover .boxBackground {
  height: 440px;
  z-index: 1;
}

.box:hover .boxProfile {
  height: 0;
}

.box:hover .boxProfile > img {
  position: absolute;
  top: 20px;
}

.box:hover .boxContent {
  position: absolute;
  top: 104px;
  z-index: 2;
}

.box:hover .boxContent > .boxFooter > .boxLinks .outerCircle {
  opacity: 1;
}

.box:hover .boxContent > .boxFooter > .boxLinks .readMoreContent {
  margin-left: 1.5rem;
}

.boxBackground {
  width: 464px;
  height: 408px;
  background-color: #f1f1f1;
  position: absolute;
  z-index: -1;
  bottom: 0;
  transition: height 0.5s;
}

.boxProfile {
  width: 464px;
  height: 104px;
  background-color: transparent;
  position: relative;
  display: flex;
}

.boxProfile img {
  width: 144px;
  height: 144px;
  border-radius: 50%;
  object-fit: cover;
  margin: 20px auto auto auto;
}

.boxContent {
  width: 464px;
  height: 408px;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  transition: height 0.5s;
}

.imgProfile {
  width: 144px;
  height: 144px;
  border-radius: 50%;
  object-fit: cover;
  margin: -72px auto auto auto;
}

.boxBody {
  width: 464px;
  height: 286px;
  background-color: #f1f1f1;
  /* padding: calc(2rem + 72px) 2rem 2rem 2rem; */
  padding: 2rem;
}

.boxFooter {
  width: 464px;
  height: 50px;
  background-color: #f1f1f1;
  padding: 0 2rem 2rem 2rem;
  position: relative;
  display: flex;
}

.titleContent {
  font: 20px/32px Helvetica;
  color: #000000;
  text-transform: uppercase;
  text-align: left;
  margin: 0;

  display: block;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.authorContent {
  font: 14px/55px Helvetica;
  color: #223771;
  text-align: left;
}

.readMoreContent {
  text-align: left;
  font: 14px/24px Helvetica;
  color: #000000;
  margin: auto 0;
}

.boxLinks {
  position: relative;
  margin: auto 0;
}

.boxLink {
  position: relative;
  margin-top: 2rem;
}

.outerCircle {
  padding: 5px;
  /* background-color: rgb(45, 57, 69, 0.5); */
  transition: transform 0.2s;
  border-radius: 50%;
  border: 1px solid #f16624;
  display: inline-block;
  margin: 0 1rem 0 auto;
  align-self: center;
  opacity: 0;
  position: absolute;
  top: 1px;
}

.innerCircle {
  content: "";
  background: #f16624;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.linkAll {
  text-align: left;
  font: 14px/24px Helvetica;
  color: #000000;
  cursor: pointer;
  transition: all 1;
  margin: auto 0;
}

.boxLink:hover .outerCircle {
  opacity: 1;
}

.boxLink:hover .linkAll {
  margin-left: 1.5rem;
  text-decoration: none;
  color: black;
}

.customLink:hover {
  text-decoration: none;
}

.boxC {
  max-width: 1540px;
}

@media only screen and (min-width: 2560px) {
  .boxC {
    max-width: 2090px !important;
    padding: 0 0 5rem 0;
  }
}

@media only screen and (min-width: 1920px) {
}

@media only screen and (max-width: 1400px) {
  .box {
    width: 346px;
    height: 412px;
  }

  .boxBackground {
    width: 346px;
    height: 308px;
  }

  .box:hover .boxBackground {
    height: 412px;
  }

  .boxProfile {
    width: 346px;
    height: 104px;
  }

  .boxContent {
    width: 346px;
    height: 308px;
  }

  .imgProfile {
    width: 104px;
    height: 104px;
    margin: -52px auto auto auto;
  }

  .boxBody {
    width: 346px;
    height: 212px;
  }

  .boxFooter {
    width: 346px;
    height: 50px;
  }

  .boxC {
    max-width: var(--container-hd) !important;
    padding-right: 0;
    padding-left: 0;
  }

  .titleContent p {
    font: var(--p-hd);
    line-height: 1.5;
  }

  .contentSection {
    padding: 3.5rem 0 0 0;
  }

  .hrGeneral {
    margin: 3.5rem 0 0 0;
  }

  .title {
    font: var(--title-hd);
    margin-bottom: 1.5rem;
  }

  .content {
    font: var(--p-hd);
    line-height: 1.8;
  }

  .titleContent {
    font: 16px Helvetica;
    line-height: 1.8;
  }

  .boxLink {
    margin-top: 1.5rem;
  }

  .readMoreContent {
    font: 14px Helvetica;
  }
}
