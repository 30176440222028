.newNavbar {
  height: 72px;
  width: 100vw;
  background-color: #f3f3f3;
  transition: all 1s ease;
  z-index: 2;
  position: relative;
  transition: all 1s linear 0.3s;
}

.box {
  height: 41px;
  padding: 0.5rem;
  width: 115px;
  align-items: center;
}

.box span {
  color: #000;
  font: bold 10px/28px Helvetica;
  text-align: center;
}

.boxImage {
  width: 100%;
  /* height: 504px; */
  background-color: gray;
}

.imgContentD {
  width: 100%;
  /* height: 504px; */
  object-fit: contain;
}

.box span:hover {
  cursor: pointer;
}

.colorGrey {
  background-color: #f3f3f3;
  /* position: relative; */
}

.isActive span {
  color: #223771;
  /* text-transform: uppercase; */
}

.isActive {
  border-bottom: 3px solid #223771;
}

.box .isActive span {
  color: #223771;
  text-transform: uppercase;
}

@media only screen and (max-width: 1400px) {
  .customContainer {
    max-width: 1120px;
  }
}
