.imgLogo {
    height: 100%;
    width: 100%;
    color: white;
    z-index: 100;
    margin: 1rem 0 0 2rem;
}

.navLogo {
    z-index: 100;
}


@media only screen and (max-width: 1400px) {
    .imgNav {
        width: 75px;
    }

    .imgLogo {
        width: 90px;
        height: 90px;
        margin: -0.5rem 0 0 1rem;
    }
}


@media only screen and (max-width:420px) {
    .imgLogo {
        width: 40px !important;
        height: 40px !important;
    }
}

@media only screen and (max-width:360px) {
    .imgLogo {
        width: 40px !important;
        height: 40px !important;
    }
}