.educations-box-table figure {
    height: 100%;
}

.educations-box-table figure table {
    margin: auto;
}

.educations-box-table figure table td {
    border: none;
}

.educations-box-table figure table td {
    border: none;
}

.educations-box-table figure table tr:first-child td strong{
    font: bold 18px Helvetica !important;
    /* margin: 0 1rem; */
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    margin: auto;
    justify-content: center;
}

.educations-box-table figure table tr:not(:first-child) td {
    font: 16px Helvetica !important;
    text-align: center;
}
