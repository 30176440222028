.Map {
    height: 100%;
    width: 100vw;
    position: relative;
    /* background-image: linear-gradient(#004092, #020202, transparent), url('./assets/map-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
}

.imageMask {
    height: 100%;
    width: 100vw;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.boxPreview {
    width: 100%;
    height: 368px;
    background: var(--unnamed-color-f1f1f1) 0% 0% no-repeat padding-box;
    background: #F1F1F1 0% 0% no-repeat padding-box;
}

.fullWidth {
    padding: 3rem 0;
    /* height: 100%; */
    height: 65vh;
    width: 100vw !important;
    position: relative;
    transform: scale(1.2);
}

.detailMaps {
    position: absolute;
    bottom: 0;
    left: 0;
}

.tools {
    position: absolute;
    bottom: 10%;
    right: 12%;
    z-index: 4;
}

.button_map {
    width: 40px;
    height: 40px;
    border: none;
    margin-right: 10px;
    font-size: 20px;
}

.boxImage {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
}

/* .boxImage::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(#004092, #020202, transparent);
} */


.backgroundImg {
    height: 100%;
    width: 100%;
    position: relative;
}

@media only screen and (max-width:1366px) {
    .tools {
        top: 51%;
        right: 15%;
    }
}

@media only screen and (max-width: 420px) and (min-width: 361px) {
    .button_map {
        display: none;
    }
}

@media only screen and (max-width:360px) {
    .button_map {
        display: none;
    }
}